import { createIntl, createIntlCache, IntlShape } from 'react-intl';

const cache = createIntlCache();
let cachedIntl: IntlShape | undefined;

const createNewIntl = (language: string, messages: { [key: string]: string }): IntlShape => {
  cachedIntl = createIntl(
    {
      locale: language,
      messages,
      onError: () => {},
    },
    cache
  );

  return cachedIntl;
};

export const getCachedIntl = (): IntlShape | undefined => cachedIntl;

export default createNewIntl;
