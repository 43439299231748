import styled from 'styled-components';
import { Box } from '../Atom';

export const MODAL_WIDTH = 1000;

export const TabsContainer = styled(Box)`
  .ant-tabs-bar {
    margin: 0;
    border: none;
  }

  .ant-tabs .ant-tabs-top-content {
    border: 1px solid ${({ theme }) => theme.color.primary};
    border-radius: ${({ theme }) => theme.space[1]}px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
