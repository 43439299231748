import { Checkbox } from '@lucid/core';
import styled from 'styled-components';

export const FooterCheckbox = styled(Checkbox)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;

  > span {
    text-align: left;
  }
`;
