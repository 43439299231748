import { AnyAction, CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { messageHolder } from '../../helpers';
import MassValidationAsyncActions from '../massValidation';
import SchemasAsyncActions from '../schemas';

const UI_SLICE_NAME = 'ui';
export const UI_INITIAL_STATE: STATES.UI = {
  isSidebarCollapsed: true,
  isGlobalLoading: false,
};

const setGlobalLoading: CaseReducer<STATES.UI, PayloadAction<boolean>> = (state, { payload }) => {
  state.isGlobalLoading = payload;
};

function isActionForShowingLoading(action: AnyAction): action is PayloadAction<unknown> {
  return [
    SchemasAsyncActions.actions.setSchemasWithJSONSetups.pending.type,
    SchemasAsyncActions.actions.setSchema.pending.type,
    SchemasAsyncActions.actions.applyChanges.pending.type,
    MassValidationAsyncActions.actions.changeInputs.pending.type,
  ].includes(action.type);
}

function isActionForHidingLoading(action: AnyAction): action is PayloadAction<unknown, string, never, Error> {
  return [
    SchemasAsyncActions.actions.setSchemasWithJSONSetups.fulfilled.type,
    SchemasAsyncActions.actions.setSchemasWithJSONSetups.rejected.type,
    SchemasAsyncActions.actions.setSchema.rejected.type,
    SchemasAsyncActions.actions.setSchema.fulfilled.type,
    SchemasAsyncActions.actions.applyChanges.fulfilled.type,
    SchemasAsyncActions.actions.applyChanges.rejected.type,
    MassValidationAsyncActions.actions.changeInputs.fulfilled.type,
    MassValidationAsyncActions.actions.changeInputs.rejected.type,
  ].includes(action.type);
}

const openSidebar: CaseReducer<STATES.UI> = (state) => {
  state.isSidebarCollapsed = false;
};

const closeSidebar: CaseReducer<STATES.UI> = (state) => {
  state.isSidebarCollapsed = true;
};

const UISlice = createSlice({
  name: UI_SLICE_NAME,
  initialState: UI_INITIAL_STATE,
  reducers: { openSidebar, closeSidebar, setGlobalLoading },
  extraReducers: (builder) => {
    builder.addMatcher(isActionForShowingLoading, (state) => {
      state.isGlobalLoading = true;
    });
    builder.addMatcher(isActionForHidingLoading, (state, { error }) => {
      state.isGlobalLoading = false;

      if (error) {
        messageHolder.error(error);
      }
    });
  },
});

export default UISlice;
