import { useEntityStoreContext, EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { EditorType } from '@coherent/json-editor';
import { Button, Col, Icon } from '@lucid/core';
import { JSONEditorOptions } from 'jsoneditor';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DEFAULT_JSON_RESOURCE } from '../../common';
import { compareMessages, jsonEditorMessages } from '../../locale';
import Theme from '../../styles/theme';
import { TextEllipsis } from '../common';
import { EditorHeaderControlsContainer } from '../Layout';
import { JsonResourceModal } from '../Modals';
import { StyledJsonEditor, StyledRow } from './JsonCompare.styled';

export type JsonCompareProps = {
  className?: string;
  firstJsonResource: ENTITIES.JsonResourceObject;
  secondJsonResource: ENTITIES.JsonResourceObject;
  onChangeResource?: (jsonResource: ENTITIES.JsonResourceObject) => void;
};

const JsonCompare = ({
  firstJsonResource,
  secondJsonResource,
  className,
  onChangeResource,
}: JsonCompareProps): JSX.Element => {
  const intl = useIntl();
  const { downloadEntity } = useEntityStoreContext();

  const onSelectResource = async (
    data: Record<string, unknown>,
    resourceKey: string,
    entity?: EntityAddress | undefined
  ): Promise<void> => {
    let newJsonResource: ENTITIES.JsonResourceObject = {
      ...DEFAULT_JSON_RESOURCE,
      obj: data,
      originObj: data,
      resourceKey,
    };

    if (entity) {
      const entityData = (await downloadEntity(entity)) as EntityData;
      const { data: obj, effectiveVersion } = entityData;

      newJsonResource = {
        entityAddress: entity,
        entityVersion: effectiveVersion ?? null,
        obj,
        originObj: obj,
        resourceKey,
      };
    }

    if (onChangeResource) {
      onChangeResource(newJsonResource);
    }
  };

  const jsonEditorOptions = useMemo<Partial<JSONEditorOptions>>(() => {
    return {
      mode: 'tree',
      modes: ['tree'],
      mainMenuBar: false,
      onEditable: () => false,
      onClassName: ({ path }) => {
        const dotPath = path.join('.');
        const valueFrom1st = get(firstJsonResource.obj, dotPath, null);
        const valueFrom2nd = get(secondJsonResource.obj, dotPath, null);

        return isEqual(valueFrom1st, valueFrom2nd) ? 'the_same_element' : 'different_element';
      },
    };
  }, [firstJsonResource, secondJsonResource]);

  return (
    <StyledRow className={className} gutter={Theme.space[2]}>
      {[firstJsonResource, secondJsonResource].map((resource) => {
        const colTitle = intl.formatMessage(compareMessages.colTitle, { resourceKey: resource.resourceKey });

        return (
          <Col span={12} key={resource.resourceKey}>
            <StyledJsonEditor
              headerTitle={<TextEllipsis>{colTitle}</TextEllipsis>}
              headerControls={
                onChangeResource && (
                  <EditorHeaderControlsContainer>
                    <JsonResourceModal onDone={onSelectResource} resourceKey={resource.resourceKey} title={colTitle}>
                      {({ onOpen }) => (
                        <Button data-testid="modal-trigger" type="link" size="small" onClick={onOpen}>
                          <Icon type="file-text" />
                          <span>
                            <FormattedMessage {...jsonEditorMessages.menuSelect} />
                          </span>
                        </Button>
                      )}
                    </JsonResourceModal>
                  </EditorHeaderControlsContainer>
                )
              }
              initData={resource.obj}
              displayType={EditorType.Textual}
              jsonEditorOptions={jsonEditorOptions}
            />
          </Col>
        );
      })}
    </StyledRow>
  );
};

export default memo(JsonCompare);
