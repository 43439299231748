import styled from 'styled-components';

export const Field = styled.div`
  margin-bottom: 8px;

  .small {
    width: 500px;
  }

  .editor-container {
    width: 500px;
    max-height: 400px;
    overflow: auto;
    border: 1px solid #000;

    /* Syntax highlighting */
    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: #90a4ae;
    }
    .token.punctuation {
      color: #9e9e9e;
    }
    .namespace {
      opacity: 0.7;
    }
    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
      color: #e91e63;
    }
    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
      color: #4caf50;
    }
    .token.operator,
    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string {
      color: #795548;
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: #3f51b5;
    }
    .token.function {
      color: #f44336;
    }
    .token.regex,
    .token.important,
    .token.variable {
      color: #ff9800;
    }
    .token.important,
    .token.bold {
      font-weight: bold;
    }
    .token.italic {
      font-style: italic;
    }
    .token.entity {
      cursor: help;
    }
  }
`;

export const RefItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .type-radio-group {
    span {
      margin: 0 5px;
    }
  }

  input[type='text'] {
    width: 300px;
  }
`;

export const JSONContainer = styled.div`
  margin-bottom: 8px;
`;

export const Playground = styled.div`
  padding: 20;

  /** Override some material rules for better looking */
  .MuiCardHeader-root {
    padding-bottom: 4px !important;
  }
  .MuiCardContent-root {
    padding-top: 4px !important;
  }
  .MuiGrid-direction-xs-column {
    flex-wrap: nowrap !important;
  }

  /** */
  .ant-select {
    width: 100%;
  }
`;
