import React, { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_JSON_RESOURCE } from '../../common';
import { useModal } from '../../hooks';
import { compareMessages } from '../../locale';
import { JsonCompare } from '../JsonCompare';
import { BaseModal } from '../Modals';

export type DiffsModalProps = {
  title: string;
  getFirstJsonResource: () => ENTITIES.JsonResourceObject;
  getSecondJsonResource: () => ENTITIES.JsonResourceObject;
  children?: (props: { onOpen: () => void }) => JSX.Element;
  isVisible?: boolean;
  setVisible?: (visible: boolean) => void;
};

const DiffsModal = ({
  title,
  getFirstJsonResource,
  getSecondJsonResource,
  children,
  isVisible,
  setVisible,
}: DiffsModalProps): JSX.Element => {
  const [firstJsonResource, setFirstJsonResource] = useState(DEFAULT_JSON_RESOURCE);
  const [secondJsonResource, setSecondJsonResource] = useState(DEFAULT_JSON_RESOURCE);
  const beforeOpen = useCallback(() => {
    setFirstJsonResource(getFirstJsonResource());
    setSecondJsonResource(getSecondJsonResource());
  }, [getFirstJsonResource, getSecondJsonResource]);
  const { isOpen, onCancel, onOpen } = useModal({ isVisible, setVisible, beforeOpen });

  return (
    <>
      {children && children({ onOpen })}
      <BaseModal
        title={<FormattedMessage {...compareMessages.title} values={{ title }} />}
        visible={isOpen}
        onCancel={onCancel}
        width={1020}
      >
        <JsonCompare firstJsonResource={firstJsonResource} secondJsonResource={secondJsonResource} />
      </BaseModal>
    </>
  );
};

export default memo(DiffsModal);
