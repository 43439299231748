import { EntityAddressType } from '@coherent/entity-store-ui';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type UseMassValidationRouterMatchType = {
  dataType: EntityAddressType;
  entityType: string;
  rootFolder: string;
};

const useMassValidationRouterMatch = (): UseMassValidationRouterMatchType => {
  const { pathname } = useLocation();

  return useMemo(() => {
    if (!pathname.startsWith('/mass/validation')) {
      return {
        dataType: EntityAddressType.JsonData,
        entityType: '',
        rootFolder: '',
      };
    }

    const [pathWithDataType, entityType, rootFolder] = pathname.split(/:/);
    const dataType = pathWithDataType.replace('/mass/validation/', '');

    return {
      dataType: dataType === EntityAddressType.JsonData ? EntityAddressType.JsonData : EntityAddressType.Blob,
      entityType,
      rootFolder,
    };
  }, [pathname]);
};

export default useMassValidationRouterMatch;
