import GenerationAsyncActions from './GenerationAsyncActions';
import GenerationSlice, { GENERATION_INITIAL_STATE } from './GenerationSlice';

export default {
  initialState: GENERATION_INITIAL_STATE,
  name: GenerationSlice.name,
  reducer: GenerationSlice.reducer,
  actions: {
    ...GenerationSlice.actions,
    ...GenerationAsyncActions,
  },
};
