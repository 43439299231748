import EnvAsyncActions from './EnvAsyncActions';
import EnvSlice, { ENV_INITIAL_STATE } from './EnvSlice';

export default {
  name: EnvSlice.name,
  initialState: ENV_INITIAL_STATE,
  reducer: EnvSlice.reducer,
  actions: {
    ...EnvSlice.actions,
    ...EnvAsyncActions,
  },
};
