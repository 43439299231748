import { Button, Modal } from '@lucid/core';
import { ModalProps } from '@lucid/core/dist/Modal';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { commonMessages } from '../../locale';
import { Box, CloseIcon } from '../Atom';
import { BaseModalGlobalStyled } from './BaseModal.styled';

export type BaseModalProps = PropsWithChildren<ModalProps> & {
  footerExtraComponent?: ReactNode;
};

const BaseModal = (props: BaseModalProps): JSX.Element => {
  const intl = useIntl();
  const { onCancel, okButtonProps, cancelButtonProps, onOk, footerExtraComponent, okText, cancelText, ...rest } = props;

  return (
    <>
      <BaseModalGlobalStyled />
      <Modal
        wrapClassName="base-modal"
        destroyOnClose
        centered
        okButtonProps={{
          ...okButtonProps,
          type: 'primary',
          shape: 'round',
        }}
        cancelButtonProps={{
          ...cancelButtonProps,
          type: 'default',
          shape: 'round',
        }}
        closeIcon={<CloseIcon />}
        width={900}
        onCancel={onCancel}
        onOk={onOk}
        okText={intl.formatMessage(commonMessages.ok)}
        cancelText={intl.formatMessage(commonMessages.cancel)}
        footer={
          <Box isFlex flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box flex={1} isFlex>
              {footerExtraComponent}
            </Box>
            <Box>
              {onOk && (
                <Button shape="round" onClick={onOk} type="primary" {...okButtonProps}>
                  {okText || intl.formatMessage(commonMessages.ok)}
                </Button>
              )}
              <Button shape="round" onClick={onCancel} {...cancelButtonProps}>
                {cancelText || intl.formatMessage(commonMessages.cancel)}
              </Button>
            </Box>
          </Box>
        }
        {...rest}
      />
    </>
  );
};

export default BaseModal;
