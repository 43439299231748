import { EntityAddressType } from '@coherent/entity-store-ui';
import { CaseReducer, createSlice } from '@reduxjs/toolkit';
import MassValidationAsyncActions from './MassValidationAsyncActions';

const MASS_VALIDATION_SLICE_NAME = 'massValidation';
export const MASS_VALIDATION_INITIAL_STATE: STATES.MassValidation = {
  dataType: EntityAddressType.JsonData,
  entityType: '',
  rootFolder: '',
  invalidOnly: false,
  entities: [],
  pageIndex: 0,
  total: 0,
};

const toggleInvalidOnly: CaseReducer<STATES.MassValidation> = (state) => {
  state.invalidOnly = !state.invalidOnly;
};

const MassValidationSlice = createSlice({
  name: MASS_VALIDATION_SLICE_NAME,
  initialState: MASS_VALIDATION_INITIAL_STATE,
  reducers: { toggleInvalidOnly },
  extraReducers: (builder) => {
    builder.addCase(MassValidationAsyncActions.changeInputs.fulfilled, (state, { payload }) => {
      const { dataType, entityType, rootFolder, entities, pageIndex, total, invalidOnly } = payload;

      return {
        ...state,
        dataType,
        entityType,
        rootFolder,
        entities,
        pageIndex,
        total,
        invalidOnly,
      };
    });
    builder.addCase(MassValidationAsyncActions.updateEntity.fulfilled, (state, { payload }) => {
      const { isValid, savedData } = payload;

      const index = state.entities.findIndex((e) => e.path === savedData.entity.path);

      if (index === -1) {
        return;
      }

      if (state.invalidOnly) {
        state.entities.splice(index, 1);

        return;
      }

      const entity = state.entities[index];

      entity.isValid = isValid;
      entity.created = savedData.entity.created;
      entity.updated = savedData.entity.updated;
      entity.path = savedData.entity.path;
      entity.entityData = savedData;
    });
  },
});

export default MassValidationSlice;
