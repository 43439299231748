import { EntityAddressType } from '@coherent/entity-store-ui';
import { Button, Icon, Input, Radio } from '@lucid/core';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { FormEvent, forwardRef, memo, useImperativeHandle, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useMassValidationRouterMatch } from '../../hooks';
import { commonMessages, entityTableMessages, headerMessages, massValidationModalMessages } from '../../locale';
import BaseModal from './BaseModal';

type MassValidationModel = {
  entityType: string;
  dataType: EntityAddressType;
  rootFolder: string;
};

type MassValidationFormContentProps = FormComponentProps<MassValidationModel> & {
  initData: MassValidationModel;
};

const MassValidationFormContent = forwardRef<FormComponentProps<MassValidationModel>, MassValidationFormContentProps>(
  (props, ref) => {
    const { form, initData } = props;

    useImperativeHandle(ref, () => ({
      form,
    }));

    return (
      <Form onSubmit={(e: FormEvent) => e.preventDefault()} layout="vertical" hideRequiredMark>
        <Form.Item label={<FormattedMessage {...massValidationModalMessages.massValidationModalRootFolder} />}>
          {form.getFieldDecorator('rootFolder', {
            initialValue: initData.rootFolder,
          })(<Input data-testid="root-folder-input" />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage {...massValidationModalMessages.massValidationModalType} />}>
          {form.getFieldDecorator('entityType', {
            initialValue: initData.entityType,
          })(<Input data-testid="entity-type-input" />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage {...massValidationModalMessages.massValidationModalSource} />}>
          {form.getFieldDecorator('dataType', {
            initialValue: initData.dataType,
          })(
            <Radio.Group buttonStyle="solid">
              {[EntityAddressType.Blob, EntityAddressType.JsonData].map(
                (value): JSX.Element => (
                  <Radio.Button key={value} value={value}>
                    <FormattedMessage {...commonMessages.entityDataType} values={{ dataType: value }} />
                  </Radio.Button>
                )
              )}
            </Radio.Group>
          )}
        </Form.Item>
      </Form>
    );
  }
);

const MassValidationForm = Form.create<MassValidationFormContentProps>({ name: 'mass_validation' })(
  MassValidationFormContent
);

const MassValidationModal = (): JSX.Element => {
  const formRef = useRef<{ form: WrappedFormUtils<MassValidationModel> } | null>(null);
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const { dataType, entityType, rootFolder } = useMassValidationRouterMatch();

  const onCancel = (): void => {
    setOpen(false);
  };

  const onOpen = (): void => {
    setOpen(true);
  };

  const onSubmit = (): void => {
    if (formRef.current) {
      formRef.current.form.validateFields((err, data) => {
        if (err) {
          return;
        }
        history.push(`/mass/validation/${data.dataType}:${data.entityType}:${data.rootFolder}`);
        setOpen(false);
      });
    }
  };

  return (
    <>
      <Button type="link" size="small" onClick={onOpen}>
        <Icon type="copy" />
        <span>
          <FormattedMessage {...entityTableMessages.massValidationChangeType} />
        </span>
      </Button>
      <BaseModal
        title={<FormattedMessage {...headerMessages.massValidation} />}
        visible={isOpen}
        onCancel={onCancel}
        onOk={onSubmit}
        width={700}
        okText={<FormattedMessage {...commonMessages.done} />}
      >
        <MassValidationForm
          initData={{
            dataType,
            entityType,
            rootFolder,
          }}
          wrappedComponentRef={formRef}
        />
      </BaseModal>
    </>
  );
};

export default memo(MassValidationModal);
