import { createSlice } from '@reduxjs/toolkit';
import configProviderEn from 'antd/es/locale/en_US';
import { createNewIntl } from '../../helpers';
import LangAsyncActions from './LangAsyncActions';

const LANG_SLICE_NAME = 'lang';
const LANG_INITIAL_STATE: STATES.Language = {
  currentLanguage: navigator.language,
  messages: {},
  intl: createNewIntl(navigator.language, {}),
  configProviderLocale: configProviderEn,
};

const LangSlice = createSlice({
  name: LANG_SLICE_NAME,
  initialState: LANG_INITIAL_STATE,
  extraReducers: (builder) => {
    builder.addCase(LangAsyncActions.setLanguage.fulfilled, (_state, { payload }) => {
      const { lang, messages, configProviderLocale } = payload;

      return {
        currentLanguage: lang,
        messages,
        intl: createNewIntl(lang, messages),
        configProviderLocale,
      };
    });
  },
  reducers: {},
});

export default LangSlice;
