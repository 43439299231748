import { Layout } from '@lucid/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box } from '../../components';
import GeneratedSchemaEditContainer from './GeneratedSchemaEditContainer';
import SampleDataEditColumn from './SampleDataEditColumn';

const generationSelector = createSelector(
  ({ generation }: STATES.App) => generation.selectedKey,
  (selectedKey) => selectedKey
);

const Generation = (): JSX.Element => {
  const selectedKey = useSelector(generationSelector);

  return (
    <>
      <Layout.Content>
        <Box isFlex justifyContent="space-between" width="100%" height="100%" flexWrap="nowrap">
          <SampleDataEditColumn resourceKey={selectedKey} />
          <GeneratedSchemaEditContainer />
        </Box>
      </Layout.Content>
    </>
  );
};

export default Generation;
