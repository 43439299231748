import { EntityAddress, EntityAddressType, EntityStoreProvider } from '@coherent/entity-store-ui';
import { EntityStoreJsonEditorProvider } from '@coherent/json-editor';
import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const esWrapperSelector = createSelector(
  ({ auth }: STATES.App) => auth.accessToken,
  ({ env }: STATES.App) => env,
  (accessToken, env) => ({
    accessToken,
    ...env,
  })
);

const EntityStoreWrapperProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const { accessToken, entityStoreHost, globalRefMappingsEntity } = useSelector(esWrapperSelector);

  const refMappingEntity = useMemo<EntityAddress>(() => {
    return {
      path: globalRefMappingsEntity,
      dataType: EntityAddressType.Blob,
    };
  }, [globalRefMappingsEntity]);

  return (
    <EntityStoreProvider accessToken={accessToken} entityHostUrl={entityStoreHost}>
      <EntityStoreJsonEditorProvider refMappingEntity={refMappingEntity}>{children}</EntityStoreJsonEditorProvider>
    </EntityStoreProvider>
  );
};

export default EntityStoreWrapperProvider;
