import { useEntityStoreContext } from '@coherent/entity-store-ui';
import { useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { Icon, Layout, Modal } from '@lucid/core';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LOGIN_PATH } from '../../common';
import { Box } from '../../components';
import { Keycloak } from '../../helpers/keycloak';
import { useEntityAddressRouteMatch, useSchemas } from '../../hooks';
import { confirmMessages, userControlMessages } from '../../locale';
import DataEditColumn from './DataEditColumn';
import SchemaEditColumn from './SchemaEditColumn';

const homeSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.selectedKey,
  (selectedKey) => selectedKey
);
const authSelector = createSelector(
  ({ auth }: STATES.App) => auth.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);

const envSelector = createSelector(
  ({ env }: STATES.App) => env,

  ({ environmentKey, entityStoreHost, globalRefMappingsEntity, keycloakClientId, keycloakDomain, keycloakTenant }) => ({
    environmentKey,
    entityStoreHost,
    globalRefMappingsEntity,
    keycloakClientId,
    keycloakDomain,
    keycloakTenant,
  })
);

const Home = (): JSX.Element => {
  const { entityHostUrl } = useEntityStoreContext();
  const { loadedGlobalRefsEntity } = useEntityStoreJsonEditorContext();
  const intl = useIntl();
  const { entityAddressFromPath } = useEntityAddressRouteMatch();
  const { loadSetupsForEntity } = useSchemas();
  const selectedKey = useSelector(homeSelector);
  const isAuthenticated = useSelector(authSelector);
  const { keycloakDomain, keycloakTenant, keycloakClientId } = useSelector(envSelector);

  useEffect(() => {
    if (!isAuthenticated && entityAddressFromPath) {
      const keycloak = new Keycloak(
        keycloakDomain,
        keycloakTenant,
        keycloakClientId,
        `${window.location.origin}${LOGIN_PATH}?return_url=${window.location.pathname}`,
        window.location.origin
      );

      Modal.confirm({
        title: intl.formatMessage(confirmMessages.loginRequired),
        icon: <Icon type="warning" />,
        okText: intl.formatMessage(userControlMessages.login),
        onOk() {
          window.location.replace(keycloak.singleSignOnUrl);
        },
      });
    }
    // Only check on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityAddressFromPath]);

  useEffect(() => {
    if (!loadedGlobalRefsEntity || !entityAddressFromPath) {
      return;
    }

    loadSetupsForEntity(entityAddressFromPath);
  }, [entityHostUrl, loadSetupsForEntity, entityAddressFromPath, loadedGlobalRefsEntity]);

  return (
    <>
      <Layout.Content>
        <Box isFlex justifyContent="space-between" width="100%" height="100%" flexWrap="nowrap">
          <SchemaEditColumn resourceKey={selectedKey} />
          <DataEditColumn />
        </Box>
      </Layout.Content>
    </>
  );
};

export default Home;
