import { DocStore } from '@coherent/entity-store';
import { detectRefURIFromJsonSchema, EntityJsonSetups, getEntityRefsFromSchema } from '@coherent/json-editor';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RESOURCE_KEY_JSON_SCHEMA, RESOURCE_KEY_UI_SCHEMA } from '../../common';

const detectNewRefsMappingFromSchema = async (
  schema: Record<string, unknown>,
  currentRefsMappingList: Record<string, ENTITIES.JsonResourceObject>,
  docStore: DocStore | null,
  globalRefMapping: Record<string, string>,
  entityHostUrl: string
): Promise<Record<string, ENTITIES.JsonResourceObject>> => {
  const triedToLoadUris = await detectRefURIFromJsonSchema(schema);
  const newUris = triedToLoadUris.filter((uri) => !currentRefsMappingList[uri]);
  const jsonOfNewUris = await Promise.all(
    newUris.map(
      async (uri): Promise<Record<string, unknown>> => {
        try {
          const response = await fetch(uri, {
            method: 'GET',
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return await response.json();
        } catch (error) {
          return {};
        }
      }
    )
  );

  const refEntities = await getEntityRefsFromSchema(
    schema,
    Object.keys(currentRefsMappingList),
    docStore,
    globalRefMapping,
    entityHostUrl
  );

  let newRefsMappingList = newUris.reduce((prev, uri, index) => {
    return {
      ...prev,
      [uri]: {
        entityAddress: null,
        entityVersion: null,
        obj: jsonOfNewUris[index],
        originObj: jsonOfNewUris[index],
        resourceKey: uri,
      },
    };
  }, {} as Record<string, ENTITIES.JsonResourceObject>);

  newRefsMappingList = refEntities.reduce<Record<string, ENTITIES.JsonResourceObject>>(
    (prev, { refUri, refEntity }) => {
      if (!refEntity) {
        return {
          ...prev,
          [refUri]: {
            entityAddress: null,
            entityVersion: null,
            originObj: {},
            obj: {},
            resourceKey: refUri,
          },
        };
      }

      const entityDataObj = refEntity.entityData?.data ?? {};

      return {
        ...prev,
        [refUri]: {
          entityAddress: refEntity.entityAddress,
          entityVersion: refEntity.entityData?.effectiveVersion ?? null,
          obj: entityDataObj,
          originObj: entityDataObj,
          resourceKey: refUri,
        },
      };
    },
    newRefsMappingList
  );

  return newRefsMappingList;
};

type SetSchemaReturnType = {
  schema: ENTITIES.JsonResourceObject;
  newRefsMappingList: Record<string, ENTITIES.JsonResourceObject>;
};

/** Use this to set a schema from data selected on resouce modal */
const setSchema = createAsyncThunk<
  SetSchemaReturnType,
  {
    schema: ENTITIES.JsonResourceObject;
    docStore: DocStore | null;
    globalRefMapping: Record<string, string>;
    entityHostUrl: string;
  },
  {
    state: STATES.App;
  }
>(
  'schemas/set',
  async ({ schema, docStore, globalRefMapping, entityHostUrl }, { getState }): Promise<SetSchemaReturnType> => {
    const {
      schemas: { refsMappingList },
    } = getState();
    const { resourceKey, obj } = schema;

    if (resourceKey === RESOURCE_KEY_UI_SCHEMA) {
      return {
        schema,
        newRefsMappingList: {},
      };
    }

    const newRefsMappingList = await detectNewRefsMappingFromSchema(
      obj,
      resourceKey === RESOURCE_KEY_JSON_SCHEMA ? {} : refsMappingList,
      docStore,
      globalRefMapping,
      entityHostUrl
    );

    return {
      schema,
      newRefsMappingList,
    };
  }
);

type SetSchemasWithJSONSetupsType = Pick<STATES.Schemas, 'uiSchema' | 'jsonSchema' | 'refsMappingList'>;

const setSchemasWithJSONSetups = createAsyncThunk<
  SetSchemasWithJSONSetupsType,
  {
    jsonSetups: EntityJsonSetups;
    docStore: DocStore | null;
    globalRefMapping: Record<string, string>;
    entityHostUrl: string;
  },
  {
    state: STATES.App;
  }
>(
  'schemas/setFromJSONSetups',
  async ({ jsonSetups, docStore, globalRefMapping, entityHostUrl }): Promise<SetSchemasWithJSONSetupsType> => {
    const { schema, uischema } = jsonSetups;
    const schemaResouce: ENTITIES.JsonResourceObject = {
      entityAddress: null,
      entityVersion: null,
      obj: {},
      originObj: {},
      resourceKey: RESOURCE_KEY_JSON_SCHEMA,
    };
    const uiSchemaResouce: ENTITIES.JsonResourceObject = {
      entityAddress: null,
      entityVersion: null,
      obj: {},
      originObj: {},
      resourceKey: RESOURCE_KEY_UI_SCHEMA,
    };

    if (schema) {
      schemaResouce.entityAddress = schema.entityAddress;

      const schemaObj = schema.entityData?.data ?? {};

      schemaResouce.obj = schemaObj;
      schemaResouce.originObj = schemaObj;
      schemaResouce.entityVersion = schema.entityData?.effectiveVersion ?? null;
    }

    if (uischema) {
      uiSchemaResouce.entityAddress = uischema.entityAddress;

      const uiSchemaObj = uischema.entityData?.data ?? {};

      uiSchemaResouce.obj = uiSchemaObj;
      uiSchemaResouce.originObj = uiSchemaObj;
      uiSchemaResouce.entityVersion = uischema.entityData?.effectiveVersion ?? null;
    }

    const refsMappingList = await detectNewRefsMappingFromSchema(
      jsonSetups.schema?.entityData?.data ?? {},
      {},
      docStore,
      globalRefMapping,
      entityHostUrl
    );

    return {
      jsonSchema: schemaResouce,
      uiSchema: uiSchemaResouce,
      refsMappingList,
    };
  }
);

type ApplyChangesReturnType = Nullable<{
  resourceKey: string;
  obj: Record<string, unknown>;
  newRefsMappingList: Record<string, ENTITIES.JsonResourceObject>;
}>;

const applyChanges = createAsyncThunk<
  ApplyChangesReturnType,
  {
    resourceKey: string;
    textContent: string;
    docStore: DocStore | null;
    globalRefMapping: Record<string, string>;
    entityHostUrl: string;
  },
  {
    state: STATES.App;
  }
>(
  'schemas/applyChanges',
  async (
    { resourceKey, textContent, docStore, globalRefMapping, entityHostUrl },
    { getState }
  ): Promise<ApplyChangesReturnType> => {
    const {
      schemas: { resourcesHaveErrors, resourcesHaveChanges, refsMappingList },
    } = getState();

    if (resourcesHaveErrors[resourceKey] || !resourcesHaveChanges[resourceKey]) {
      return null;
    }

    const obj = JSON.parse(textContent) as Record<string, unknown>;

    if (resourceKey === RESOURCE_KEY_UI_SCHEMA) {
      return {
        resourceKey,
        obj,
        newRefsMappingList: {},
      };
    }

    const newRefsMappingList = await detectNewRefsMappingFromSchema(
      obj,
      refsMappingList,
      docStore,
      globalRefMapping,
      entityHostUrl
    );

    return Promise.resolve({
      resourceKey,
      obj,
      newRefsMappingList,
    });
  }
);

export default {
  setSchema,
  setSchemasWithJSONSetups,
  applyChanges,
};
