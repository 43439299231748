import { EntityAddress } from '@coherent/entity-store-ui';
import { Tabs, Tooltip } from '@lucid/core';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useModal } from '../../hooks';
import { commonMessages, jsonModalMessages } from '../../locale';
import { EntityStoreTab, LocalTab, RawTab, RemoteTab } from '../JsonResourceTabs';
import BaseModal from './BaseModal';
import { MODAL_WIDTH, TabsContainer } from './JsonResourceModal.styled';

const userControlSelector = createSelector(
  ({ auth }: STATES.App) => auth,
  ({ isAuthenticated }) => ({
    isAuthenticated,
  })
);
export type JsonResourceModalProps = {
  title: string;
  resourceKey: string;
  onDone: (data: Record<string, unknown>, resourceKey: string, entity?: EntityAddress) => void;
  children?: (props: { onOpen: () => void }) => JSX.Element;
  isVisible?: boolean;
  setVisible?: (visible: boolean) => void;
};

const JsonResourceModal = ({
  title,
  resourceKey,
  onDone,
  children,
  isVisible,
  setVisible,
}: JsonResourceModalProps): JSX.Element => {
  const [entity, setEntity] = useState<EntityAddress | undefined>(undefined);
  const intl = useIntl();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [data, setData] = useState<Record<string, unknown>>({});
  const { isAuthenticated } = useSelector(userControlSelector);
  const { isOpen, onCancel, onOpen } = useModal({ isVisible, setVisible });

  const handleDataChange = useCallback((obj: Record<string, unknown>): void => {
    setData(obj);
    setDisabled(false);
  }, []);

  const resetModal = (): void => {
    setEntity(undefined);
    setData({});
    setDisabled(true);
  };

  const handleOnDone = (): void => {
    onDone(data, resourceKey, entity);
    resetModal();
    onCancel();
  };

  return (
    <>
      {children && children({ onOpen })}

      <BaseModal
        visible={isOpen}
        onCancel={onCancel}
        onOk={handleOnDone}
        okText={intl.formatMessage(commonMessages.done)}
        okButtonProps={{
          disabled,
        }}
        width={MODAL_WIDTH}
        title={<FormattedMessage {...jsonModalMessages.title} values={{ title }} />}
      >
        <TabsContainer>
          <Tabs type="card" defaultActiveKey="local-tab">
            <Tabs.TabPane tab={intl.formatMessage(jsonModalMessages.tabLocal)} key="local-tab">
              <LocalTab setData={handleDataChange} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage(jsonModalMessages.tabRemote)} key="remote-tab">
              <RemoteTab setData={handleDataChange} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={intl.formatMessage(jsonModalMessages.tabRaw)} key="raw-tab">
              <RawTab setData={handleDataChange} data={data} />
            </Tabs.TabPane>
            <Tabs.TabPane
              disabled={!isAuthenticated}
              key="entity-tab"
              tab={
                !isAuthenticated ? (
                  <Tooltip title={intl.formatMessage(jsonModalMessages.loggedIn)}>
                    {intl.formatMessage(jsonModalMessages.tabEntityStore)}
                  </Tooltip>
                ) : (
                  intl.formatMessage(jsonModalMessages.tabEntityStore)
                )
              }
            >
              <EntityStoreTab setEntity={setEntity} setData={handleDataChange} />
            </Tabs.TabPane>
          </Tabs>
        </TabsContainer>
      </BaseModal>
    </>
  );
};

export default JsonResourceModal;
