import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LOGIN_PATH } from '../../common';
import { Keycloak } from '../../helpers/keycloak';
import { userControlMessages } from '../../locale';
import { Auth, useAppDispatch } from '../../store';

const authSelector = createSelector(
  ({ auth }: STATES.App) => auth,
  ({ isAuthenticated, displayName }) => ({
    isAuthenticated,
    displayName,
  })
);

const envSelector = createSelector(
  ({ env }: STATES.App) => env,

  ({ environmentKey, entityStoreHost, globalRefMappingsEntity, keycloakClientId, keycloakDomain, keycloakTenant }) => ({
    environmentKey,
    entityStoreHost,
    globalRefMappingsEntity,
    keycloakClientId,
    keycloakDomain,
    keycloakTenant,
  })
);

const UserAction = (): JSX.Element => {
  const { isAuthenticated } = useSelector(authSelector);
  const { keycloakDomain, keycloakTenant, keycloakClientId } = useSelector(envSelector);
  const keycloak = new Keycloak(
    keycloakDomain,
    keycloakTenant,
    keycloakClientId,
    `${window.location.origin}${LOGIN_PATH}?return_url=${window.location.pathname}`,
    window.location.origin
  );

  const dispatch = useAppDispatch();

  if (!isAuthenticated) {
    return (
      <a href={keycloak.singleSignOnUrl}>
        <FormattedMessage {...userControlMessages.login} />
      </a>
    );
  }

  const onLogout = (): void => {
    dispatch(Auth.actions.logout());
  };

  return (
    <a href={keycloak.singleSignOutUrl} onClick={onLogout}>
      <FormattedMessage {...userControlMessages.logout} />
    </a>
  );
};

export default UserAction;
