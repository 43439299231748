import { createAsyncThunk } from '@reduxjs/toolkit';

export type EnvResponseToken = Record<string, Omit<STATES.Env, 'environmentKey' | 'apiResponse'>>;

const fetchEnv = createAsyncThunk('env/get', async () => {
  const response = await fetch(process.env.REACT_APP_ENV_URL || '');

  if (response.status !== 200) {
    return {
      apiResponse: undefined,
    };
  }

  const apiResponse = (await response.json()) as EnvResponseToken;

  return {
    apiResponse,
  };
});

export default { fetchEnv };
