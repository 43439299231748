import { DocStore, DocStorePagedRequest, SearchOperator } from '@coherent/entity-store';
import { downloadEntity, EntityAddress, EntityAddressType, EntityData } from '@coherent/entity-store-ui';
import { createAsyncThunk } from '@reduxjs/toolkit';
import Ajv from 'ajv';
import isEmpty from 'lodash/isEmpty';

const createValidate = (
  jsonSchema: ENTITIES.JsonResourceObject,
  refsMappingList: Record<string, ENTITIES.JsonResourceObject>
): Ajv.ValidateFunction => {
  const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    schemaId: 'auto',
    $data: true,
  });

  Object.entries(refsMappingList).forEach(([key, value]) => {
    ajv.removeSchema(key);
    ajv.addSchema(value.obj, key);
  });

  return ajv.compile(jsonSchema.obj);
};

const changeInputs = createAsyncThunk(
  'massValidation/changeInputs',
  async (
    arg: {
      pageIndex: number;
      dataType: EntityAddressType;
      entityType: string;
      rootFolder: string;
      docStore: DocStore;
      entityHostUrl: string;
      invalidOnly: boolean;
    },
    { getState }
  ) => {
    const { dataType, entityType, rootFolder, docStore, invalidOnly, entityHostUrl, pageIndex } = arg;
    const {
      schemas: { refsMappingList, jsonSchema },
    } = getState() as STATES.App;
    const validate = createValidate(jsonSchema, refsMappingList);
    let entities: ENTITIES.EntitiesTableRow[] = [];
    let total = 0;

    if (rootFolder && entityType) {
      type ResultForOnePageLoad = {
        count: number;
        numOfLoad: number;
        entitiesPerPage: ENTITIES.EntitiesTableRow[];
      };

      const requestParams: DocStorePagedRequest = {
        page: 0,
        pageSize: 10,
        searchParams: [
          {
            field: 'Type',
            op: SearchOperator.Equal,
            value: entityType,
          },
        ],
        sort: {
          field: 'created',
          direction: 'desc',
        },
      };

      const loadOnePage = async (page: number): Promise<ResultForOnePageLoad> => {
        const { count, result } = await docStore.folders.getChildDocuments(rootFolder, {
          ...requestParams,
          page,
        });

        let entitiesPerPage = await Promise.all(
          result.map(
            async (entity): Promise<ENTITIES.EntitiesTableRow> => {
              const entityAddress = {
                dataType,
                entityType,
                path: entity.path,
              };

              const entityData = await downloadEntity(entityAddress, entityHostUrl, docStore);

              return {
                path: entity.path,
                updated: entity.updated,
                created: entity.created,
                entityData,
                isValid: !isEmpty(entityData?.data) && (validate(entityData?.data ?? {}) as boolean),
              };
            }
          )
        );

        const numOfLoad = entitiesPerPage.length;

        if (invalidOnly) {
          // switch to loadmore mode with invalid only checked
          entitiesPerPage = entitiesPerPage.filter((entity) => !entity.isValid);
        }

        return { count, entitiesPerPage, numOfLoad };
      };

      if (invalidOnly) {
        const loadToEnd = async (
          page: number,
          numberOfLoad: number,
          loadedEntities: ENTITIES.EntitiesTableRow[]
        ): Promise<ResultForOnePageLoad> => {
          const onePageResult = await loadOnePage(page);
          const nextLoadedEntities = [...loadedEntities, ...onePageResult.entitiesPerPage];
          const nextNumberOfLoad = numberOfLoad + onePageResult.numOfLoad;

          if (nextNumberOfLoad === onePageResult.count) {
            return {
              ...onePageResult,
              entitiesPerPage: nextLoadedEntities,
            };
          }

          return loadToEnd(page + 1, nextNumberOfLoad, nextLoadedEntities);
        };

        const { entitiesPerPage, count } = await loadToEnd(1, 0, []);

        entities = entitiesPerPage;
        total = count;
      } else {
        const { entitiesPerPage, count } = await loadOnePage(pageIndex);

        entities = entitiesPerPage;
        total = count;
      }
    }

    return {
      dataType,
      entityType,
      rootFolder,
      entities,
      total,
      invalidOnly,
      pageIndex,
    };
  }
);

const updateEntity = createAsyncThunk(
  'massValidation/updateEntity',
  async (arg: { savedData: EntityData; savedAddress: EntityAddress }, { getState }) => {
    const { savedData } = arg;
    const {
      schemas: { refsMappingList, jsonSchema },
    } = getState() as STATES.App;

    const validate = createValidate(jsonSchema, refsMappingList);
    const isValid = !isEmpty(savedData.data) && (validate(savedData.data) as boolean);

    return Promise.resolve({
      isValid,
      savedData,
    });
  }
);

export default {
  changeInputs,
  updateEntity,
};
