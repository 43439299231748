import { EditorType, JsonError, RefMap } from '@coherent/json-editor';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import React, { memo } from 'react';
import { Box } from '..';
import { StyledJsonEditor } from '../../containers/Home/Home.styled';
import Theme from '../../styles/theme';

export type SchemaEditProps = {
  onErrors?: (errors: readonly JsonError[]) => void;
  schema?: JsonSchema;
  onChange?: (data: Record<string, unknown>) => void;
  headerTitle?: React.ReactNode;
  initData: Record<string, unknown>;
  key?: React.Key | null;
  headerControls?: React.ReactNode;
  textualOnTextChange?: (content: string) => void;
  textualInitTextData?: string;
  displayType?: EditorType;
  uiSchema?: UISchemaElement;
  schemaRefs?: Record<string, RefMap>;
};
const SchemaEdit = (props: SchemaEditProps): JSX.Element => {
  return (
    <Box
      height="100%"
      width="100%"
      isFlex
      borderLeft="1px solid"
      borderColor={Theme.color.secondary}
      data-testid="json-editor-container"
      maxWidth="100%"
      overflow="hidden"
    >
      <StyledJsonEditor {...props} />
    </Box>
  );
};

export default memo(SchemaEdit);
