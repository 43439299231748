import { EntityData } from '@coherent/entity-store-ui';
import { Icon } from '@lucid/core';
import { ColumnProps } from '@lucid/core/dist/Table';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { formatDateTime } from '../../helpers';
import { entitiesValidationColumnLabels } from '../../locale';
import { StyledEntitiesTable } from './EntitiesValidation.styled';

export type EntitiesValidationTableProps = {
  entities: ENTITIES.EntitiesTableRow[];
  onSelectEntity: (path: string, entityData: EntityData) => void;
  pagination?: { currentPage: number; total: number; pageSize: number };
  onPageChange: (page: number) => void;
};

const EntitiesValidationTable = ({
  entities,
  onSelectEntity,
  pagination,
  onPageChange,
}: EntitiesValidationTableProps): JSX.Element => {
  const [selectedEntityPath, setSelectedEntity] = useState('');
  const intl = useIntl();

  const columns = useMemo<ColumnProps<ENTITIES.EntitiesTableRow>[]>(
    () => [
      {
        title: intl.formatMessage(entitiesValidationColumnLabels.entitiesValidationPath),
        dataIndex: 'path',
        width: '40%',
      },
      {
        title: intl.formatMessage(entitiesValidationColumnLabels.entitiesValidationModifiedAt),
        dataIndex: 'updated',
        width: '25%',
        render: (text: string, record: ENTITIES.EntitiesTableRow) => {
          return formatDateTime(record.updated);
        },
      },
      {
        title: intl.formatMessage(entitiesValidationColumnLabels.entitiesValidationCreatedAt),
        dataIndex: 'created',
        width: '25%',
        render: (text: string, record: ENTITIES.EntitiesTableRow) => {
          return formatDateTime(record.created);
        },
      },
      {
        title: intl.formatMessage(entitiesValidationColumnLabels.entitiesValidationStatus),
        dataIndex: 'status',
        width: '10%',
        align: 'center',
        render: (text: string, record: ENTITIES.EntitiesTableRow) => {
          return <Icon type={record.isValid ? 'checkCircle' : 'warning'} />;
        },
      },
    ],
    [intl]
  );

  return (
    <StyledEntitiesTable
      hasData={entities.length > 0}
      showAll={!pagination}
      bordered
      dataSource={entities}
      rowKey="path"
      columns={columns}
      scroll={{ y: pagination ? 'calc(100vh - 205px)' : 'calc(100vh - 160px)' }}
      pagination={
        pagination
          ? {
              ...pagination,
              current: pagination.currentPage,
              onChange: onPageChange,
            }
          : false
      }
      onRow={(record: ENTITIES.EntitiesTableRow) => {
        return {
          onClick: () => {
            setSelectedEntity(record.path);
            if (record.entityData) {
              onSelectEntity(record.path, record.entityData);
            }
          },
        };
      }}
      rowClassName={(record: ENTITIES.EntitiesTableRow) => {
        return selectedEntityPath === record.path ? 'ant-table-row-selected' : '';
      }}
    />
  );
};

export default EntitiesValidationTable;
