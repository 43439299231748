import { Button } from '@lucid/core';
import { Upload } from 'antd';
import { RcCustomRequestOptions, RcFile } from 'antd/lib/upload/interface';
import React from 'react';
import { useIntl } from 'react-intl';
import { messageHolder } from '../../helpers';
import { jsonModalMessages } from '../../locale';
import { Box } from '../Atom';
import { LocalTabContainer, TAB_CONTENT_HEIGHT } from './common.styled';

export type TabProps = {
  setData: (obj: Record<string, unknown>) => void;
};

const LocalTab = ({ setData }: TabProps): JSX.Element => {
  const intl = useIntl();
  const customRequest = ({ file: uploadedFile, onSuccess }: RcCustomRequestOptions): void => {
    setTimeout(() => {
      onSuccess({}, uploadedFile);
    }, 0);
  };

  const beforeUpload = (uploadedFile: RcFile): boolean => {
    const isJson = uploadedFile.type === 'application/json';

    if (isJson) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target) {
          try {
            const obj = JSON.parse(e.target.result as string) as Record<string, unknown>;
            setData(obj);
          } catch (error) {
            messageHolder.error(error);
          }
        }
      };

      reader.readAsText(uploadedFile);
    } else {
      setData({});
    }
    return isJson;
  };

  return (
    <LocalTabContainer
      width="100%"
      minHeight={TAB_CONTENT_HEIGHT}
      isFlex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center">
        <p>{intl.formatMessage(jsonModalMessages.dropFile)}</p>
        <Upload accept=".json" beforeUpload={beforeUpload} customRequest={customRequest}>
          <Button shape="round" size="large" type="primary">
            {intl.formatMessage(jsonModalMessages.chooseFile)}
          </Button>
        </Upload>
      </Box>
    </LocalTabContainer>
  );
};

export default LocalTab;
