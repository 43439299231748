import { Layout } from '@lucid/core';
import querystring from 'query-string';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_JSON_RESOURCE, RESOURCE_KEY_COMPARE_1ST, RESOURCE_KEY_COMPARE_2ND } from '../../common';
import { buildEntityAddressPathParam } from '../../helpers';
import { useDiffsRouteMatch } from '../../hooks';
import { StyledJsonCompare } from './Diffs.styled';

const Diffs = (): JSX.Element => {
  const history = useHistory();
  const { firstJsonResource, secondJsonResource } = useDiffsRouteMatch();
  const [localFirstJsonResource, setLocalFirstJsonResource] = useState<ENTITIES.JsonResourceObject>({
    ...DEFAULT_JSON_RESOURCE,
    resourceKey: RESOURCE_KEY_COMPARE_1ST,
  });
  const [localSecondJsonResource, setLocalSecondJsonResource] = useState<ENTITIES.JsonResourceObject>({
    ...DEFAULT_JSON_RESOURCE,
    resourceKey: RESOURCE_KEY_COMPARE_2ND,
  });

  const onChangeResource = useCallback(
    (jsonResource: ENTITIES.JsonResourceObject) => {
      const entities = [firstJsonResource, secondJsonResource];

      if (RESOURCE_KEY_COMPARE_1ST === jsonResource.resourceKey) {
        entities[0] = jsonResource;
        setLocalFirstJsonResource(jsonResource);
      } else {
        entities[1] = jsonResource;
        setLocalSecondJsonResource(jsonResource);
      }

      const [first, second] = entities.map((entity) => {
        if (entity?.entityAddress) {
          return buildEntityAddressPathParam(entity.entityAddress);
        }

        return '';
      });

      const nextPath = querystring.stringifyUrl({
        url: '/diffs',
        query: { first, second },
      });

      history.push(nextPath);
    },
    [history, firstJsonResource, secondJsonResource]
  );

  return (
    <Layout.Content>
      <StyledJsonCompare
        firstJsonResource={firstJsonResource ?? localFirstJsonResource}
        secondJsonResource={secondJsonResource ?? localSecondJsonResource}
        onChangeResource={onChangeResource}
      />
    </Layout.Content>
  );
};

export default Diffs;
