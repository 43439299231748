import { Layout } from '@lucid/core';
import styled from 'styled-components';
import { Box } from '../../components';

export const SIDEBAR_WIDTH = 300;

export const StyledSidebarHeader = styled(Box)`
  background: ${({ theme }) => theme.color.primary};
  font-weight: bold;
  min-height: ${({ theme }) => theme.space[6]}px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]}px;
`;

export const StyledSideBar = styled(Layout.Sider)`
  background-color: ${({ theme }) => theme.color.white};
  height: calc(100vh - 64px);

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSidebarHeaderChild = styled.div`
  position: absolute;
  right: 0;
  margin-right: ${({ theme }) => theme.space[2]}px;

  .ant-btn.ant-btn-dashed {
    background-color: ${({ theme }) => theme.color.primary};
    padding: 0;
    padding-top: 1px;
  }
`;

export const SchemaListItemContainer = styled.div`
  height: calc(100vh - 112px);
  overflow-y: auto;
`;
