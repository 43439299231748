import { EntityAddress, EntityAddressType, useEntityStoreContext } from '@coherent/entity-store-ui';
import { EntityJsonSetups, useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { buildEntityAddressPathParam, messageHolder } from '../helpers';
import { Entity, Schemas, UI, useAppDispatch } from '../store';

type UseSchemasType = {
  onSelectResource: (data: Record<string, unknown>, resourceKey: string, entity?: EntityAddress | undefined) => void;
  loadSetupsForEntity: (entityAddress: EntityAddress) => void;
  loadSchemaSetupsForType: (entityType: string, dataType: EntityAddressType) => Promise<EntityJsonSetups | null>;
};

const schemaPathsSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.jsonSchema.entityAddress?.path,
  ({ schemas }: STATES.App) => schemas.uiSchema.entityAddress?.path,
  (jsonSchemaEntityPath, uiSchemaEntityPath) => {
    return {
      jsonSchemaEntityPath,
      uiSchemaEntityPath,
    };
  }
);

const useSchemas = (): UseSchemasType => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { docStore, entityHostUrl, downloadEntity } = useEntityStoreContext();
  const { refMapping, getJSONSetups, getJSONSetupsForType } = useEntityStoreJsonEditorContext();
  const { jsonSchemaEntityPath, uiSchemaEntityPath } = useSelector(schemaPathsSelector);
  const currentSchemasSetupsRefs = useRef<{ jsonSchemaEntityPath?: string; uiSchemaEntityPath?: string }>({
    jsonSchemaEntityPath,
    uiSchemaEntityPath,
  });

  useEffect(() => {
    currentSchemasSetupsRefs.current = {
      jsonSchemaEntityPath,
      uiSchemaEntityPath,
    };
  }, [jsonSchemaEntityPath, uiSchemaEntityPath]);

  const onSelectResource = useCallback(
    (data: Record<string, unknown>, resourceKey: string, entity?: EntityAddress | undefined): void => {
      if (!entity) {
        const schema: ENTITIES.JsonResourceObject = {
          obj: data,
          originObj: data,
          resourceKey,
          entityAddress: null,
          entityVersion: null,
        };
        void dispatch(
          Schemas.actions.setSchema({
            docStore,
            entityHostUrl,
            globalRefMapping: refMapping,
            schema,
          })
        );
        return;
      }

      void (async (): Promise<void> => {
        try {
          const entityData = await downloadEntity(entity);

          const schema = {
            obj: entityData?.data,
            originObj: entityData?.data,
            resourceKey,
            entityAddress: entity,
            entityVersion: null,
          } as ENTITIES.JsonResourceObject;

          void dispatch(
            Schemas.actions.setSchema({
              docStore,
              entityHostUrl,
              globalRefMapping: refMapping,
              schema,
            })
          );
        } catch (error) {
          messageHolder.error(error);
        }
      })();
    },
    [dispatch, docStore, entityHostUrl, refMapping, downloadEntity]
  );

  const dispatchActionsPrefillSchemas = useCallback(
    async (jsonSetups: EntityJsonSetups) => {
      // we won't reload the current state of schemas if ui/json schemas don't change
      // Doing this will prevent losing developing data (unsaved changes) when switching between data entities which have same setups
      if (
        currentSchemasSetupsRefs.current.jsonSchemaEntityPath !== jsonSetups.schema?.entityAddress.path ||
        currentSchemasSetupsRefs.current.uiSchemaEntityPath !== jsonSetups.uischema?.entityAddress.path
      ) {
        await dispatch(
          Schemas.actions.setSchemasWithJSONSetups({
            jsonSetups,
            docStore,
            entityHostUrl,
            globalRefMapping: refMapping,
          })
        );
      }
    },
    [dispatch, docStore, entityHostUrl, refMapping]
  );

  const loadSchemaSetupsForType = useCallback(
    async (entityType: string, dataType: EntityAddressType): Promise<EntityJsonSetups | null> => {
      try {
        dispatch(UI.actions.setGlobalLoading(true));

        const jsonSetups = await getJSONSetupsForType(entityType, dataType);

        await dispatchActionsPrefillSchemas(jsonSetups);
        dispatch(UI.actions.setGlobalLoading(false));

        return jsonSetups;
      } catch (error) {
        messageHolder.error(error);
        dispatch(UI.actions.setGlobalLoading(false));

        return null;
      }
    },
    [dispatch, getJSONSetupsForType, dispatchActionsPrefillSchemas]
  );

  const loadSetupsForEntity = useCallback(
    (entityAddress: EntityAddress): void => {
      void (async (): Promise<void> => {
        try {
          dispatch(UI.actions.setGlobalLoading(true));

          const jsonSetups = await getJSONSetups(entityAddress);

          await dispatchActionsPrefillSchemas(jsonSetups);

          dispatch(Entity.actions.setFromJSONSetups({ jsonSetups }));

          history.push(`/entity/${buildEntityAddressPathParam(entityAddress)}`);
          dispatch(UI.actions.setGlobalLoading(false));
        } catch (error) {
          messageHolder.error(error);
          dispatch(UI.actions.setGlobalLoading(false));
        }
      })();
    },
    [dispatch, getJSONSetups, dispatchActionsPrefillSchemas, history]
  );

  return {
    onSelectResource,
    loadSetupsForEntity,
    loadSchemaSetupsForType,
  };
};

export default useSchemas;
