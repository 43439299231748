import { createAsyncThunk } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';
import { LOGIN_PATH } from '../../common';
import { Keycloak } from '../../helpers';

const decodeAccessToken = (accessToken: string): { realm: string; displayName: string; expDate: Date } => {
  const decodedData = jwt.decode(accessToken) as Nullable<ENTITIES.JWTData>;

  if (!decodedData) {
    throw new Error('Invalid token');
  }

  const { exp, name, realm } = decodedData;

  return {
    displayName: name,
    realm,
    expDate: new Date(exp * 1000),
  };
};
type LoginPayload = { code: string; returnUrl: string };

const login = createAsyncThunk('auth/login', async ({ code, returnUrl }: LoginPayload, { getState }) => {
  const {
    env: { keycloakDomain, keycloakTenant, keycloakClientId },
  } = getState() as STATES.App;
  const keycloak = new Keycloak(
    keycloakDomain,
    keycloakTenant,
    keycloakClientId,
    `${window.location.origin}${LOGIN_PATH}?return_url=${returnUrl}`,
    window.location.origin
  );
  const { access_token: accessToken, refresh_token: newRefreshToken } = await keycloak.generateToken(code);
  const decodedData = decodeAccessToken(accessToken);

  return {
    accessToken,
    refreshToken: newRefreshToken,
    ...decodedData,
  };
});

const refreshToken = createAsyncThunk('auth/refresh', async (currentRefreshToken: string, { getState }) => {
  const {
    env: { keycloakDomain, keycloakTenant, keycloakClientId },
  } = getState() as STATES.App;
  const keycloak = new Keycloak(
    keycloakDomain,
    keycloakTenant,
    keycloakClientId,
    `${window.location.origin}${LOGIN_PATH}`,
    window.location.origin
  );
  const { access_token: nextAccessToken, refresh_token: nextRefreshToken } = await keycloak.refreshToken(
    currentRefreshToken
  );
  const decodedData = decodeAccessToken(nextAccessToken);

  return {
    accessToken: nextAccessToken,
    refreshToken: nextRefreshToken,
    ...decodedData,
  };
});

export default {
  login,
  refreshToken,
};
