import { AnyAction, CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import AuthAsyncActions from './AuthAsyncActions';

const AUTH_SLICE_NAME = 'auth';
export const AUTH_INITIAL_STATE: STATES.Auth = {
  accessToken: '',
  isAuthenticated: false,
  refreshToken: '',
  displayName: '',
  expDate: null,
  realm: '',
};

const logout: CaseReducer<STATES.Auth> = () => {
  return AUTH_INITIAL_STATE;
};

type AuthInfoProps = 'accessToken' | 'displayName' | 'realm' | 'refreshToken' | 'expDate';

function isActionWithAuthInfo(action: AnyAction): action is PayloadAction<Pick<STATES.Auth, AuthInfoProps>> {
  return [AuthAsyncActions.login.fulfilled.type, AuthAsyncActions.refreshToken.fulfilled.type].includes(action.type);
}

const AuthSlice = createSlice({
  name: AUTH_SLICE_NAME,
  initialState: AUTH_INITIAL_STATE,
  reducers: {
    logout,
  },
  extraReducers: (builder) => {
    builder.addMatcher(isActionWithAuthInfo, (state, { payload }) => {
      const { accessToken, refreshToken, displayName, expDate, realm } = payload;

      return {
        ...state,
        displayName,
        realm,
        accessToken,
        refreshToken,
        isAuthenticated: true,
        expDate,
      };
    });
    builder.addMatcher(
      (action): action is PayloadAction<unknown> =>
        [AuthAsyncActions.login.rejected.type, AuthAsyncActions.refreshToken.rejected.type].includes(action.type),
      () => {
        return AUTH_INITIAL_STATE;
      }
    );
  },
});

export default AuthSlice;
