import { defineMessages } from 'react-intl';
import { EnvEntry } from '../helpers';

export const commonMessages = defineMessages({
  ok: {
    id: 'common.ok',
    defaultMessage: 'OK',
  },
  continue: {
    id: 'common.continue',
    defaultMessage: 'Continue',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
  },
  apply: {
    id: 'common.apply',
    defaultMessage: 'Apply',
  },
  done: {
    id: 'common.done',
    defaultMessage: 'Done',
  },
  entityDataType: {
    id: 'common.entity.dataType',
    defaultMessage: `{dataType, select, 
      blob  {Blob}
      jsonData  {Json Data}}`,
  },
  entityPrivacyLevel: {
    id: 'common.entity.privacyLevel',
    defaultMessage: `{privacyLevel, select, 
      Global  {Global}
      Tenant  {Tenant}
      Private  {Private}}`,
  },
});

export const userControlMessages = defineMessages({
  loggingIn: {
    id: 'UserControl.loggingIn',
    defaultMessage: 'Logging in...',
  },
  login: {
    id: 'UserControl.login',
    defaultMessage: 'Login',
  },
  logout: {
    id: 'UserControl.logout',
    defaultMessage: 'Logout',
  },
  welcome: {
    id: 'UserControl.welcome',
    defaultMessage: 'Welcome {displayName} {realm}!',
  },
});

export const sidebarMessages = defineMessages({
  schemasTitle: {
    id: 'sidebar.schemas.title',
    defaultMessage: 'Schemas',
  },
  refSchemasTitle: {
    id: 'sidebar.refsMapping.title',
    defaultMessage: '$ref Mapping',
  },
  jsonDataSamples: {
    id: 'sidebar.jsonDataSamples.title',
    defaultMessage: 'Json Data Samples',
  },
});

export const sidebarItemMessages = defineMessages({
  title: {
    id: 'sidebar.item.title',
    defaultMessage: `{key, select, 
      JSON_SCHEMA  {JSON Schema}
      UI_SCHEMA  {UI Schema}
      other  {{key}}}`,
  },
  typeLabel: {
    id: 'sidebar.item.type.label',
    defaultMessage: `{type, select, 
      es  {ES}
      local  {Local}}`,
  },
  pathLabel: {
    id: 'sidebar.item.path.label',
    defaultMessage: 'Path:',
  },
  versionLabel: {
    id: 'sidebar.item.version.label',
    defaultMessage: 'Version:',
  },
  noSampleData: {
    id: 'sidebar.noSampleData',
    defaultMessage: 'No Sample Data',
  },
});

export const entityUploadModalMessages = defineMessages({
  title: {
    id: 'save.modal.title',
    defaultMessage: 'JSON Upload',
  },
  entityIdLabel: {
    id: 'save.modal.entityId.label',
    defaultMessage: 'Entity ID',
  },
  entityPathLabel: {
    id: 'save.modal.entityPath.label',
    defaultMessage: 'Entity path',
  },
  entityPathErorrRequired: {
    id: 'save.modal.entityPath.error.required',
    defaultMessage: 'Please enter the entity path',
  },
  entityTypeLabel: {
    id: 'save.modal.entityType.label',
    defaultMessage: 'Entity type',
  },
  dataTypeLabel: {
    id: 'save.modal.dataType.label',
    defaultMessage: 'Save as',
  },
  privacyLevelLabel: {
    id: 'save.modal.privacyLevel.label',
    defaultMessage: 'Privacy level',
  },
  download: {
    id: 'save.modal.download',
    defaultMessage: 'Download',
  },
  success: {
    id: 'save.modal.success',
    defaultMessage: 'Entity data updated successfully',
  },
  generatedSchemaSuccess: {
    id: 'save.modal.generatedSchemaSuccess',
    defaultMessage: 'Generated Json Schema Successfully uploaded',
  },
  error: {
    id: 'save.modal.error',
    defaultMessage: 'Entity data update failed',
  },
});

export const entityNewModalMessages = defineMessages({
  title: {
    id: 'new.entity.modal.title',
    defaultMessage: 'New entity',
  },
  prepareSetups: {
    id: 'new.entity.modal.prepare.setups',
    defaultMessage: 'Check and create all required entities for JSON/UI schemas with given information',
  },
});

export const jsonEditorMessages = defineMessages({
  menuNew: {
    id: 'jsonEditor.menu.new',
    defaultMessage: 'New',
  },
  menuSelect: {
    id: 'jsonEditor.menu.select',
    defaultMessage: 'Select',
  },
  menuReset: {
    id: 'jsonEditor.menu.reset',
    defaultMessage: 'Reset',
  },
  menuGenerate: {
    id: 'jsonEditor.menu.generate',
    defaultMessage: 'Generate',
  },
  menuDiffs: {
    id: 'jsonEditor.menu.diffs',
    defaultMessage: 'Show changes',
  },
  menuSave: {
    id: 'jsonEditor.menu.save',
    defaultMessage: 'Save',
  },
  menuSaveAs: {
    id: 'jsonEditor.menu.saveAs',
    defaultMessage: 'Save as...',
  },
  menuUI: {
    id: 'jsonEditor.menu.UI',
    defaultMessage: 'UI',
  },
  menuApply: {
    id: 'jsonEditor.menu.apply',
    defaultMessage: 'Apply',
  },
});

export const entityTableMessages = defineMessages({
  massValidationChangeType: {
    id: 'mass.validation.change.type',
    defaultMessage: 'Change Type',
  },
  massValidationInvalidOnly: {
    id: 'mass.validation.invalid.only',
    defaultMessage: 'Show invalid only',
  },
  massValidationHeading: {
    id: 'mass.validation.heading',
    defaultMessage: 'Mass Validation Type: {content}',
  },
  massValidationNoneSelected: {
    id: 'mass.validation.none.selected',
    defaultMessage: 'None Selected',
  },
  massValidationInputs: {
    id: 'mass.validation.selected',
    defaultMessage: '{entityType} ({dataType})',
  },
});

export const jsonModalMessages = defineMessages({
  title: {
    id: 'jsonModal.title',
    defaultMessage: 'JSON Resources: {title}',
  },
  tabLocal: {
    id: 'jsonModal.tab.local',
    defaultMessage: 'Local',
  },
  tabRemote: {
    id: 'jsonModal.tab.remote',
    defaultMessage: 'Remote',
  },
  tabRaw: {
    id: 'jsonModal.tab.raw',
    defaultMessage: 'Raw',
  },
  tabEntityStore: {
    id: 'jsonModal.tab.entityStore',
    defaultMessage: 'EntityStore',
  },
  dropFile: {
    id: 'jsonModal.local.dropFile',
    defaultMessage: 'Drop file here',
  },
  chooseFile: {
    id: 'jsonModal.tab.chooseFile',
    defaultMessage: 'Choose File',
  },
  placeholder: {
    id: 'jsonModal.remote.placeholder',
    defaultMessage: 'Enter a URL and press "Done"',
  },
  dataSchema: {
    id: 'jsonModal.data.schema',
    defaultMessage: 'Data Schema',
  },
  loggedIn: {
    id: 'jsonModal.entity.loggedIn',
    defaultMessage: 'User must be Logged in',
  },
  unsavedChangesConfirm: {
    id: 'jsonModal.unsaved.changes.confirm',
    defaultMessage: 'You have some unsaved changes. Are you sure you want to load the new Entity?',
  },
});

export const environmentModalMessages = defineMessages({
  placeholder: {
    id: 'envModal.placeholder',
    defaultMessage: 'Select environment: ',
  },
  info: {
    id: 'envModal.info',
    defaultMessage: '*** If you change the variables, you will have to re-login.',
  },
  title: {
    id: 'envModal.title',
    defaultMessage: 'Environment',
  },
  trigger: {
    id: 'envModal.trigger',
    defaultMessage: 'Environment: {environmentKey}',
  },
});

export const massValidationModalMessages = defineMessages({
  massValidationModalSource: {
    id: 'mass.validation.modal.source',
    defaultMessage: 'Source',
  },
  massValidationModalType: {
    id: 'mass.validation.modal.type',
    defaultMessage: 'Type',
  },
  massValidationModalRootFolder: {
    id: 'mass.validation.modal.root.folder',
    defaultMessage: 'Root folder',
  },
});

export const environmentTableMessages = defineMessages({
  colVariable: {
    id: 'envTable.col.variable',
    defaultMessage: 'Variable',
  },
  colInitialValue: {
    id: 'envTable.col.initialValue',
    defaultMessage: 'Initial value',
  },
  colCurrentValue: {
    id: 'envTable.col.currentValue',
    defaultMessage: 'Current value',
  },
});

export const environmentVariableMessages: {
  [key in keyof EnvEntry]: { id: string; defaultMessage: string };
} = defineMessages({
  keycloakDomain: {
    id: 'envTable.var.keycloakDomain',
    defaultMessage: 'Keycloak Domain',
  },
  keycloakTenant: {
    id: 'envTable.var.keycloakTenant',
    defaultMessage: 'Keycloak Tenant',
  },
  keycloakClientId: {
    id: 'envTable.var.keycloakClientId',
    defaultMessage: 'Keycloak Client Id',
  },
  globalRefMappingsEntity: {
    id: 'envTable.var.globalRefMappingsEntity',
    defaultMessage: 'Global Ref Mappings Entity',
  },
  entityStoreHost: {
    id: 'envTable.var.entityStoreHost',
    defaultMessage: 'EntityStore Host',
  },
});

export const compareMessages = defineMessages({
  title: {
    id: 'diffsModal.title',
    defaultMessage: 'Changes: {title}',
  },
  colTitle: {
    id: 'diffsPage.col.title',
    defaultMessage: `{resourceKey, select, 
      RESOURCE_KEY_COMPARE_1ST  {JSON Data 1st}
      RESOURCE_KEY_COMPARE_2ND  {JSON Data 2nd}
      RESOURCE_KEY_COMPARE_ORIGIN {Origin Data}
      RESOURCE_KEY_COMPARE_EDITING {Editing Data}
      other {}}`,
  },
});

export const headerMessages = defineMessages({
  editor: {
    id: 'header.editor',
    defaultMessage: 'Editor',
  },
  compare: {
    id: 'header.compare',
    defaultMessage: 'Compare',
  },
  massValidation: {
    id: 'header.mass.validation',
    defaultMessage: 'Mass Validation',
  },
  generation: {
    id: 'header.generation',
    defaultMessage: 'Generation',
  },
  data: {
    id: 'header.data',
    defaultMessage: 'Data',
  },
  generatedJsonSchema: {
    id: 'header.generatedJsonSchema',
    defaultMessage: 'Generated JSON Schema',
  },
});

export const confirmMessages = defineMessages({
  loginRequired: {
    id: 'confirm.loginRequired',
    defaultMessage: 'Login required to access EntityStore data',
  },
});

export const dataColMessages = defineMessages({
  noneSelected: {
    id: 'data.column.noneSelected',
    defaultMessage: 'No Entity selected',
  },
});

export const entitiesValidationColumnLabels = defineMessages({
  entitiesValidationPath: {
    id: 'entities.validation.path',
    defaultMessage: 'Path',
  },
  entitiesValidationCreatedAt: {
    id: 'entities.validation.created.at',
    defaultMessage: 'Created At',
  },
  entitiesValidationModifiedAt: {
    id: 'entities.validation.modified.at',
    defaultMessage: 'Modified At',
  },
  entitiesValidationStatus: {
    id: 'entities.validation.status',
    defaultMessage: 'Status',
  },
});
