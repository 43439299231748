import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SchemaItemStatusDot, TextEllipsis } from '../../components';
import { sidebarItemMessages } from '../../locale';

const SampleDataHeaderTitleSelector = createSelector(
  ({ generation }: STATES.App) => generation.selectedKey,
  ({ generation }: STATES.App) => generation.resourcesHaveChanges,
  ({ generation }: STATES.App) => generation.resourcesHaveErrors,
  ({ generation }: STATES.App) => generation.data,

  (selectedKey, resourcesHaveChanges, resourcesHaveErrors, data) => {
    return {
      selectedKey,
      resourcesHaveChanges,
      resourcesHaveErrors,
      data,
    };
  }
);

const SampleDataHeaderTitle = (): JSX.Element | null => {
  const intl = useIntl();

  const { resourcesHaveChanges, resourcesHaveErrors, selectedKey, data } = useSelector(SampleDataHeaderTitleSelector);

  const schema = data.find((resource) => resource.resourceKey === selectedKey);
  const schemaIndex = data.findIndex((resource) => resource.resourceKey === selectedKey);

  return (
    <>
      <SchemaItemStatusDot
        hasChanges={resourcesHaveChanges[selectedKey]}
        hasErrors={resourcesHaveErrors[selectedKey]}
      />

      <TextEllipsis>
        <span>{`#${String(schemaIndex + 1)} - `}</span>
        {schema?.entityAddress ? (
          intl.formatMessage(sidebarItemMessages.title, { key: schema?.entityAddress.path })
        ) : (
          <span>{intl.formatMessage(sidebarItemMessages.typeLabel, { type: 'local' })}</span>
        )}
      </TextEllipsis>
    </>
  );
};

export default memo(SampleDataHeaderTitle);
