import { EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { EditorType, EntityJsonSetups, JsonError } from '@coherent/json-editor';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { RESOURCE_KEY_DATA } from '../../common';

const ENTITY_SLICE_NAME = 'entity';
export const ENTITY_INITIAL_STATE: STATES.Entity = {
  data: {
    entityAddress: null,
    entityVersion: null,
    obj: {},
    originObj: {},
    resourceKey: RESOURCE_KEY_DATA,
  },
  dataDisplayType: EditorType.Textual,
};

const setErrors: CaseReducer<STATES.Entity, PayloadAction<{ errors?: JsonError[] }>> = (
  state,
  { payload: { errors } }
) => {
  return {
    ...state,
    errors,
  };
};

/**
 * Use this to set data column (from entity address path param or from entity tab on resource modal.
 * The component should get EntityJsonSetups from calling getJSONSetups (useEntityStoreContext) to dispatch this action
 */
const setFromJSONSetups: CaseReducer<
  STATES.Entity,
  PayloadAction<{ jsonSetups: EntityJsonSetups; forceDisplayType?: EditorType }>
> = (
  state,
  {
    payload: {
      jsonSetups: { data, entityType, uischema },
      forceDisplayType,
    },
  }
) => {
  const obj = data.entityData?.data ?? {};
  const entityVersion = data.entityData?.effectiveVersion ?? null;
  const entityAddress = {
    ...data.entityAddress,
    entityType,
    versionId: entityVersion?.id,
  };

  if (data.entityData?.entity) {
    entityAddress.path = data.entityData.entity.path;
    entityAddress.documentId = data.entityData.entity.id;
  }

  state.data = {
    entityAddress,
    entityVersion,
    obj,
    originObj: obj,
    resourceKey: RESOURCE_KEY_DATA,
  };

  if (forceDisplayType) {
    state.dataDisplayType = forceDisplayType;

    return;
  }

  const isUISchemaEmpty = isEmpty(uischema?.entityData?.data);
  state.dataDisplayType = isUISchemaEmpty ? EditorType.Textual : EditorType.JSONForm;
};

/** Use this to set data column (from file, url, raw on resource modal) */
const setData: CaseReducer<STATES.Entity, PayloadAction<{ obj: Record<string, unknown> }>> = (
  state,
  { payload: { obj } }
) => {
  state.data = {
    entityAddress: null,
    entityVersion: null,
    obj,
    originObj: obj,
    resourceKey: RESOURCE_KEY_DATA,
  };
};

const resetChanges: CaseReducer<STATES.Entity> = (state) => {
  state.data = {
    ...state.data,
    obj: { ...state.data.originObj },
  };
};

const updateAfterSaved: CaseReducer<
  STATES.Entity,
  PayloadAction<{ savedAddress: EntityAddress; savedData: EntityData }>
> = (state, { payload: { savedData, savedAddress } }) => {
  state.data = {
    ...state.data,
    entityAddress: {
      ...savedAddress,
      versionId: savedData.effectiveVersion?.id,
    },
    obj: savedData.data,
    originObj: savedData.data,
    entityVersion: savedData.effectiveVersion || null,
  };
};

const setDataDisplayType: CaseReducer<STATES.Entity, PayloadAction<EditorType>> = (state, { payload }) => {
  state.dataDisplayType = payload;
};

const EntitySlice = createSlice({
  name: ENTITY_SLICE_NAME,
  initialState: ENTITY_INITIAL_STATE,
  reducers: { setErrors, setFromJSONSetups, resetChanges, updateAfterSaved, setData, setDataDisplayType },
});

export default EntitySlice;
