import { Button, Icon } from '@lucid/core';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box, SchemaListItem } from '../../components';
import { useSchemas } from '../../hooks';
import { sidebarItemMessages, sidebarMessages } from '../../locale';
import { Schemas, UI, useAppDispatch } from '../../store';
import { SIDEBAR_WIDTH, StyledSideBar, StyledSidebarHeader, StyledSidebarHeaderChild } from './Sidebar.styled';

const sidebarSelector = createSelector(
  ({ schemas }: STATES.App) => schemas,
  ({ ui }: STATES.App) => ui.isSidebarCollapsed,
  (
    { jsonSchema, refsMappingList, uiSchema, selectedKey, resourcesHaveChanges, resourcesHaveErrors },
    isSidebarCollapsed
  ) => ({
    jsonSchema,
    refsMappingList,
    uiSchema,
    selectedKey,
    resourcesHaveChanges,
    resourcesHaveErrors,
    isSidebarCollapsed,
  })
);

const Sidebar = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { onSelectResource } = useSchemas();

  const {
    jsonSchema,
    uiSchema,
    refsMappingList,
    selectedKey,
    resourcesHaveChanges,
    resourcesHaveErrors,
    isSidebarCollapsed,
  } = useSelector(sidebarSelector);

  const closeSidebar = (): void => {
    dispatch(UI.actions.closeSidebar());
    dispatch(
      Schemas.actions.selectSchema({
        resourceKey: '',
      })
    );
  };

  const onSelect = useCallback(
    (resourceKey: string) => {
      dispatch(Schemas.actions.selectSchema({ resourceKey }));
    },
    [dispatch]
  );

  return (
    <StyledSideBar width={SIDEBAR_WIDTH} collapsed={isSidebarCollapsed} collapsedWidth={0}>
      <StyledSidebarHeader display="flex" justifyContent="center" width="100%">
        <FormattedMessage {...sidebarMessages.schemasTitle} />
        <StyledSidebarHeaderChild>
          <Button data-testid="sidebar-close-icon" type="dashed" shape="circle" size="small" onClick={closeSidebar}>
            <Icon type="close" />
          </Button>
        </StyledSidebarHeaderChild>
      </StyledSidebarHeader>
      <SchemaListItem
        hasChanges={resourcesHaveChanges[jsonSchema.resourceKey]}
        hasErrors={resourcesHaveErrors[jsonSchema.resourceKey]}
        selected={selectedKey === jsonSchema.resourceKey}
        jObject={jsonSchema}
        onSelect={onSelect}
        title={intl.formatMessage(sidebarItemMessages.title, { key: jsonSchema.resourceKey })}
        onSelectResource={onSelectResource}
      />
      <SchemaListItem
        hasChanges={resourcesHaveChanges[uiSchema.resourceKey]}
        hasErrors={resourcesHaveErrors[uiSchema.resourceKey]}
        selected={selectedKey === uiSchema.resourceKey}
        title={intl.formatMessage(sidebarItemMessages.title, { key: uiSchema.resourceKey })}
        jObject={uiSchema}
        onSelect={onSelect}
        onSelectResource={onSelectResource}
      />
      <StyledSidebarHeader display="flex" justifyContent="center" width="100%">
        <FormattedMessage {...sidebarMessages.refSchemasTitle} />
      </StyledSidebarHeader>
      <Box flex={1} overflow="auto" width="100%">
        <Box width="100%">
          {Object.entries(refsMappingList).map(([key, value]) => (
            <SchemaListItem
              hasChanges={resourcesHaveChanges[key]}
              hasErrors={resourcesHaveErrors[key]}
              key={key}
              selected={selectedKey === value.resourceKey}
              title={intl.formatMessage(sidebarItemMessages.title, { key })}
              jObject={value}
              onSelect={onSelect}
              onSelectResource={onSelectResource}
            />
          ))}
        </Box>
      </Box>
    </StyledSideBar>
  );
};

export default Sidebar;
