import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledNavLink } from './Menu.styled';

type HeaderMenuItemProps = {
  to: string;
  titleKey: string;
};

const HeaderMenuItem = ({ to, titleKey }: HeaderMenuItemProps): JSX.Element => {
  return (
    <StyledNavLink exact to={to} activeClassName="active">
      <FormattedMessage id={titleKey} />
    </StyledNavLink>
  );
};

export default HeaderMenuItem;
