import moment from 'moment';
import createNewIntl, { getCachedIntl } from './createNewIntl';

export * from './entityUrlParam';
export * from './keycloak';
export { createNewIntl, getCachedIntl };
export { default as messageHolder } from './messageHolder';
export type EnvEntry = Omit<STATES.Env, 'environmentKey' | 'apiResponse'>;

export const formatDateTime = (value: string): string => {
  if (value === '5000-01-01T00:00:00.000000Z') {
    return '';
  }

  const date = moment(value);

  return `${date.format('L')}-${date.format('LT')}`;
};
