import { JsonEditor } from '@coherent/json-editor';
import styled from 'styled-components';
import { Box } from '../Atom';

export const TAB_CONTENT_HEIGHT = 400;

export const LocalTabContainer = styled(Box)`
  background-color: ${({ theme }) => theme.color.gray7};

  p {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
  }
`;

export const RemoteTabContainer = styled(Box)`
  padding: ${({ theme }) => theme.space[3]}px;
`;

export const RawTabContainer = styled(JsonEditor)`
  width: 100%;
  height: ${TAB_CONTENT_HEIGHT}px;

  .jsoneditor,
  .ace_editor {
    border: none !important;
  }
`;

export const EntityStoreTabContainer = styled(Box)`
  padding: ${({ theme }) => theme.space[3]}px;
`;
