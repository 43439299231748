import { PrivacyLevel } from '@coherent/entity-store';
import { EntityAddress, EntityAddressType, EntityData, useEntityStoreContext } from '@coherent/entity-store-ui';
import { useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { Button, Input, Radio } from '@lucid/core';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import debounce from 'lodash/debounce';
import React, { FormEvent, forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messageHolder } from '../../helpers';
import { useModal } from '../../hooks';
import { commonMessages, entityUploadModalMessages } from '../../locale';
import { UI, useAppDispatch } from '../../store';
import BaseModal from './BaseModal';
import { StyledAutocomplete } from './EntityUploadModal.styled';

export type EntityUploadModalProps = {
  onUploaded: (lastEntityData: EntityData, savedAddress: EntityAddress) => void;
  getCurrentData: () => Record<string, unknown>;
  entityAddress: Nullable<EntityAddress>;
  disabled?: boolean;
  children?: (props: { onOpen: () => void; disabled?: boolean }) => JSX.Element;
  isVisible?: boolean;
  setVisible?: (visible: boolean) => void;
};

export type EntityUploadModel = {
  entityId: string;
  entityPath: string;
  entityType: string;
  dataType: EntityAddressType;
  privacyLevel: PrivacyLevel;
};

type EntityUploadFormContentProps = FormComponentProps<EntityUploadModel> & {
  initData?: EntityUploadModel;
  hideEntityId?: boolean;
};

const EntityUploadFormContent = forwardRef<FormComponentProps<EntityUploadModel>, EntityUploadFormContentProps>(
  (props, ref) => {
    const {
      form,
      hideEntityId = false,
      initData = {
        entityId: '',
        dataType: EntityAddressType.Blob,
        entityPath: '',
        entityType: '',
        privacyLevel: PrivacyLevel.Global,
      },
    } = props;
    const [entityTypeSuggestions, setEntityTypeSuggestions] = useState<string[]>([]);
    const { searchEntityTypes } = useEntityStoreJsonEditorContext();

    useImperativeHandle(ref, () => ({
      form,
    }));

    const onSearchEntityType = useMemo(() => {
      return debounce((keyword: string) => {
        if (!keyword) {
          setEntityTypeSuggestions([]);

          return;
        }

        searchEntityTypes(keyword)
          .then((results) => {
            setEntityTypeSuggestions(results);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      }, 500);
    }, [searchEntityTypes]);

    const handleSubmit = (e: FormEvent): void => {
      e.preventDefault();
    };

    return (
      <Form onSubmit={handleSubmit} layout="vertical" hideRequiredMark>
        {!hideEntityId && (
          <Form.Item label={<FormattedMessage {...entityUploadModalMessages.entityIdLabel} />}>
            {form.getFieldDecorator('entityId', {
              initialValue: initData.entityId,
              rules: [],
            })(<Input />)}
          </Form.Item>
        )}
        <Form.Item label={<FormattedMessage {...entityUploadModalMessages.entityPathLabel} />}>
          {form.getFieldDecorator('entityPath', {
            initialValue: initData.entityPath,
            rules: [
              {
                required: true,
                message: <FormattedMessage {...entityUploadModalMessages.entityPathErorrRequired} />,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage {...entityUploadModalMessages.entityTypeLabel} />}>
          {form.getFieldDecorator('entityType', {
            initialValue: initData.entityType,
          })(<StyledAutocomplete onSearch={onSearchEntityType} dataSource={entityTypeSuggestions} showSearch />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage {...entityUploadModalMessages.dataTypeLabel} />}>
          {form.getFieldDecorator('dataType', {
            initialValue: initData.dataType,
          })(
            <Radio.Group buttonStyle="solid">
              {[EntityAddressType.Blob, EntityAddressType.JsonData].map(
                (value): JSX.Element => (
                  <Radio.Button key={value} value={value}>
                    <FormattedMessage {...commonMessages.entityDataType} values={{ dataType: value }} />
                  </Radio.Button>
                )
              )}
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label={<FormattedMessage {...entityUploadModalMessages.privacyLevelLabel} />}>
          {form.getFieldDecorator('privacyLevel', {
            initialValue: initData.privacyLevel,
          })(
            <Radio.Group buttonStyle="solid">
              {[PrivacyLevel.Global, PrivacyLevel.Private, PrivacyLevel.Tenant].map(
                (value): JSX.Element => (
                  <Radio.Button key={value} value={value}>
                    <FormattedMessage {...commonMessages.entityPrivacyLevel} values={{ privacyLevel: value }} />
                  </Radio.Button>
                )
              )}
            </Radio.Group>
          )}
        </Form.Item>
      </Form>
    );
  }
);

export const EntityUploadForm = Form.create<EntityUploadFormContentProps>({ name: 'entity_upload' })(
  EntityUploadFormContent
);

const EntityUploadModal = ({
  getCurrentData,
  entityAddress,
  disabled,
  onUploaded,
  children,
  setVisible,
  isVisible,
}: EntityUploadModalProps): JSX.Element => {
  const { uploadEntity, downloadEntity } = useEntityStoreContext();
  const formRef = useRef<{ form: WrappedFormUtils<EntityUploadModel> } | null>(null);
  const { isOpen, onCancel, onOpen } = useModal({ isVisible, setVisible });
  const dispatch = useAppDispatch();

  const initData = useMemo<EntityUploadModel>(() => {
    const {
      path = '',
      dataType = EntityAddressType.Blob,
      entityType = '',
      privacyLevel = PrivacyLevel.Global,
      documentId = '',
    } = entityAddress ?? {};

    return {
      privacyLevel,
      dataType,
      entityPath: path,
      entityId: documentId,
      entityType,
    };
  }, [entityAddress]);

  const onSubmit = (): void => {
    if (!formRef.current) {
      return;
    }

    formRef.current.form.validateFields((err, formData) => {
      if (err) {
        return;
      }

      const { dataType, privacyLevel, entityId } = formData;

      const entityPath = formData.entityPath.trim();
      const entityType = formData.entityType.trim();
      const data = getCurrentData();

      void (async (): Promise<void> => {
        dispatch(UI.actions.setGlobalLoading(true));

        try {
          const address = {
            path: entityPath,
            dataType,
            entityType,
            privacyLevel,
            documentId: entityId,
          };

          await uploadEntity(address, data);
          const lastVersion = await downloadEntity(address);
          if (!lastVersion) {
            throw new Error("Can't find the last version of just updated entity");
          }

          onUploaded(lastVersion, address);

          onCancel();
        } catch (error) {
          messageHolder.error(error);
        } finally {
          dispatch(UI.actions.setGlobalLoading(false));
        }
      })();
    });
  };

  const onDownload = (): void => {
    saveAs(
      new Blob([JSON.stringify(getCurrentData(), undefined, 2)], {
        type: 'application/json',
      }),
      'data.json'
    );
  };

  return (
    <>
      {children && children({ onOpen, disabled })}
      <BaseModal
        title={<FormattedMessage {...entityUploadModalMessages.title} />}
        visible={isOpen}
        onCancel={onCancel}
        onOk={onSubmit}
        width={700}
        footerExtraComponent={
          <Button shape="round" onClick={onDownload}>
            <FormattedMessage {...entityUploadModalMessages.download} />
          </Button>
        }
      >
        <EntityUploadForm initData={initData} wrappedComponentRef={formRef} />
      </BaseModal>
    </>
  );
};

export default memo(EntityUploadModal);
