import styled from 'styled-components';

export { default as LayoutBody } from './LayoutBody';
export { default as LayoutHeader } from './LayoutHeader';

export const EditorHeaderControlsContainer = styled.div`
  .ant-btn.ant-btn-dashed {
    background-color: ${({ theme }) => theme.color.primary};
    padding: 0;
    padding-top: 1px;
  }

  .ant-switch {
    &.ant-switch-checked {
      background-color: ${({ theme }) => theme.color.secondary};
    }
  }
`;
