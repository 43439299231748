import { EntityAddress, EntityAddressType } from '@coherent/entity-store-ui';

export const guidRegex = /({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}/;
export const getEntityAddressFromPath = (path: string): Nullable<EntityAddress> => {
  // /entity/(jsonData|blob):(path|entity id)(:version id)
  const matches = /\/entity\/(blob|jsonData):([a-zA-Z0-9/_\-\\.+]*)(:([a-zA-Z0-9-]*))?/g.exec(path);

  if (!matches) {
    return null;
  }

  const [, entityAddressType, entityPathOrId, , entityVersionId] = matches;
  const isGuid = guidRegex.test(entityPathOrId);

  return {
    path: !isGuid ? entityPathOrId : undefined,
    dataType: entityAddressType as EntityAddressType,
    versionId: entityVersionId,
    documentId: isGuid ? entityPathOrId : undefined,
  };
};

export const buildEntityAddressPathParam = (entityAddress: EntityAddress): string => {
  const { path, dataType = EntityAddressType.Blob, documentId, versionId } = entityAddress;

  const versionIdPart = versionId ? `:${versionId}` : '';
  const entityPathOrId = String(path || documentId);

  return `${dataType}:${entityPathOrId}${versionIdPart}`;
};
