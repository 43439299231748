import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.primary};
  padding: 0 ${({ theme }) => theme.space[3]}px;
  font-weight: bold;

  :hover {
    color: ${({ theme }) => theme.color.gray4};
  }

  &.active {
    color: ${({ theme }) => theme.color.white};

    :hover {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
