import { Icon } from '@lucid/core';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SchemaItemStatusDot, TextEllipsis } from '../../components';
import { headerMessages } from '../../locale';
import { UI, useAppDispatch } from '../../store';
import { OpenSidebarIcon } from './Home.styled';

const dataHeaderTitleSelector = createSelector(
  ({ entity }: STATES.App) => entity.errors,
  ({ ui }: STATES.App) => ui.isSidebarCollapsed,
  (errors, isSidebarCollapsed) => {
    return {
      errors,
      isSidebarCollapsed,
    };
  }
);

type DataHeaderTitleProp = {
  hideSidebarToggle?: boolean;
};

const DataHeaderTitle = ({ hideSidebarToggle = false }: DataHeaderTitleProp): JSX.Element | null => {
  const { errors, isSidebarCollapsed } = useSelector(dataHeaderTitleSelector);
  const intl = useIntl();

  const dispatch = useAppDispatch();

  const openSidebar = (): void => {
    dispatch(UI.actions.openSidebar());
  };

  return (
    <>
      <SchemaItemStatusDot hasChanges={false} hasErrors={Boolean(errors && errors.length)} />
      <TextEllipsis>
        {!hideSidebarToggle && (
          <OpenSidebarIcon
            data-testid="data-edit-column-open-icon"
            hidden={!isSidebarCollapsed}
            type="dashed"
            shape="circle"
            size="small"
            onClick={openSidebar}
          >
            <Icon type="menuUnfold" />
          </OpenSidebarIcon>
        )}
        {intl.formatMessage(headerMessages.data)}
      </TextEllipsis>
    </>
  );
};

export default memo(DataHeaderTitle);
