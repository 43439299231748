import { Input } from '@lucid/core';
import React from 'react';
import { useIntl } from 'react-intl';
import { messageHolder } from '../../helpers';
import { jsonModalMessages } from '../../locale';
import { Box } from '../Atom';
import { RemoteTabContainer, TAB_CONTENT_HEIGHT } from './common.styled';
import { TabProps } from './LocalTab';

const RemoteTab = ({ setData }: TabProps): JSX.Element => {
  const intl = useIntl();

  const onBlurHandler = async (event: React.FocusEvent<HTMLInputElement>): Promise<void> => {
    const url = event.target.value;
    if (!url) {
      return;
    }

    try {
      const response = await fetch(url);
      if ([200, 201].includes(response.status)) {
        const data = (await response.json()) as Record<string, unknown>;
        setData(data);
      } else {
        throw Error('Unable to Fetch Data');
      }
    } catch (error) {
      messageHolder.error(error);
    }
  };
  return (
    <RemoteTabContainer width="100%" minHeight={TAB_CONTENT_HEIGHT}>
      <Box>
        <p>{intl.formatMessage(jsonModalMessages.placeholder)}</p>
        <Input data-testid="json-modal-input-remote" onBlur={onBlurHandler} type="url" />
      </Box>
    </RemoteTabContainer>
  );
};

export default RemoteTab;
