import { EditorType, JsonError, JsonSchemaDraft07 } from '@coherent/json-editor';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box } from '../../components';
import { Generation, useAppDispatch } from '../../store';
import Theme from '../../styles/theme';
import { StyledJsonEditor } from '../Home/Home.styled';
import SampleDataHeaderControl from './SampleDataHeaderControl';
import SampleDataHeaderTitle from './SampleDataHeaderTitle';

export type SampleDataEditColumnProps = {
  resourceKey: string;
};
const dataSamplesSelector = createSelector(
  ({ generation }: STATES.App) => generation.data,
  ({ generation }: STATES.App) => generation.selectedKey,

  (data, selectedKey) => {
    return {
      data,
      selectedKey,
    };
  }
);

const SampleDataEditColumn = ({ resourceKey }: SampleDataEditColumnProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { data, selectedKey } = useSelector(dataSamplesSelector);
  const textualContentsCached = useRef<Record<string, string | undefined>>({});
  const resourceKeyRef = useRef<string>(selectedKey);
  const [jsonEditorKey, setJsonEditorKey] = useState(0);

  const schemaObjectResource = useMemo((): Nullable<ENTITIES.JsonResourceObject> => {
    const schemaResource = data.find((schema) => schema.resourceKey === resourceKey);
    return schemaResource || null;
  }, [data, resourceKey]);

  useEffect((): void => {
    textualContentsCached.current[resourceKey] = undefined;
    setJsonEditorKey((current) => current + 1);
  }, [data, resourceKey]);

  useEffect(() => {
    setJsonEditorKey((current) => current + 1);
    resourceKeyRef.current = resourceKey;
  }, [resourceKey]);

  const textualOnTextChange = useCallback(
    (content: string) => {
      textualContentsCached.current[resourceKey] = content;
      dispatch(
        Generation.actions.hasChanges({
          resourceKey,
        })
      );
    },
    [resourceKey, dispatch]
  );

  const onErrors = useCallback(
    (errors: ReadonlyArray<JsonError>) => {
      dispatch(
        Generation.actions.hasErrors({
          resourceKey,
          value: errors.length !== 0,
        })
      );
    },
    [resourceKey, dispatch]
  );

  const applyChanges = (): void => {
    void dispatch(
      Generation.actions.applyChanges({
        resourceKey,
        textContent: textualContentsCached.current[resourceKey] ?? '',
      })
    );
  };

  if (!schemaObjectResource) {
    return null;
  }

  return (
    <Box
      height="100%"
      width="100%"
      isFlex
      borderLeft="1px solid"
      borderColor={Theme.color.secondary}
      data-testid="inputs-json-editor"
      overflow="hidden"
    >
      <StyledJsonEditor
        key={`${jsonEditorKey}`}
        initData={schemaObjectResource.obj}
        displayType={EditorType.Textual}
        headerTitle={<SampleDataHeaderTitle />}
        schema={JsonSchemaDraft07}
        textualOnTextChange={textualOnTextChange}
        textualInitTextData={textualContentsCached.current[resourceKey]}
        onErrors={onErrors}
        headerControls={<SampleDataHeaderControl applyChanges={applyChanges} />}
      />
    </Box>
  );
};

export default memo(SampleDataEditColumn);
