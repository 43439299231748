import { EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { Button, Icon, Modal } from '@lucid/core';
import React, { memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { EditorHeaderControlsContainer, EntityUploadModal } from '../../components';
import useEntityUploadModal from '../../hooks/useEntityUploadModal';
import { commonMessages, jsonEditorMessages, jsonModalMessages } from '../../locale';
import { Generation, useAppDispatch } from '../../store';

const GeneratedSchemaHeaderControlSelector = createSelector(
  ({ generation }: STATES.App) => generation.resourcesHaveErrors,
  ({ generation }: STATES.App) => generation.resourcesHaveChanges,
  ({ generation }: STATES.App) => generation.generatedSchemaHasChanges,

  (resourcesHaveErrors, resourcesHaveChanges, generatedSchemaHasChanges) => {
    return {
      resourcesHaveErrors,
      resourcesHaveChanges,
      generatedSchemaHasChanges,
    };
  }
);
export type GeneratedSchemaHeaderControlsProps = {
  getCurrentData: () => Record<string, unknown>;
  onUploaded: (lastEntityData: EntityData, savedAddress: EntityAddress) => void;
  entityAddress: Nullable<EntityAddress>;
};

const GeneratedSchemaHeaderControls = ({
  getCurrentData,
  onUploaded,
  entityAddress,
}: GeneratedSchemaHeaderControlsProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { entityUploadModal, onSave } = useEntityUploadModal({
    entityAddress,
    getCurrentData,
    onUploaded,
    resourceKey: '',
  });
  const { resourcesHaveErrors, resourcesHaveChanges, generatedSchemaHasChanges } = useSelector(
    GeneratedSchemaHeaderControlSelector
  );

  const isDisabled = useMemo((): boolean => {
    return Object.values(resourcesHaveErrors).includes(true);
  }, [resourcesHaveErrors]);

  const generateSchema = (): void => {
    void dispatch(Generation.actions.generateSchema());
  };

  const handleConfirm = (): void => {
    Modal.confirm({
      title: intl.formatMessage(jsonModalMessages.unsavedChangesConfirm),
      okText: intl.formatMessage(commonMessages.continue),
      onOk() {
        generateSchema();
        dispatch(Generation.actions.schemaHasChanges({ value: false }));
      },
    });
  };

  const handleGenerate = (): void => {
    if (!resourcesHaveChanges || !generatedSchemaHasChanges) {
      generateSchema();
    } else {
      handleConfirm();
    }
  };

  return (
    <EditorHeaderControlsContainer>
      <Button
        data-testid="schema-generate-button"
        disabled={isDisabled}
        type="link"
        size="small"
        onClick={handleGenerate}
      >
        <Icon type="sync" />
        <span>
          <FormattedMessage {...jsonEditorMessages.menuGenerate} />
        </span>
      </Button>
      <Button type="link" size="small" onClick={onSave} data-testid="entity-upload-trigger">
        <Icon type="save" />
        <span>
          <FormattedMessage {...jsonEditorMessages.menuSave} />
        </span>
      </Button>

      {entityUploadModal}

      <EntityUploadModal entityAddress={entityAddress} getCurrentData={getCurrentData} onUploaded={onUploaded} />
    </EditorHeaderControlsContainer>
  );
};

export default memo(GeneratedSchemaHeaderControls);
