import { EntityAddress, useEntityStoreContext } from '@coherent/entity-store-ui';
import { EntityStoreJsonEditor, JsonTextualEditor, useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { lucidCells, lucidRenderers } from '@json-editor/lucid-renderers';
import { createAjv, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { Button } from '@lucid/core';
import { saveAs } from 'file-saver';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import React, { MouseEventHandler, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import ReactSimpleCodeEditor from 'react-simple-code-editor';
import { ErrorFallback } from '../../components';
import { UserAction, UserControl } from '../User';
import EntityAddressInput from './EntityAddress';
import { Field, JSONContainer, Playground, RefItem } from './Temp.styled';

const TimeFormatRegex = /^(?:[0-2]\d:[0-5]\d:[0-5]\d|23:59:60)(?:\.\d+)?(?:z|[+-]\d\d(?::?\d\d)?)?$/i;

enum ErrorSources {
  Schema = 'Schema',
  UISchema = 'UISchema',
  Form = 'Form',
}

enum EditorMode {
  Form = 'Form',
  Text = 'Text',
}

const FieldRow = (props: PropsWithChildren<{ label: string; contentClassName?: string }>): JSX.Element => {
  const { label, children, contentClassName } = props;

  return (
    <Field>
      <div>{label}</div>
      <div className={contentClassName}>{children}</div>
    </Field>
  );
};

const readJsonFile = <T extends unknown>(file: File): Promise<T> =>
  new Promise<T>((resolve, reject) => {
    const onReaderLoad = (readEvent: ProgressEvent<FileReader>): void => {
      const result = readEvent.target?.result ?? '{}';

      try {
        resolve(JSON.parse(result.toString()) as T);
      } catch (error) {
        reject(error);
      }
    };

    const jsonReader = new FileReader();

    jsonReader.onload = onReaderLoad;
    jsonReader.readAsText(file);
  });

const Temp = (): JSX.Element => {
  const { uploadEntity, docStore } = useEntityStoreContext();
  const { getJSONSetups, getEntityRefsFromSchema, detectRefURIFromJsonSchema } = useEntityStoreJsonEditorContext();
  const currentDataRef = useRef<Record<string, unknown>>({});
  const [initDataJSON, setInitDataJSON] = useState<string>('{}');
  const [initData, setInitData] = useState<Record<string, unknown>>({});
  const [reloadSignal, setReloadSignal] = useState<number>(0);
  const [editorMode, setEditorMode] = useState<EditorMode>(EditorMode.Form);
  const [detectedRefs, setDetectedRefs] = useState<{ [uri: string]: ENTITIES.RefMap }>({});
  const [errors, setErrors] = useState<{ [type in ErrorSources]?: Error }>({});
  const [selectedSchemaObject, setSelectedSchemaObject] = useState<JsonSchema | undefined>(undefined);
  const [selectedUISchemaObject, setSelectedUISchemaObject] = useState<UISchemaElement | undefined>(undefined);
  const [schemaObject, setSchemaObject] = useState<JsonSchema | undefined>(undefined);
  const [uiSchemaObject, setUISchemaObject] = useState<UISchemaElement | undefined>(undefined);
  const [mappedRef, setMappedRef] = useState<Record<string, unknown>>({});

  const [dataEntityAddress, setDataEntityAddress] = useState<Nullable<EntityAddress>>(null);
  const [initDataEntityAddress, setInitDataEntityAddress] = useState<Nullable<EntityAddress>>(null);

  const schemaFileInputRef = useRef<HTMLInputElement | null>(null);
  const uiShemaFileInputRef = useRef<HTMLInputElement | null>(null);
  const ajv = useRef(
    createAjv({ missingRefs: 'ignore' }).addFormat(
      'time',
      // We need this config for draft-7 time format
      TimeFormatRegex
    )
  );

  const clearError = (source: ErrorSources): void => {
    setErrors((current) => ({
      ...current,
      [source]: undefined,
    }));
  };

  useEffect((): void => {
    if (initDataJSON) {
      try {
        const parsedData = JSON.parse(initDataJSON) as Record<string, unknown>;

        setInitData(parsedData);
        currentDataRef.current = parsedData;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  }, [initDataJSON]);

  const onGenerateUI = (nextEditorMode: EditorMode): void => {
    if (!selectedSchemaObject) {
      return;
    }

    ajv.current.removeSchema('jsonEditor');
    ajv.current.addSchema(selectedSchemaObject, 'jsonEditor');

    switch (nextEditorMode) {
      case EditorMode.Form: {
        if (!selectedUISchemaObject) {
          return;
        }

        setUISchemaObject(selectedUISchemaObject);
        setEditorMode(EditorMode.Form);
        break;
      }

      case EditorMode.Text: {
        setInitData(currentDataRef.current);

        const nextResolvedRefs = Object.entries(detectedRefs).reduce((prev, [key, value]) => {
          return {
            ...prev,
            [key]: value.resolveType === 'local' ? value.resolvedObject : value.resolveWebUrl,
          };
        }, {});

        setMappedRef(nextResolvedRefs);
        setEditorMode(EditorMode.Text);
        break;
      }

      default:
        break;
    }

    setSchemaObject(selectedSchemaObject);
    setReloadSignal((current) => current + 1);
  };

  const onSave: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();

    saveAs(
      new Blob([JSON.stringify(currentDataRef.current, undefined, 4)], {
        type: 'application/json',
      }),
      'data.json'
    );
  };

  const uiSchemaOnChange = async (): Promise<void> => {
    clearError(ErrorSources.UISchema);

    if (uiShemaFileInputRef.current && uiShemaFileInputRef.current.files) {
      try {
        const readJson: UISchemaElement = await readJsonFile(uiShemaFileInputRef.current.files[0]);

        setSelectedUISchemaObject(readJson);
      } catch (error) {
        setErrors((current) => ({
          ...current,
          [ErrorSources.UISchema]: error as Error,
        }));
      }
    }
  };

  const setSchemaAndDetectRef = async (schema: Record<string, unknown>): Promise<void> => {
    const refEntities = await getEntityRefsFromSchema(schema, Object.keys(detectedRefs));

    const next = refEntities.reduce<{ [uri: string]: ENTITIES.RefMap }>((prev, { refUri, refEntity }) => {
      return {
        ...prev,
        [refUri]: {
          ref: refUri,
          resolveType: refEntity ? 'es' : 'web',
          resolveWebUrl: refEntity ? '' : refUri,
          resolveEntity: refEntity ? `${refEntity.entityAddress.path ?? ''}` : undefined,
          resolveFile: null,
          resolvedObject: refEntity?.entityData?.data ? refEntity.entityData.data : null,
        },
      };
    }, detectedRefs);

    setDetectedRefs(next);
    setSelectedSchemaObject(schema);
  };

  const schemaOnChange = async (): Promise<void> => {
    clearError(ErrorSources.Schema);

    if (schemaFileInputRef.current && schemaFileInputRef.current.files) {
      try {
        const readJson: Record<string, unknown> = await readJsonFile(schemaFileInputRef.current.files[0]);

        await setSchemaAndDetectRef(readJson);
      } catch (error) {
        setErrors((current) => ({
          ...current,
          [ErrorSources.Schema]: error as Error,
        }));
      }
    }
  };

  const changeRefType = (ref: string, type: 'local' | 'web' | 'es'): void => {
    setDetectedRefs((current) => ({
      ...current,
      [ref]: {
        ...current[ref],
        resolveType: type,
      },
    }));
  };

  const changeRefWebUrl = (ref: string, value: string): void => {
    setDetectedRefs((current) => ({
      ...current,
      [ref]: {
        ...current[ref],
        resolveWebUrl: value,
        resolveFile: null,
        resolvedObject: null,
      },
    }));

    ajv.current.removeSchema(ref);
  };

  const changeRefLocalFile = async (ref: string, file: File): Promise<void> => {
    const jObject: Record<string, unknown> = await readJsonFile(file);
    const triedToLoadUris = (await detectRefURIFromJsonSchema(jObject)).filter((uri) => !detectedRefs[uri]);
    const newOnes = triedToLoadUris.reduce<{ [uri: string]: ENTITIES.RefMap }>((prev, current) => {
      return {
        ...prev,
        [current]: {
          ref: current,
          resolveType: 'web',
          resolveWebUrl: current,
          resolveFile: null,
          resolvedObject: null,
          resolveEntity: undefined,
        },
      };
    }, {});

    setDetectedRefs((current) => ({
      ...current,
      [ref]: {
        ...current[ref],
        resolveFile: file,
        resolvedObject: jObject,
        resolveEntity: undefined,
      },
      ...newOnes,
    }));
  };

  const onChange = useCallback(({ data: nextData }: { data: Record<string, unknown> }) => {
    currentDataRef.current = nextData;
  }, []);

  const refParserOptions = useMemo(() => {
    return {
      resolve: {
        external: true,
        http: {
          order: 1,
          canRead: true,
          read: async (file: { url: string }) => {
            const { resolveWebUrl, resolvedObject } = detectedRefs[file.url];

            if (resolvedObject) {
              return resolvedObject;
            }

            const response = await fetch(resolveWebUrl, {
              method: 'GET',
              headers: new Headers({
                'content-type': 'application/json',
              }),
            });

            return response.json();
          },
        },
      },
    };
  }, [detectedRefs]);

  const textualEditorOnChange = useCallback((nextData: Record<string, unknown>) => {
    currentDataRef.current = nextData;
  }, []);

  return (
    <Playground style={{ padding: 20 }}>
      <div>
        <h1>
          <FormattedMessage id="app.heading" />
        </h1>
      </div>
      <div>
        <UserControl />
      </div>
      <div>
        <UserAction />
      </div>
      <FieldRow label="1. Select your main JSON schema">
        <input type="file" accept="*.json" ref={schemaFileInputRef} onChange={schemaOnChange} readOnly />
        <ErrorFallback error={errors[ErrorSources.Schema]} resetErrorBoundary={() => clearError(ErrorSources.Schema)} />
      </FieldRow>
      <FieldRow label="2. Select your UI schema">
        <input type="file" accept="*.json" ref={uiShemaFileInputRef} onChange={uiSchemaOnChange} />
        <ErrorFallback
          error={errors[ErrorSources.UISchema]}
          resetErrorBoundary={() => clearError(ErrorSources.UISchema)}
        />
      </FieldRow>
      {docStore && (
        <>
          <FieldRow label="3.0. Init json data from an Entity">
            <div className="small">
              <EntityAddressInput label="Entity address" onChange={setInitDataEntityAddress} />
              <Button
                type="primary"
                onClick={async () => {
                  if (!initDataEntityAddress) {
                    return;
                  }

                  const { data: initDataSetups, schema, uischema } = await getJSONSetups(initDataEntityAddress);

                  setInitDataJSON(JSON.stringify(initDataSetups.entityData?.data ?? {}, undefined, 2));

                  const uiSchemaFromEntity = uischema?.entityData?.data;

                  if (uiSchemaFromEntity) {
                    setSelectedUISchemaObject((uiSchemaFromEntity as unknown) as UISchemaElement);
                  }

                  const schemaFromEntity = schema?.entityData?.data;

                  if (schemaFromEntity) {
                    await setSchemaAndDetectRef(schemaFromEntity);
                  }
                }}
              >
                Load
              </Button>
            </div>
          </FieldRow>
          {initDataEntityAddress && (
            <FieldRow label="3.0. Shared component">
              <EntityStoreJsonEditor title="Presentation" dataEntity={initDataEntityAddress} />
            </FieldRow>
          )}
        </>
      )}
      <FieldRow label="3.1 Add init JSON data (optional)" contentClassName="editor-container">
        <ReactSimpleCodeEditor
          value={initDataJSON}
          onValueChange={setInitDataJSON}
          highlight={(code) => highlight(code, languages.json)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
      </FieldRow>

      <FieldRow label="4. Specify $ref mappings">
        {Object.values(detectedRefs).map(
          ({ ref, resolveType, resolveWebUrl, resolveEntity }, index): JSX.Element => (
            <RefItem key={ref}>
              <div>
                <input type="text" value={ref} />
              </div>
              <div className="type-radio-group">
                <input
                  type="radio"
                  value="local"
                  name={`resolveType-${index}`}
                  checked={resolveType === 'local'}
                  onClick={() => changeRefType(ref, 'local')}
                />
                <span>Local</span>
                <input
                  type="radio"
                  value="web"
                  name={`resolveType-${index}`}
                  checked={resolveType === 'web'}
                  onClick={() => changeRefType(ref, 'web')}
                />
                <span>Web</span>
                <input
                  type="radio"
                  value="es"
                  name={`resolveType-${index}`}
                  checked={resolveType === 'es'}
                  onClick={() => changeRefType(ref, 'es')}
                />
                <span>ES</span>
              </div>
              {resolveType === 'local' && (
                <div>
                  <input
                    type="file"
                    accept="application/json"
                    onChange={(e) => {
                      if (e.target.files) {
                        void changeRefLocalFile(ref, e.target.files[0]);
                      }
                    }}
                  />
                </div>
              )}
              {resolveType === 'web' && (
                <div>
                  <input type="text" value={resolveWebUrl} onChange={(e) => changeRefWebUrl(ref, e.target.value)} />
                </div>
              )}
              {resolveType === 'es' && (
                <div>
                  <input type="text" readOnly value={resolveEntity ?? ''} />
                </div>
              )}
            </RefItem>
          )
        )}
      </FieldRow>
      <FieldRow label="5.">
        <Button type="primary" onClick={() => onGenerateUI(EditorMode.Form)}>
          Generate Form Editor
        </Button>

        <Button type="danger" onClick={() => onGenerateUI(EditorMode.Text)}>
          Generate Textual Editor
        </Button>
      </FieldRow>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={() => {
          setSchemaObject(undefined);
          setUISchemaObject(undefined);
          setReloadSignal((current) => current + 1);
        }}
      >
        <JSONContainer key={reloadSignal}>
          {editorMode === EditorMode.Form && schemaObject && uiSchemaObject && (
            <JsonForms
              ajv={ajv.current}
              schema={schemaObject}
              uischema={uiSchemaObject}
              data={initData}
              renderers={lucidRenderers}
              cells={lucidCells}
              onChange={onChange}
              refParserOptions={refParserOptions}
            />
          )}

          {editorMode === EditorMode.Text && schemaObject && (
            <JsonTextualEditor
              data={initData}
              schema={schemaObject}
              schemaRefs={mappedRef}
              onChange={textualEditorOnChange}
            />
          )}
        </JSONContainer>
      </ErrorBoundary>

      <FieldRow label="6.">
        <Button type="primary" onClick={onSave}>
          Download
        </Button>
      </FieldRow>

      {docStore && (
        <FieldRow label="7.">
          <div className="small">
            <EntityAddressInput label="Update Entity" onChange={setDataEntityAddress} upload />
            <Button
              type="primary"
              onClick={async () => {
                if (!dataEntityAddress) {
                  return;
                }

                await uploadEntity(dataEntityAddress, currentDataRef.current);
              }}
            >
              Upload
            </Button>
          </div>
        </FieldRow>
      )}
    </Playground>
  );
};

export default Temp;
