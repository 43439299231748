import { EntityStoreError } from '@coherent/entity-store';
import { Message } from '@lucid/core';
import { getCachedIntl } from './createNewIntl';

const PrefixedMessageCodes = ['NULL_OR_EMPTY_FIELD', 'INVALID_VALUE_FOR_FIELD', 'MISSING_FIELD'];
const InfixedMessageCodes = [
  'FIELD_MUST_BE_ATLEAST_SIZE',
  'FIELD_MUST_BE_LESS_THAN_SIZE',
  'FIELD_EXCEEDED_MAX_LENGTH_OF_SIZE',
];
const PrefixedRegex = (errorCode: string): RegExp => {
  const pattern = errorCode.replace(/_FIELD$/g, '_(\\w+)');

  return new RegExp(`^${pattern}$`, 'g');
};
const InfixedRegex = (errorCode: string): RegExp => {
  const pattern = errorCode.replace(/^FIELD_/g, '(\\w+)_').replace(/_SIZE$/g, '_(\\w+)');

  return new RegExp(`^${pattern}$`, 'g');
};

type ParsedDynamicError = {
  message: string;
  data: { [key: string]: string };
};

export const parseDynamicErrorCode = (errorCode: string): ParsedDynamicError | undefined => {
  let result: ParsedDynamicError | undefined;
  let foundErrorCode = PrefixedMessageCodes.find((dynamicError) => PrefixedRegex(dynamicError).test(errorCode));

  if (foundErrorCode) {
    const regex = PrefixedRegex(foundErrorCode);
    const matches = regex.exec(errorCode);

    if (matches) {
      const [, fieldname] = matches;

      result = {
        message: foundErrorCode,
        data: { fieldname },
      };

      return result;
    }
  }

  foundErrorCode = InfixedMessageCodes.find((dynamicError) => InfixedRegex(dynamicError).exec(errorCode));

  if (foundErrorCode) {
    const regex = InfixedRegex(foundErrorCode);
    const matches = regex.exec(errorCode);
    if (matches) {
      const [, prefix, suffix] = matches;

      result = {
        message: foundErrorCode,
        data: { fieldname: prefix, size: suffix },
      };
    }
  }

  return result;
};

const error = (value: Error): void => {
  const intl = getCachedIntl();

  if (!intl) {
    return;
  }

  if (value instanceof EntityStoreError) {
    const {
      errorResponse: { errors },
    } = value;

    if (errors && errors.length > 0) {
      errors.forEach((errorCode) => {
        const parsedError = parseDynamicErrorCode(errorCode);

        if (parsedError) {
          const { message: id, data } = parsedError;

          void Message.error(intl.formatMessage({ id }, data));

          return;
        }

        void Message.error(intl.formatMessage({ id: errorCode }));
      });
    }

    return;
  }

  void Message.error(intl.formatMessage({ id: value.message }));
};

export default { error };
