import React, { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { persistor, store } from '../store';
import { AppGlobalStyled } from '../styles/global.styled';
import Theme from '../styles/theme';
import AuthProvider from './AuthProvider';
import EntityStoreWrapperProvider from './EntityStoreWrapperProvider';
import LanguageProvider from './LanguageProvider';

interface AppProviderProps {
  onBeforeLift?(): void | Promise<void>;
  loadingUI?: ReactNode;
}

const AppProvider: FC<AppProviderProps> = ({ children, onBeforeLift, loadingUI }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={loadingUI ?? <div>&nbsp;</div>} onBeforeLift={onBeforeLift} persistor={persistor}>
        <BrowserRouter>
          <LanguageProvider>
            <AuthProvider>
              <EntityStoreWrapperProvider>
                <ThemeProvider theme={Theme}>
                  {children}
                  <AppGlobalStyled />
                </ThemeProvider>
              </EntityStoreWrapperProvider>
            </AuthProvider>
          </LanguageProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default AppProvider;
