import { Layout } from '@lucid/core';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box, HeaderDropdownMenu, HeaderMenuItem, LayoutBody, LayoutHeader, LoadingOverlay } from '../../components';
import { headerMessages } from '../../locale';
import { Environment } from '../Environment';
import { UserAction, UserControl } from '../User';

const appLayoutSelector = createSelector(
  ({ ui }: STATES.App) => ui.isGlobalLoading,
  (isGlobalLoading) => isGlobalLoading
);
const authSelector = createSelector(
  ({ auth }: STATES.App) => auth.isAuthenticated,
  (isAuthenticated) => isAuthenticated
);
type AppLayoutProps = {
  sidebar?: ReactNode;
};
const AppLayout = ({ children, sidebar }: PropsWithChildren<AppLayoutProps>): JSX.Element => {
  const intl = useIntl();
  const isGlobalLoading = useSelector(appLayoutSelector);
  const isAuthenticated = useSelector(authSelector);

  return (
    <Layout>
      <LayoutHeader>
        <Box isFlex alignItems="center" alignContent="center">
          <HeaderMenuItem to="/" titleKey={intl.formatMessage(headerMessages.editor)} />
          <HeaderMenuItem to="/diffs" titleKey={intl.formatMessage(headerMessages.compare)} />
          <HeaderMenuItem to="/mass/validation" titleKey={intl.formatMessage(headerMessages.massValidation)} />
          <HeaderMenuItem to="/generation" titleKey={intl.formatMessage(headerMessages.generation)} />
          <HeaderDropdownMenu
            menu={
              isAuthenticated
                ? [<Environment />, '----', <UserControl />, <UserAction />]
                : [<Environment />, '----', <UserAction />]
            }
          />
        </Box>
      </LayoutHeader>
      <LayoutBody>
        {sidebar}
        {children}
        <LoadingOverlay isLoading={isGlobalLoading} />
      </LayoutBody>
    </Layout>
  );
};

export default AppLayout;
