import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledLayoutHeaderBox = styled(Layout.Header)`
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
`;
