import jwt from 'jsonwebtoken';
import { combineReducers } from 'redux';
import { createTransform, PersistConfig, Transform } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import { createNewIntl } from '../helpers';
import auth from './auth';
import entity from './entity';
import env from './env';
import generation from './generation';
import lang from './lang';
import massValidation from './massValidation';
import schemas from './schemas';
import ui from './ui';

const rootReducer = combineReducers({
  [auth.name]: auth.reducer,
  [lang.name]: lang.reducer,
  [schemas.name]: schemas.reducer,
  [entity.name]: entity.reducer,
  [env.name]: env.reducer,
  [ui.name]: ui.reducer,
  [generation.name]: generation.reducer,
  [massValidation.name]: massValidation.reducer,
});

const authTransform: Transform<STATES.Auth, STATES.Auth, STATES.App> = createTransform(
  (inboundState, key) => {
    if (key === auth.name) {
      return { ...inboundState };
    }

    return { ...inboundState };
  },
  (outboundState, key) => {
    if (key === auth.name) {
      if (outboundState.accessToken) {
        const decodedData = jwt.decode(outboundState.accessToken) as Nullable<ENTITIES.JWTData>;

        if (!decodedData || +decodedData.exp * 1000 < +new Date()) {
          return { ...auth.initialState };
        }
      }

      return {
        ...outboundState,
        expDate: outboundState.expDate ? new Date(outboundState.expDate) : null,
      };
    }

    return { ...outboundState };
  },
  {
    whitelist: [auth.name],
  }
);

const langTransform = createTransform<STATES.Language, STATES.Language>(
  (inboundState, key) => {
    if (key === lang.name) {
      return { ...inboundState, intl: null };
    }

    return { ...inboundState };
  },
  (outboundState) => {
    if (!outboundState.intl) {
      return {
        ...outboundState,
        init: createNewIntl(outboundState.currentLanguage, outboundState.messages),
      };
    }

    return { ...outboundState };
  },
  {
    whitelist: [lang.name],
  }
);

export const rootPersistConfig: PersistConfig<STATES.App> = {
  version: 1,
  storage,
  key: 'root',
  whitelist: [auth.name, lang.name, env.name],
  transforms: [authTransform, langTransform],
  stateReconciler: autoMergeLevel2,
};

export default rootReducer;
