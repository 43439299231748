import { EntityAddressType, EntityAddress } from '@coherent/entity-store-ui';
import { Button, Icon } from '@lucid/core';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { commonMessages, sidebarItemMessages } from '../../locale';
import { Box } from '../Atom';
import { JsonResourceModal } from '../Modals';
import SchemaItemStatusDot from './SchemaItemStatusDot';
import { Container, Content, Label, Title } from './SchemaListItem.styled';

export type SchemaListItemProps = {
  selected: boolean;
  hasChanges?: boolean;
  hasErrors?: boolean;
  title: string;
  onSelect?: (key: string) => void;
  jObject: ENTITIES.JsonResourceObject;
  onSelectResource?: (data: Record<string, unknown>, resourceKey: string, entity?: EntityAddress) => void;
  onDeleteResource?: (resourceKey: string) => void;
};

const SchemaListItem = ({
  jObject,
  selected,
  title,
  onSelect,
  hasChanges,
  hasErrors,
  onSelectResource,
  onDeleteResource,
}: SchemaListItemProps): JSX.Element => {
  const intl = useIntl();

  return (
    <Container
      data-testid="schema-list-item"
      selected={selected}
      onClick={() => {
        if (onSelect) {
          onSelect(jObject.resourceKey);
        }
      }}
    >
      <Box>
        <Box isFlex alignItems="center" justifyContent="space-between" mb={1}>
          <SchemaItemStatusDot hasChanges={hasChanges} hasErrors={hasErrors} />
          <Title title={title}>
            <span>{title}</span>
          </Title>
          {onDeleteResource && (
            <Button
              data-testid="schema-delete-list-item"
              onClick={() => onDeleteResource(jObject.resourceKey)}
              type="dashed"
              shape="circle"
              size="small"
            >
              <Icon type="delete" />
            </Button>
          )}
          {onSelectResource && (
            <JsonResourceModal onDone={onSelectResource} resourceKey={jObject.resourceKey} title={title}>
              {({ onOpen }) => (
                <Button onClick={onOpen} type="dashed" shape="circle" size="small" data-testid="json-modal-trigger">
                  <Icon type="file-text" />
                </Button>
              )}
            </JsonResourceModal>
          )}
        </Box>
        {jObject.entityAddress ? (
          <>
            <Box isFlex alignItems="center" justifyContent="space-between">
              <Label>
                <b>
                  <i>
                    <span>{intl.formatMessage(sidebarItemMessages.typeLabel, { type: 'es' })}</span>
                    &nbsp;
                    <span>
                      (
                      {intl.formatMessage(commonMessages.entityDataType, {
                        dataType: jObject.entityAddress.dataType ?? EntityAddressType.Blob,
                      })}
                      )
                    </span>
                  </i>
                </b>
              </Label>
            </Box>
            <Box isFlex alignItems="center" justifyContent="space-between">
              <Label>{intl.formatMessage(sidebarItemMessages.pathLabel)}</Label>
              <Content title={jObject.entityAddress.path}>{jObject.entityAddress.path}</Content>
            </Box>
            {jObject.entityVersion && (
              <Box isFlex alignItems="center" justifyContent="space-between">
                <Label>{intl.formatMessage(sidebarItemMessages.versionLabel)}</Label>
                <Content title={jObject.entityVersion.id}>{jObject.entityVersion.id}</Content>
              </Box>
            )}
          </>
        ) : (
          <Box isFlex alignItems="center" justifyContent="space-between">
            <Label>
              <b>
                <span>{intl.formatMessage(sidebarItemMessages.typeLabel, { type: 'local' })}</span>
              </b>
            </Label>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default memo(SchemaListItem);
