import React, { memo } from 'react';
import { StyledStatusIcon } from './SchemaListItem.styled';

type SchemaItemStatusDotProps = {
  hasChanges?: boolean;
  hasErrors?: boolean;
};

const SchemaItemStatusDot = ({ hasChanges, hasErrors }: SchemaItemStatusDotProps): JSX.Element => {
  return <StyledStatusIcon changes={hasChanges} errors={hasErrors} data-testid="schema-item-status-dot" />;
};

export default memo(SchemaItemStatusDot);
