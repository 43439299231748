import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import {
  alignContent,
  AlignContentProps,
  alignItems,
  AlignItemsProps,
  alignSelf,
  AlignSelfProps,
  background,
  BackgroundProps,
  borderRadius,
  BorderRadiusProps,
  borders,
  BordersProps,
  bottom,
  BottomProps,
  color,
  ColorProps,
  display,
  DisplayProps,
  flex,
  flexDirection,
  FlexDirectionProps,
  FlexProps,
  flexWrap,
  FlexWrapProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  justifyItems,
  JustifyItemsProps,
  left,
  LeftProps,
  lineHeight,
  LineHeightProps,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  order,
  OrderProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  right,
  RightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
  boxShadow,
  BoxShadowProps,
} from 'styled-system';

export interface BoxProps
  extends AlignContentProps,
    AlignItemsProps,
    AlignSelfProps,
    BackgroundProps,
    BordersProps,
    BorderRadiusProps,
    BottomProps,
    ColorProps,
    DisplayProps,
    FlexProps,
    FlexDirectionProps,
    FlexWrapProps,
    FontSizeProps,
    FontWeightProps,
    HeightProps,
    JustifyContentProps,
    JustifyItemsProps,
    LeftProps,
    LineHeightProps,
    MaxHeightProps,
    MinHeightProps,
    MaxWidthProps,
    MinWidthProps,
    OrderProps,
    PositionProps,
    RightProps,
    SpaceProps,
    TextAlignProps,
    TopProps,
    WidthProps,
    OverflowProps,
    BoxShadowProps,
    HTMLAttributes<HTMLDivElement> {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<unknown>;
  cursorPointer?: boolean;
  isFlex?: boolean;
  isOverflowHidden?: boolean;
}

const flexCss = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BaseBox = styled.div<BoxProps>`
  cursor: ${({ cursorPointer }): string => (cursorPointer ? 'pointer' : 'inherit')};
  ${({ isFlex }) => isFlex && flexCss};
  ${space}
  ${flex}
  ${flexDirection}
  ${flexWrap}
  ${width}
  ${minWidth}
  ${maxWidth}
  ${height}
  ${minHeight}
  ${maxHeight}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${textAlign}
  ${lineHeight}
  ${position}
  ${bottom}
  ${top}
  ${left}
  ${right}
  ${order}
  ${display}
  ${alignContent}
  ${alignItems}
  ${alignSelf}
  ${justifyContent}
  ${justifyItems}
  ${background}
  ${borders}
  ${borderRadius}
  ${overflow}
  ${boxShadow}
`;

const BoxAsSpan = BaseBox.withComponent('span');

const Box = React.forwardRef<HTMLDivElement, BoxProps>(({ as, children, ...rest }, ref) => {
  if (as === 'span') {
    return (
      <BoxAsSpan style={{ display: 'inline-block' }} {...rest} ref={ref}>
        {children}
      </BoxAsSpan>
    );
  }
  return (
    <BaseBox {...rest} ref={ref}>
      {children}
    </BaseBox>
  );
});

export default Box;
