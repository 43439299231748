import { Select, Input, Icon, Table } from '@lucid/core';
import { ColumnProps } from '@lucid/core/dist/Table';
import { Spin } from 'antd';
import React, { PropsWithChildren, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '..';
import { EnvEntry } from '../../helpers';
import {
  commonMessages,
  environmentModalMessages,
  environmentTableMessages,
  environmentVariableMessages,
} from '../../locale';
import { SelectContainer, MODAL_WIDTH } from './EnvironmentManagerModal.styled';
import { BaseModal } from '.';

export type EnvironmentManagerModalProps = {
  title: string;
  env: EnvEntry;
  onDone: (data: STATES.Env) => void;
  environmentKey: string;
  apiResponse?: Record<string, EnvEntry>;
};

type EditedEnvEntry = {
  variable: string;
  value: string;
  currentValue: string;
};

const mapEnvToEnvEntries = (obj: EnvEntry, env: EnvEntry): EditedEnvEntry[] =>
  Object.entries(obj).map(([key, values]) => ({
    variable: key,
    value: values,
    currentValue: env[key as keyof EnvEntry],
  }));

const EnvironmentManagerModal = ({
  title,
  env,
  children,
  onDone,
  environmentKey,
  apiResponse,
}: PropsWithChildren<EnvironmentManagerModalProps>): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const intl = useIntl();
  const [selectedEnvKey, setSelectedEnvKey] = useState<string>(environmentKey);
  const [envData, setEnvData] = useState<EnvEntry>(env);

  const handleOnCancel = (): void => {
    setOpen(false);
  };

  const openModal = (): void => {
    setOpen(true);
  };
  const handleOnDone = (): void => {
    onDone({ ...envData, environmentKey: selectedEnvKey });
    handleOnCancel();
  };

  const data = useMemo<EditedEnvEntry[]>(() => {
    if (!apiResponse) {
      return mapEnvToEnvEntries(env, env);
    }
    return mapEnvToEnvEntries(apiResponse[selectedEnvKey], env);
  }, [apiResponse, selectedEnvKey, env]);

  const columns = useMemo<ColumnProps<EditedEnvEntry>[]>(() => {
    const onResetCurrentValue = (value: string, variable: string): void => {
      setEnvData({ ...envData, [variable as keyof EnvEntry]: value });
    };
    return [
      {
        key: 'variable',
        title: intl.formatMessage(environmentTableMessages.colVariable),
        dataIndex: 'variable',
        align: 'left',
        width: '200px',
        ellipsis: true,
        render: (text: keyof EnvEntry) => {
          return intl.formatMessage(environmentVariableMessages[text]);
        },
      },
      {
        key: 'value',
        title: intl.formatMessage(environmentTableMessages.colInitialValue),
        dataIndex: 'value',
        align: 'left',
      },
      {
        key: 'currentValue',
        title: intl.formatMessage(environmentTableMessages.colCurrentValue),
        dataIndex: 'currentValue',
        render: (text: string, record) => {
          return (
            <Input
              value={envData[record.variable as keyof EnvEntry]}
              name={record.variable}
              type="text"
              onChange={(e) => {
                setEnvData({ ...envData, [record.variable as keyof EnvEntry]: e.target.value });
              }}
            />
          );
        },
        align: 'left',
        colSpan: 2,
      },
      {
        key: 'icon',

        dataIndex: 'value',
        render: (text, record) => <Icon onClick={() => onResetCurrentValue(text, record.variable)} type="reload" />,
        width: '50px',
        colSpan: 0,
      },
    ];
  }, [envData, intl]);
  return (
    <>
      <Box display="inline" onClick={openModal} data-testid="json-modal-trigger">
        {children}
      </Box>

      <BaseModal
        visible={isOpen}
        onCancel={handleOnCancel}
        onOk={handleOnDone}
        okText={intl.formatMessage(commonMessages.apply)}
        width={MODAL_WIDTH}
        title={title}
      >
        {!apiResponse ? (
          <Spin />
        ) : (
          <Box width="100%">
            <Box width="100%" isFlex alignItems="baseline" mb={10}>
              <b>{intl.formatMessage(environmentModalMessages.placeholder)}</b>
              <SelectContainer
                data-testid="env-select"
                value={selectedEnvKey}
                onChange={(value) => setSelectedEnvKey(value as string)}
              >
                {Object.keys(apiResponse).map((key) => {
                  return <Select.Option key={key}>{key}</Select.Option>;
                })}
              </SelectContainer>
            </Box>
            <Table
              rowKey="variable"
              loading={!apiResponse}
              scroll={{ y: 270, x: 'max-content' }}
              pagination={false}
              columns={columns}
              dataSource={data}
              bordered
            />
            <span>{intl.formatMessage(environmentModalMessages.info)}</span>
          </Box>
        )}
      </BaseModal>
    </>
  );
};

export default EnvironmentManagerModal;
