import { createGlobalStyle, css, DefaultTheme } from 'styled-components';

const TabsCardGlobalCss = css`
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-color: transparent;
    border-bottom-color: ${({ theme }) => theme.color.primary};
    text-align: center;
    min-width: ${({ theme }) => theme.space[8] * 2}px;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border-color: ${({ theme }) => theme.color.primary};
    background: ${({ theme }) => theme.color.primary};
  }
`;

const TableGlobalCss = css`
  .ant-table-body {
    overflow: auto !important;
  }
`;

const ButtonGlobalCss = css`
  .ant-btn {
    &.ant-btn-dashed {
      background-color: transparent !important;
      :hover {
        background-color: transparent !important;
      }
    }
  }
`;

export const AppGlobalStyled = createGlobalStyle<{ theme: DefaultTheme }>`
  ${TabsCardGlobalCss} 
  ${TableGlobalCss}
  ${ButtonGlobalCss}
`;
