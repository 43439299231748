import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SchemaItemStatusDot, TextEllipsis } from '../../components';

const schemaHeaderTitleSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.selectedKey,
  ({ schemas }: STATES.App) => schemas.resourcesHaveChanges,
  ({ schemas }: STATES.App) => schemas.resourcesHaveErrors,
  (selectedKey, resourcesHaveChanges, resourcesHaveErrors) => {
    return {
      selectedKey,
      resourcesHaveChanges,
      resourcesHaveErrors,
    };
  }
);

const SchemaHeaderTitle = (): JSX.Element | null => {
  const { resourcesHaveChanges, resourcesHaveErrors, selectedKey } = useSelector(schemaHeaderTitleSelector);

  return (
    <>
      <SchemaItemStatusDot
        hasChanges={resourcesHaveChanges[selectedKey]}
        hasErrors={resourcesHaveErrors[selectedKey]}
      />
      <TextEllipsis>
        <FormattedMessage id="sidebar.item.title" values={{ key: selectedKey }} />
      </TextEllipsis>
    </>
  );
};

export default memo(SchemaHeaderTitle);
