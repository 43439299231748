import AuthAsyncActions from './AuthAsyncActions';
import AuthSlice, { AUTH_INITIAL_STATE } from './AuthSlice';

export default {
  name: AuthSlice.name,
  reducer: AuthSlice.reducer,
  initialState: AUTH_INITIAL_STATE,
  actions: {
    ...AuthSlice.actions,
    ...AuthAsyncActions,
  },
};
