import styled from 'styled-components';
import Theme from '../../styles/theme';

export const MassValidationHeader = styled.div`
  width: 100%;
  min-height: ${({ theme }) => theme.space[5]}px;
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: bold;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.space[2]}px;
  align-items: center;
`;

export const MassValidationTitle = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MassValidationControlsContainer = styled.div`
  display: flex;
  align-items: center;

  .type-switch-label {
    margin-left: ${({ theme }) => theme.space[1]}px;
  }

  button {
    color: ${({ theme }) => theme.color.secondary};
    margin: 0 ${({ theme }) => theme.space[1]}px;
    padding: 0 ${({ theme }) => theme.space[1]}px;
    font-weight: bold;

    .anticon {
      font-size: ${({ theme }) => (theme.fontSizes ?? Theme.fontSizes)[2]}px;
    }

    :hover,
    :active,
    :focus {
      color: ${({ theme }) => theme.color.secondary};
      border: 1px solid ${({ theme }) => theme.color.secondary};
    }
  }
`;
