import { Aqua, Fuchsia, Shades, Purple } from '@lucid/colors';
import { DefaultTheme } from 'styled-components';

const Theme: DefaultTheme = {
  breakpoints: ['480px', '576px', '768px', '992px', '1200px', '1600px'],
  space: [0, 4, 8, 16, 24, 32, 48, 56, 64, 250],
  fontSizes: [12, 14, 16, 20, 24, 30, 38],
  color: {
    primary: Aqua.primary,
    secondary: Shades.dark,
    error: '#ff4d4f',
    black: '#333333',
    gray: '#828282',
    gray4: '#BDBDBD',
    gray5: '#E0E0E0',
    gray6: '#F2F2F2',
    gray7: '#E5E5E5',
    brown: '#F19955',
    white: Shades.white,
    pink: Fuchsia.primary,
    purple: Purple.primary,
  },
};

export default Theme;
