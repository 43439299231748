import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const envSelector = createSelector(
  ({ env }: STATES.App) => env,

  ({ environmentKey, entityStoreHost, globalRefMappingsEntity, keycloakClientId, keycloakDomain, keycloakTenant }) => ({
    environmentKey,
    entityStoreHost,
    globalRefMappingsEntity,
    keycloakClientId,
    keycloakDomain,
    keycloakTenant,
  })
);

const useEnvironmentState = (): STATES.Env => {
  return useSelector(envSelector);
};

export default useEnvironmentState;
