import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SchemaItemStatusDot, TextEllipsis } from '../../components';
import { headerMessages } from '../../locale';

const GeneratedHeaderTitleSelector = createSelector(
  ({ generation }: STATES.App) => generation.generatedSchemaHasChanges,
  ({ generation }: STATES.App) => generation.generatedSchemaHasErrors,

  (generatedSchemaHasChanges, generatedSchemaHasErrors) => {
    return {
      generatedSchemaHasChanges,
      generatedSchemaHasErrors,
    };
  }
);

const GeneratedHeaderTitle = (): JSX.Element => {
  const intl = useIntl();

  const { generatedSchemaHasChanges, generatedSchemaHasErrors } = useSelector(GeneratedHeaderTitleSelector);

  return (
    <>
      <SchemaItemStatusDot hasChanges={generatedSchemaHasChanges} hasErrors={generatedSchemaHasErrors} />

      <TextEllipsis>{intl.formatMessage(headerMessages.generatedJsonSchema)}</TextEllipsis>
    </>
  );
};

export default memo(GeneratedHeaderTitle);
