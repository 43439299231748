import LangAsyncActions from './LangAsyncActions';
import LangSlice from './LangSlice';

export default {
  name: LangSlice.name,
  reducer: LangSlice.reducer,
  actions: {
    ...LangSlice.actions,
    ...LangAsyncActions,
  },
};
