import '@lucid/fonts';
import { Action } from '@reduxjs/toolkit';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AppProvider } from '../../providers';
import { Env, Lang, store } from '../../store';
import { Diffs } from '../Diffs';
import Generation from '../Generation/Generation';
import { Home } from '../Home';
import { MassValidationPage } from '../MassValidation';
import { GenerationSidebar, Sidebar } from '../Sidebar';
import { Temp } from '../Temp';
import AppLayout from './AppLayout';

const App = (): JSX.Element => {
  const onBeforeLift = async (): Promise<void> => {
    const { lang } = store.getState();

    const appDispatch: ThunkDispatch<STATES.App, null, Action> = store.dispatch;

    await appDispatch(Lang.actions.setLanguage(lang.currentLanguage));
    await appDispatch(Env.actions.fetchEnv());
  };

  return (
    <AppProvider onBeforeLift={onBeforeLift}>
      <Switch>
        <Route path="/old" component={Temp} />
        <Route
          path="/diffs"
          render={() => (
            <AppLayout>
              <Diffs />
            </AppLayout>
          )}
        />
        <Route
          path="/mass/validation"
          render={() => (
            <AppLayout>
              <MassValidationPage />
            </AppLayout>
          )}
        />
        <Route
          path="/generation"
          render={() => (
            <AppLayout sidebar={<GenerationSidebar />}>
              <Generation />
            </AppLayout>
          )}
        />
        <Route
          path="/"
          render={() => (
            <AppLayout sidebar={<Sidebar />}>
              <Home />
            </AppLayout>
          )}
        />
      </Switch>
    </AppProvider>
  );
};

export default App;
