import { EntityAddress, useEntityStoreContext } from '@coherent/entity-store-ui';
import { useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messageHolder } from '../../helpers';
import { useModal } from '../../hooks';
import { entityNewModalMessages } from '../../locale';
import { UI, useAppDispatch } from '../../store';
import BaseModal from './BaseModal';
import { FooterCheckbox } from './EntityNewModal.styled';
import { EntityUploadForm, EntityUploadModel } from './EntityUploadModal';

export type EntityNewModalProps = {
  children?: (props: { onOpen: () => void; disabled?: boolean }) => JSX.Element;
  isVisible?: boolean;
  setVisible?: (visible: boolean) => void;
  onDone: (entityAddress: EntityAddress, entityExists: boolean) => void;
};

export const EntityNewModal = ({ children, setVisible, isVisible, onDone }: EntityNewModalProps): JSX.Element => {
  const { downloadEntity } = useEntityStoreContext();
  const { checkAndCreateRequiredSchemas } = useEntityStoreJsonEditorContext();
  const formRef = useRef<{ form: WrappedFormUtils<EntityUploadModel> } | null>(null);
  const { isOpen, onCancel, onOpen } = useModal({ isVisible, setVisible });
  const dispatch = useAppDispatch();
  const [enablePrepareSetups, setEnablePrepareSetups] = useState(true);

  const onSubmit = (): void => {
    if (!formRef.current) {
      return;
    }

    formRef.current.form.validateFields((err, formData) => {
      if (err) {
        return;
      }

      const { dataType, privacyLevel } = formData;

      const entityAddress: EntityAddress = {
        path: formData.entityPath.trim(),
        dataType,
        entityType: formData.entityType.trim(),
        privacyLevel,
      };

      void (async (): Promise<void> => {
        try {
          dispatch(UI.actions.setGlobalLoading(true));

          // Check for entity with give path already exists
          const entityData = await downloadEntity(entityAddress);

          if (enablePrepareSetups) {
            await checkAndCreateRequiredSchemas(dataType, entityAddress.entityType ?? '');
          }

          void onDone(entityAddress, Boolean(entityData?.entity));

          onCancel();
        } catch (error) {
          messageHolder.error(error);
        } finally {
          dispatch(UI.actions.setGlobalLoading(false));
        }
      })();
    });
  };

  return (
    <>
      {children && children({ onOpen })}
      <BaseModal
        title={<FormattedMessage {...entityNewModalMessages.title} />}
        visible={isOpen}
        onCancel={onCancel}
        onOk={onSubmit}
        width={700}
        footerExtraComponent={
          <FooterCheckbox onChange={(e) => setEnablePrepareSetups(e.target.checked)} checked={enablePrepareSetups}>
            <FormattedMessage {...entityNewModalMessages.prepareSetups} />
          </FooterCheckbox>
        }
      >
        <EntityUploadForm wrappedComponentRef={formRef} hideEntityId />
      </BaseModal>
    </>
  );
};

export default memo(EntityNewModal);
