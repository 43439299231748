import { EditorType } from '@coherent/json-editor';
import React from 'react';
import { Box } from '../Atom';
import { RawTabContainer, TAB_CONTENT_HEIGHT } from './common.styled';
import { TabProps } from './LocalTab';

interface RawTabProps extends TabProps {
  data: Record<string, unknown>;
}

const RawTab = ({ setData, data }: RawTabProps): JSX.Element => {
  return (
    <Box width="100%" minHeight={TAB_CONTENT_HEIGHT}>
      <RawTabContainer onChange={setData} initData={data} displayType={EditorType.Textual} />
    </Box>
  );
};

export default RawTab;
