import { EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RESOURCE_KEY_JSON_SCHEMA, RESOURCE_KEY_UI_SCHEMA } from '../../common';
import SchemasAsyncActions from './SchemasAsyncActions';

const SCHEMAS_SLICE_NAME = 'schemas';
export const SCHEMAS_INITIAL_STATE: STATES.Schemas = {
  selectedKey: '',
  resourcesHaveChanges: {},
  resourcesHaveErrors: {},
  jsonSchema: {
    entityAddress: null,
    entityVersion: null,
    obj: {},
    originObj: {},
    resourceKey: RESOURCE_KEY_JSON_SCHEMA,
  },
  uiSchema: {
    entityAddress: null,
    entityVersion: null,
    obj: {},
    originObj: {},
    resourceKey: RESOURCE_KEY_UI_SCHEMA,
  },
  refsMappingList: {},
};

const selectSchema: CaseReducer<STATES.Schemas, PayloadAction<{ resourceKey: string }>> = (
  state,
  { payload: { resourceKey } }
) => {
  state.selectedKey = resourceKey;
};

const hasChanges: CaseReducer<STATES.Schemas, PayloadAction<{ resourceKey: string; value?: boolean }>> = (
  state,
  { payload: { resourceKey, value = true } }
) => {
  if (state.resourcesHaveChanges[resourceKey] === value) {
    return;
  }

  state.resourcesHaveChanges = {
    ...state.resourcesHaveChanges,
    [resourceKey]: value,
  };
};

const hasErrors: CaseReducer<STATES.Schemas, PayloadAction<{ resourceKey: string; value?: boolean }>> = (
  state,
  { payload: { resourceKey, value = true } }
) => {
  if (state.resourcesHaveErrors[resourceKey] === value) {
    return;
  }

  state.resourcesHaveErrors = {
    ...state.resourcesHaveErrors,
    [resourceKey]: value,
  };
};

const resetChanges: CaseReducer<STATES.Schemas, PayloadAction<{ resourceKey: string }>> = (
  state,
  { payload: { resourceKey } }
) => {
  if (!state.resourcesHaveChanges[resourceKey]) {
    return;
  }
  switch (resourceKey) {
    case RESOURCE_KEY_UI_SCHEMA: {
      state.uiSchema = {
        ...state.uiSchema,
        obj: { ...state.uiSchema.originObj },
      };
      break;
    }
    case RESOURCE_KEY_JSON_SCHEMA: {
      state.jsonSchema = {
        ...state.jsonSchema,
        obj: { ...state.jsonSchema.originObj },
      };
      break;
    }
    default: {
      state.refsMappingList = {
        ...state.refsMappingList,
        [resourceKey]: {
          ...state.refsMappingList[resourceKey],
          obj: { ...state.refsMappingList[resourceKey].originObj },
        },
      };
      break;
    }
  }

  const { [resourceKey]: removingHasChanges, ...resourcesHaveChanges } = state.resourcesHaveChanges;

  state.resourcesHaveChanges = resourcesHaveChanges;

  const { [resourceKey]: removingHasErrors, ...resourcesHaveErrors } = state.resourcesHaveErrors;

  state.resourcesHaveErrors = resourcesHaveErrors;
};

const updateAfterSaved: CaseReducer<
  STATES.Schemas,
  PayloadAction<{ savedAddress: EntityAddress; savedData: EntityData; resourceKey: string }>
> = (state, { payload: { savedData, savedAddress, resourceKey } }) => {
  const updateSchemaOnSaved = (schema: ENTITIES.JsonResourceObject): ENTITIES.JsonResourceObject => {
    return {
      ...schema,
      entityAddress: {
        ...savedAddress,
        versionId: savedData.effectiveVersion?.id,
      },
      obj: savedData.data,
      originObj: savedData.data,
      entityVersion: savedData.effectiveVersion || null,
    };
  };

  switch (resourceKey) {
    case RESOURCE_KEY_UI_SCHEMA: {
      state.uiSchema = updateSchemaOnSaved(state.uiSchema);
      break;
    }
    case RESOURCE_KEY_JSON_SCHEMA: {
      state.jsonSchema = updateSchemaOnSaved(state.jsonSchema);
      break;
    }
    default: {
      state.refsMappingList[resourceKey] = updateSchemaOnSaved(state.refsMappingList[resourceKey]);
      break;
    }
  }

  state.resourcesHaveChanges[resourceKey] = false;
};

const SchemasSlice = createSlice({
  name: SCHEMAS_SLICE_NAME,
  initialState: SCHEMAS_INITIAL_STATE,
  reducers: { selectSchema, hasChanges, resetChanges, hasErrors, updateAfterSaved },
  extraReducers: (builder) => {
    builder.addCase(SchemasAsyncActions.setSchema.fulfilled, (state, { payload }) => {
      const { newRefsMappingList, schema } = payload;

      switch (schema.resourceKey) {
        case RESOURCE_KEY_UI_SCHEMA: {
          state.uiSchema = schema;
          break;
        }
        case RESOURCE_KEY_JSON_SCHEMA: {
          state.jsonSchema = schema;

          // json schema changes -> Replace refsMappingList with newRefsMappingList but keeping the schemas have changes
          state.refsMappingList = {
            ...state.refsMappingList,
            ...Object.entries(newRefsMappingList).reduce((prev, [key, value]) => {
              if (state.resourcesHaveChanges[key]) {
                return prev;
              }

              return {
                ...prev,
                [key]: value,
              };
            }, {} as Record<string, ENTITIES.JsonResourceObject>),
          };

          break;
        }
        default: {
          state.refsMappingList[schema.resourceKey] = schema;

          // ref schema changes -> Add newRefsMappingList into refsMappingList
          state.refsMappingList = {
            ...state.refsMappingList,
            ...newRefsMappingList,
          };

          break;
        }
      }

      state.resourcesHaveChanges[schema.resourceKey] = false;
      state.resourcesHaveErrors[schema.resourceKey] = false;
    });
    builder.addCase(SchemasAsyncActions.setSchemasWithJSONSetups.fulfilled, (state, { payload }) => {
      const { jsonSchema, refsMappingList, uiSchema } = payload;

      state.jsonSchema = jsonSchema;
      state.refsMappingList = refsMappingList;
      state.uiSchema = uiSchema;

      state.resourcesHaveChanges = {};
      state.resourcesHaveErrors = {};
    });
    builder.addCase(SchemasAsyncActions.applyChanges.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }

      const { obj, resourceKey, newRefsMappingList } = payload;

      switch (resourceKey) {
        case RESOURCE_KEY_UI_SCHEMA: {
          state.uiSchema = {
            ...state.uiSchema,
            obj,
          };
          break;
        }
        case RESOURCE_KEY_JSON_SCHEMA: {
          state.jsonSchema = {
            ...state.jsonSchema,
            obj,
          };
          state.refsMappingList = {
            ...state.refsMappingList,
            ...newRefsMappingList,
          };
          break;
        }
        default: {
          state.refsMappingList = {
            ...state.refsMappingList,
            [resourceKey]: {
              ...state.refsMappingList[resourceKey],
              obj,
            },
            ...newRefsMappingList,
          };
          break;
        }
      }
    });
  },
});

export default SchemasSlice;
