import { Switch } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { entityTableMessages } from '../../locale';
import { MassValidationModal } from '../Modals';
import { EntitiesTableColumnHeaderControlContainer } from './EntitiesValidation.styled';

type EntitiesTableColumnHeaderProps = {
  toggleInvalidOnly: () => void;
  invalidOnly: boolean;
};

const EntitiesTableColumnHeader = ({ toggleInvalidOnly, invalidOnly }: EntitiesTableColumnHeaderProps): JSX.Element => {
  return (
    <EntitiesTableColumnHeaderControlContainer>
      <MassValidationModal />
      <Switch onClick={toggleInvalidOnly} checked={invalidOnly} />
      <FormattedMessage {...entityTableMessages.massValidationInvalidOnly} />
    </EntitiesTableColumnHeaderControlContainer>
  );
};

export default EntitiesTableColumnHeader;
