import { Button, Icon } from '@lucid/core';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { EditorHeaderControlsContainer } from '../../components';
import { jsonEditorMessages } from '../../locale';
import { Generation, useAppDispatch } from '../../store';

const SampleDataHeaderControlSelector = createSelector(
  ({ generation }: STATES.App) => generation.selectedKey,
  (selectedKey) => {
    return {
      selectedKey,
    };
  }
);

export type SampleDataHeaderControlProps = {
  applyChanges: () => void;
};

const SampleDataHeaderControl = ({ applyChanges }: SampleDataHeaderControlProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { selectedKey } = useSelector(SampleDataHeaderControlSelector);

  const closeSchemaEditColumn = (): void => {
    dispatch(
      Generation.actions.selectSchema({
        resourceKey: '',
      })
    );
  };

  const resetChanges = (): void => {
    dispatch(
      Generation.actions.resetChanges({
        resourceKey: selectedKey,
      })
    );
  };

  return (
    <EditorHeaderControlsContainer>
      <Button type="link" size="small" onClick={applyChanges}>
        <Icon type="check-circle" />
        <span>
          <FormattedMessage {...jsonEditorMessages.menuApply} />
        </span>
      </Button>
      <Button type="link" size="small" onClick={resetChanges}>
        <Icon type="sync" />
        <span>
          <FormattedMessage {...jsonEditorMessages.menuReset} />
        </span>
      </Button>
      <Button type="dashed" shape="circle" size="small" onClick={closeSchemaEditColumn}>
        <Icon type="close" />
      </Button>
    </EditorHeaderControlsContainer>
  );
};

export default memo(SampleDataHeaderControl);
