import { useCallback, useEffect, useState } from 'react';

type UseModalType = {
  isOpen: boolean;
  onCancel: () => void;
  onOpen: () => void;
};
type UseModalProps = {
  beforeOpen?: () => void;
  isVisible?: boolean;
  setVisible?: (visible: boolean) => void;
};

const useModal = (props?: UseModalProps): UseModalType => {
  const { isVisible, setVisible, beforeOpen } = props ?? {};
  const [isOpen, setOpen] = useState(typeof isVisible === 'boolean' ? isVisible : false);

  useEffect(() => {
    if (typeof isVisible === 'undefined') {
      return;
    }

    if (isVisible && beforeOpen) {
      beforeOpen();
    }

    setOpen(isVisible);
  }, [isVisible, beforeOpen]);

  const onOpen = useCallback((): void => {
    if (beforeOpen) {
      beforeOpen();
    }

    setOpen(true);
  }, [beforeOpen]);

  const onCancel = useCallback((): void => {
    setOpen(false);

    if (setVisible) {
      setVisible(false);
    }
  }, [setVisible]);

  return {
    isOpen,
    onOpen,
    onCancel,
  };
};

export default useModal;
