import { EntityAddress, EntityData, useEntityStoreContext } from '@coherent/entity-store-ui';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box } from '../../components/Atom';
import EntitiesTableColumnHeaderControl from '../../components/EntitiesValidation/EntitiesTableColumnHeaderControl';
import EntitiesValidationTable from '../../components/EntitiesValidation/EntitiesValidationTable';
import { commonMessages, entityTableMessages } from '../../locale';
import { Entity, MassValidation, useAppDispatch } from '../../store';
import Theme from '../../styles/theme';
import { MassValidationControlsContainer, MassValidationHeader, MassValidationTitle } from './MassValidation.styled';

const massValidationSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.refsMappingList,
  ({ schemas }: STATES.App) => schemas.jsonSchema,
  ({ schemas }: STATES.App) => schemas.uiSchema,
  ({ entity }: STATES.App) => entity.dataDisplayType,
  ({ massValidation }: STATES.App) => massValidation,
  (refsMappingList, jsonSchema, uiSchema, dataDisplayType, massValidation) => {
    return {
      refsMappingList,
      jsonSchema,
      uiSchema,
      dataDisplayType,
      massValidation,
    };
  }
);

const EntitiesValidationTableColumn = (): JSX.Element => {
  const { dataDisplayType, massValidation } = useSelector(massValidationSelector);
  const { docStore, entityHostUrl } = useEntityStoreContext();
  const intl = useIntl();
  const { dataType, entities, entityType, invalidOnly, rootFolder, pageIndex, total } = massValidation;

  const dispatch = useAppDispatch();
  const pagination = useMemo(() => {
    if (invalidOnly) {
      return undefined;
    }

    return {
      pageSize: 10,
      currentPage: pageIndex,
      total,
    };
  }, [invalidOnly, pageIndex, total]);

  const toggleInvalidOnly = (): void => {
    dispatch(MassValidation.actions.toggleInvalidOnly());
  };

  const onSelectEntity = useCallback(
    (path: string, entityData: EntityData): void => {
      const address: EntityAddress = { dataType, entityType, path };

      dispatch(
        Entity.actions.setFromJSONSetups({
          jsonSetups: {
            entityType,
            data: {
              entityAddress: address,
              entityData,
            },
          },
          forceDisplayType: dataDisplayType,
        })
      );
    },
    [dataDisplayType, dataType, dispatch, entityType]
  );

  const onPageChange = useCallback(
    (page: number) => {
      if (!docStore) {
        return;
      }

      void dispatch(
        MassValidation.actions.changeInputs({
          dataType,
          invalidOnly,
          entityType,
          rootFolder,
          docStore,
          entityHostUrl,
          pageIndex: page,
        })
      );
    },
    [dataType, dispatch, docStore, entityHostUrl, entityType, invalidOnly, rootFolder]
  );

  return (
    <Box
      height="100%"
      width="100%"
      isFlex
      borderColor={Theme.color.secondary}
      data-testid="data-json-editor"
      maxWidth="100%"
      overflow="hidden"
    >
      <MassValidationHeader className="json-editor-header">
        <MassValidationTitle>
          {intl.formatMessage(entityTableMessages.massValidationHeading, {
            content: intl.formatMessage(
              entityType ? entityTableMessages.massValidationInputs : entityTableMessages.massValidationNoneSelected,
              {
                entityType,
                dataType: intl.formatMessage(commonMessages.entityDataType, { dataType }),
              }
            ),
          })}
        </MassValidationTitle>
        <MassValidationControlsContainer>
          <EntitiesTableColumnHeaderControl invalidOnly={invalidOnly} toggleInvalidOnly={toggleInvalidOnly} />
        </MassValidationControlsContainer>
      </MassValidationHeader>
      <EntitiesValidationTable
        entities={entities}
        onSelectEntity={onSelectEntity}
        pagination={pagination}
        onPageChange={onPageChange}
      />
    </Box>
  );
};

export default EntitiesValidationTableColumn;
