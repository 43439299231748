import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box } from '../../components';
import EnvironmentManagerModal from '../../components/Modals/EnvironmentManagerModal';
import { EnvEntry } from '../../helpers';
import { environmentModalMessages } from '../../locale';
import { Auth, Env, useAppDispatch } from '../../store';

const envSelector = createSelector(
  ({ env }: STATES.App) => env,

  ({
    environmentKey,
    entityStoreHost,
    globalRefMappingsEntity,
    keycloakClientId,
    keycloakDomain,
    keycloakTenant,
    apiResponse,
  }) => ({
    environmentKey,
    entityStoreHost,
    globalRefMappingsEntity,
    keycloakClientId,
    keycloakDomain,
    keycloakTenant,
    apiResponse,
  })
);

const Environment = (): JSX.Element => {
  const env = useSelector(envSelector);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const onApplySelectedEnv = (data: STATES.Env): void => {
    void dispatch(
      Env.actions.selectEnv({
        selectedEnv: data,
      })
    );
    dispatch(Auth.actions.logout());
  };

  return (
    <EnvironmentManagerModal
      environmentKey={env.environmentKey}
      onDone={onApplySelectedEnv}
      apiResponse={env.apiResponse as Record<string, EnvEntry>}
      env={env}
      title={intl.formatMessage(environmentModalMessages.title)}
    >
      <Box data-testid="env-modal-trigger" isFlex alignContent="center">
        <FormattedMessage
          id="envModal.trigger"
          values={{
            environmentKey: (
              <Box display="inline-block" ml={1} fontWeight="bold">
                {env.environmentKey}
              </Box>
            ),
          }}
        />
      </Box>
    </EnvironmentManagerModal>
  );
};

export default memo(Environment);
