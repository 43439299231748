import { EntityAddress } from '@coherent/entity-store-ui';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import { getEntityAddressFromPath } from '../helpers';

type UseEntityAddressRouteMatchType = {
  entityAddressFromPath: Nullable<EntityAddress>;
};

const useEntityAddressRouteMatchSelector = createSelector(
  ({ entity }: STATES.App) => entity.data,
  (data) => {
    return { data };
  }
);

const useEntityAddressRouteMatch = (): UseEntityAddressRouteMatchType => {
  const { pathname } = useLocation();
  const { data } = useSelector(useEntityAddressRouteMatchSelector);
  const [entityAddressFromPath, setEntityAddressFromPath] = useState<Nullable<EntityAddress>>(null);
  const dataRef = useRef(data);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect((): void => {
    const detectedEntityAddress = getEntityAddressFromPath(pathname);

    if (!detectedEntityAddress) {
      setEntityAddressFromPath(null);

      return;
    }

    const { entityAddress, entityVersion } = dataRef.current;

    if (
      entityAddress?.path !== detectedEntityAddress.path ||
      entityAddress?.entityType !== detectedEntityAddress.entityType ||
      entityVersion?.id !== detectedEntityAddress.versionId ||
      entityAddress?.documentId !== detectedEntityAddress.documentId
    ) {
      setEntityAddressFromPath(detectedEntityAddress);
    }
  }, [pathname]);

  return {
    entityAddressFromPath,
  };
};

export default useEntityAddressRouteMatch;
