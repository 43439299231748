import styled from 'styled-components';

export * from './Logo';

export const TextEllipsis = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export { default as LoadingOverlay } from './LoadingOverlay';
