import styled from 'styled-components';
import { ReactComponent } from '../../assets/logo.svg';

export const DarkLogo = styled(ReactComponent)`
  && {
    path {
      fill: ${({ theme }) => theme.color.secondary};
    }
  }
`;

export const Logo = ReactComponent;
