import UISlice, { UI_INITIAL_STATE } from './UISlice';

export default {
  initialState: UI_INITIAL_STATE,
  name: UISlice.name,
  reducer: UISlice.reducer,
  actions: {
    ...UISlice.actions,
  },
};
