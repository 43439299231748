import { EntityAddress, EntityData, useEntityStoreContext } from '@coherent/entity-store-ui';
import { useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { Message } from '@lucid/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RESOURCE_KEY_DATA, RESOURCE_KEY_UI_SCHEMA, RESOURCE_KEY_JSON_SCHEMA } from '../common';
import { EntityUploadModal } from '../components';
import { messageHolder } from '../helpers';
import { entityUploadModalMessages } from '../locale';
import { UI, useAppDispatch } from '../store';

type UseEntityUploadModalType = {
  entityUploadModal: JSX.Element;
  onSave: () => void;
  onSaveAs: () => void;
};

type UseEntityUploadModalProps = {
  resourceKey: string;
  getCurrentData: () => Record<string, unknown>;
  entityAddress: Nullable<EntityAddress>;
  onUploaded: (savedData: EntityData, savedAddress: EntityAddress) => void;
};

const schemaEditSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.jsonSchema?.entityAddress,
  ({ schemas }: STATES.App) => schemas.uiSchema?.entityAddress,
  (schemaAddress, uiSchemaAddress) => {
    return {
      schemaAddress,
      uiSchemaAddress,
    };
  }
);

const useEntityUploadModal = ({
  resourceKey,
  entityAddress,
  getCurrentData,
  onUploaded,
}: UseEntityUploadModalProps): UseEntityUploadModalType => {
  const intl = useIntl();
  const { schemaAddress, uiSchemaAddress } = useSelector(schemaEditSelector);
  const { uploadEntity, downloadEntity } = useEntityStoreContext();
  const {
    suggestEntityAddressFromSchemaPath,
    suggestRefMappingEntityAddressFromUrl,
  } = useEntityStoreJsonEditorContext();
  const dispatch = useAppDispatch();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const suggestEntityAddress = useMemo<Nullable<EntityAddress>>(() => {
    if (entityAddress) {
      return entityAddress;
    }

    if (resourceKey === RESOURCE_KEY_DATA) {
      return (
        suggestEntityAddressFromSchemaPath('json', schemaAddress?.path) ??
        suggestEntityAddressFromSchemaPath('ui', uiSchemaAddress?.path) ??
        entityAddress
      );
    }

    // check for saving $ref mappings
    if (![RESOURCE_KEY_UI_SCHEMA, RESOURCE_KEY_JSON_SCHEMA].includes(resourceKey)) {
      return suggestRefMappingEntityAddressFromUrl(resourceKey);
    }

    return entityAddress;
  }, [
    entityAddress,
    schemaAddress,
    uiSchemaAddress,
    resourceKey,
    suggestEntityAddressFromSchemaPath,
    suggestRefMappingEntityAddressFromUrl,
  ]);

  const onSave = useCallback(async (): Promise<void> => {
    if (!entityAddress) {
      setUploadModalVisible(true);

      return;
    }

    try {
      dispatch(UI.actions.setGlobalLoading(true));

      const data = getCurrentData();

      await uploadEntity(entityAddress, data);

      const lastVersion = await downloadEntity({
        ...entityAddress,
        versionId: undefined,
      });

      if (!lastVersion) {
        throw new Error("Can't find the last version of just updated entity");
      }

      onUploaded(lastVersion, { ...entityAddress, versionId: lastVersion.effectiveVersion?.id });

      void Message.success(intl.formatMessage(entityUploadModalMessages.success));
    } catch (error) {
      messageHolder.error(error);
    } finally {
      dispatch(UI.actions.setGlobalLoading(false));
    }
  }, [dispatch, downloadEntity, entityAddress, getCurrentData, intl, onUploaded, uploadEntity]);

  const onSaveAs = useCallback((): void => {
    setUploadModalVisible(true);
  }, []);

  const entityUploadModal = (
    <EntityUploadModal
      entityAddress={suggestEntityAddress}
      getCurrentData={getCurrentData}
      onUploaded={onUploaded}
      isVisible={uploadModalVisible}
      setVisible={setUploadModalVisible}
    />
  );

  return {
    entityUploadModal,
    onSave,
    onSaveAs,
  };
};

export default useEntityUploadModal;
