import { createAsyncThunk } from '@reduxjs/toolkit';
import configProviderEn from 'antd/es/locale/en_US';
import configProviderZh from 'antd/es/locale/zh_CN';
import { Locale } from 'antd/lib/locale-provider';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { SUPPORTED_LANGUAGES } from '../../common';

const setLanguage = createAsyncThunk('lang/set', async (lang: string) => {
  let resolvedLang = lang;
  let configProviderLocale: Locale = configProviderEn;

  if (!SUPPORTED_LANGUAGES.includes(resolvedLang)) {
    [resolvedLang] = resolvedLang.split('-');
    if (!SUPPORTED_LANGUAGES.includes(resolvedLang)) {
      resolvedLang = 'en';
    }
  }

  moment.locale(resolvedLang);

  switch (resolvedLang) {
    case 'zh': {
      configProviderLocale = configProviderZh;
      break;
    }
    case 'en': {
      configProviderLocale = configProviderEn;
      break;
    }
    default:
      break;
  }

  const messages = (await import(`../../locale/${resolvedLang}`)) as Record<string, string>;

  return {
    lang: resolvedLang,
    messages,
    configProviderLocale,
  };
});

export default {
  setLanguage,
};
