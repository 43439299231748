import { createSelector, unwrapResult } from '@reduxjs/toolkit';
import queryString from 'query-string';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN_PATH } from '../common';
import { userControlMessages } from '../locale';
import { Auth, useAppDispatch } from '../store';

const authSelector = createSelector(
  ({ auth }: STATES.App) => auth,
  ({ accessToken, refreshToken, expDate }) => ({
    accessToken,
    refreshToken,
    expDate,
  })
);
const envSelector = createSelector(
  ({ env }: STATES.App) => env,

  ({ keycloakClientId, keycloakDomain, keycloakTenant }) => ({
    keycloakClientId,
    keycloakDomain,
    keycloakTenant,
  })
);

const AuthProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const { refreshToken, expDate } = useSelector(authSelector);
  const { keycloakDomain, keycloakTenant, keycloakClientId } = useSelector(envSelector);

  const { pathname, search } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isVerifying, setVerifying] = useState(false);

  useEffect(() => {
    if (pathname === LOGIN_PATH) {
      const parsed = queryString.parse(search);

      if (parsed.session_state && typeof parsed.code === 'string') {
        const { code, return_url: returnUrl = '/' } = parsed as Record<string, string>;
        setVerifying(true);

        const onDone = (): void => {
          setVerifying(false);

          history.replace(returnUrl);
        };

        void dispatch(Auth.actions.login({ code, returnUrl })).then(unwrapResult).then(onDone).catch(onDone);
      }
    }
  }, [pathname, search, dispatch, history, keycloakDomain, keycloakTenant, keycloakClientId]);

  useEffect(() => {
    if (!expDate) {
      return () => {};
    }

    const timeoutId = setTimeout(() => {
      void dispatch(Auth.actions.refreshToken(refreshToken));
    }, +expDate - Date.now() - 30000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [expDate, dispatch, refreshToken, keycloakDomain, keycloakTenant, keycloakClientId]);

  if (isVerifying) {
    return (
      <div>
        <FormattedMessage {...userControlMessages.loggingIn} />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthProvider;
