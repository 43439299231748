import MassValidationAsyncActions from './MassValidationAsyncActions';
import MassValidationSlice, { MASS_VALIDATION_INITIAL_STATE } from './MassValidationSlice';

export default {
  initialState: MASS_VALIDATION_INITIAL_STATE,
  name: MassValidationSlice.name,
  reducer: MassValidationSlice.reducer,
  actions: {
    ...MassValidationSlice.actions,
    ...MassValidationAsyncActions,
  },
};
