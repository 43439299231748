import { useEntityStoreContext } from '@coherent/entity-store-ui';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RESOURCE_KEY_COMPARE_1ST, RESOURCE_KEY_COMPARE_2ND } from '../common';
import { getEntityAddressFromPath, messageHolder } from '../helpers';
import { UI, useAppDispatch } from '../store';

type UseDiffsRouteMatchType = {
  firstJsonResource: Nullable<ENTITIES.JsonResourceObject>;
  secondJsonResource: Nullable<ENTITIES.JsonResourceObject>;
};

const useDiffsRouteMatch = (): UseDiffsRouteMatchType => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { downloadEntity, docStore } = useEntityStoreContext();
  const [firstJsonResource, setFirstJsonResource] = useState<Nullable<ENTITIES.JsonResourceObject>>(null);
  const [secondJsonResource, setSecondJsonResource] = useState<Nullable<ENTITIES.JsonResourceObject>>(null);
  const entityAddressPathsRefs = useRef(['', '']);

  const [firstEntityAddressPath, secondEntityAddressPath] = useMemo<Array<string>>(() => {
    const { first, second } = queryString.parse(search);

    if (Array.isArray(first) || Array.isArray(second)) {
      return ['', ''];
    }

    return [first ?? '', second ?? ''];
  }, [search]);

  const getJsonResourceFromEntityAddressPath = useCallback(
    async (
      entityAddressPath: Nullable<string>,
      resourceDefaultKey: string
    ): Promise<Nullable<ENTITIES.JsonResourceObject>> => {
      const entityAddress = getEntityAddressFromPath(`/entity/${entityAddressPath ?? ''}`);

      if (!entityAddress) {
        return null;
      }

      const entity = await downloadEntity(entityAddress);
      const obj = entity?.data ?? {};

      return {
        entityAddress,
        entityVersion: entity?.effectiveVersion ?? null,
        obj,
        originObj: obj,
        resourceKey: resourceDefaultKey,
      };
    },
    [downloadEntity]
  );

  useEffect(() => {
    if (!docStore) {
      return;
    }

    void (async () => {
      try {
        dispatch(UI.actions.setGlobalLoading(true));

        if (entityAddressPathsRefs.current[0] !== firstEntityAddressPath) {
          const resource = await getJsonResourceFromEntityAddressPath(firstEntityAddressPath, RESOURCE_KEY_COMPARE_1ST);
          setFirstJsonResource(resource);
        }

        if (entityAddressPathsRefs.current[1] !== secondEntityAddressPath) {
          const resource = await getJsonResourceFromEntityAddressPath(
            secondEntityAddressPath,
            RESOURCE_KEY_COMPARE_2ND
          );
          setSecondJsonResource(resource);
        }

        entityAddressPathsRefs.current = [firstEntityAddressPath, secondEntityAddressPath];
      } catch (error) {
        messageHolder.error(error);
      } finally {
        dispatch(UI.actions.setGlobalLoading(false));
      }
    })();
  }, [dispatch, docStore, secondEntityAddressPath, firstEntityAddressPath, getJsonResourceFromEntityAddressPath]);

  return {
    firstJsonResource,
    secondJsonResource,
  };
};

export default useDiffsRouteMatch;
