import { Table } from 'antd';
import styled from 'styled-components';
import Theme from '../../styles/theme';

class ClassedTable extends Table<ENTITIES.EntitiesTableRow> {}

export const StyledEntitiesTable = styled(ClassedTable)<{ hasData?: boolean; showAll?: boolean }>`
  width: 100%;
  overflow: hidden;
  .ant-table-body {
    .ant-table-row {
      transition: none;
      cursor: pointer;
    }

    .ant-table-row-selected td {
      background: ${({ theme }) => theme.color.secondary} !important;
      color: ${({ theme }) => theme.color.white};
    }

    .anticon-warning {
      color: ${({ theme }) => (theme.color ?? Theme.color).error};
    }
    .anticon svg {
      height: 21px;
      width: 21px;
    }
  }
  .ant-table-thead > tr > th {
    font-weight: bold;
    color: ${({ theme }) => theme.color.secondary};
    border: none;
  }

  .ant-table-body {
    min-height: ${(props) => {
      if (props.hasData) {
        return 'calc(100vh - 205px)';
      }

      return props.showAll ? 'calc(100vh - 205px)' : 'calc(100vh - 160px)';
    }};
  }

  .ant-table-placeholder {
    min-height: calc(100vh - ${(props) => (props.showAll ? '160px' : '205px')});
  }

  .ant-pagination {
    margin: ${({ theme }) => theme.space[2]}px;
  }

  .ant-table-bordered {
    .ant-table-header > table {
      border: none;
    }
    .ant-table-scroll .ant-table-header::-webkit-scrollbar {
      border: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .textual-editor {
    flex: 1;
  }
`;

export const EntitiesTableColumnHeaderControlContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-switch {
    &.ant-switch-checked {
      background-color: ${({ theme }) => theme.color.secondary};
    }
  }
`;
