import { EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { EditorType, JsonSchemaDraft07, JsonError } from '@coherent/json-editor';
import { Message } from '@lucid/core';
import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { SchemaEdit } from '../../components/SchemaEdit';
import { entityUploadModalMessages } from '../../locale';
import { Generation, useAppDispatch } from '../../store';
import GeneratedHeaderTitle from './GeneratedHeaderTitle';
import GeneratedSchemaHeaderControls from './GeneratedSchemaHeaderControls';

const generatedSchemaSelector = createSelector(
  ({ generation }: STATES.App) => generation.jsonSchema,
  ({ generation }: STATES.App) => generation.lastSavedEntityAddress,

  (jsonSchema, lastSavedEntityAddress) => {
    return {
      jsonSchema,
      lastSavedEntityAddress,
    };
  }
);

const GeneratedSchemaEditContainer = (): JSX.Element => {
  const intl = useIntl();
  const [jsonEditorKey, setJsonEditorKey] = useState(0);
  const dispatch = useAppDispatch();
  const { jsonSchema, lastSavedEntityAddress } = useSelector(generatedSchemaSelector);
  const jsonSchemaRef = useRef<Record<string, unknown>>({});

  const clonedInitData = useMemo(() => {
    jsonSchemaRef.current = cloneDeep(jsonSchema.obj);
    return cloneDeep(jsonSchema.obj);
  }, [jsonSchema.obj]);

  useEffect(() => {
    setJsonEditorKey((current) => current + 1);
  }, [jsonSchema.obj]);
  useEffect(() => {
    jsonSchemaRef.current = clonedInitData;
  }, [clonedInitData]);
  const onChange = useCallback(
    (nextData: Record<string, unknown>) => {
      jsonSchemaRef.current = nextData;
      dispatch(Generation.actions.schemaHasChanges({}));
    },
    [dispatch]
  );

  const getCurrentData = useCallback(() => {
    return jsonSchemaRef.current;
  }, []);

  const onErrors = useCallback(
    (errors: ReadonlyArray<JsonError>) => {
      dispatch(
        Generation.actions.schemaHasErrors({
          value: errors.length !== 0,
        })
      );
    },
    [dispatch]
  );
  const onUploaded = (_: EntityData, savedAddress: EntityAddress): void => {
    dispatch(
      Generation.actions.saveLastEntityAddress({
        savedAddress,
      })
    );
    void Message.success(intl.formatMessage(entityUploadModalMessages.generatedSchemaSuccess));
  };

  return (
    <SchemaEdit
      schema={JsonSchemaDraft07}
      key={`${jsonEditorKey}`}
      initData={jsonSchemaRef.current}
      displayType={EditorType.Textual}
      onErrors={onErrors}
      onChange={onChange}
      headerControls={
        <GeneratedSchemaHeaderControls
          entityAddress={lastSavedEntityAddress}
          onUploaded={onUploaded}
          getCurrentData={getCurrentData}
        />
      }
      headerTitle={<GeneratedHeaderTitle />}
    />
  );
};

export default memo(GeneratedSchemaEditContainer);
