import { EntityAddress, EntityData, useEntityStoreContext } from '@coherent/entity-store-ui';
import { Button, Icon } from '@lucid/core';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box, SchemaListItem, JsonResourceModal } from '../../components';
import { messageHolder } from '../../helpers';
import { sidebarItemMessages, sidebarMessages } from '../../locale';
import { useAppDispatch, UI, Generation } from '../../store';
import {
  SIDEBAR_WIDTH,
  StyledSideBar,
  StyledSidebarHeader,
  StyledSidebarHeaderChild,
  SchemaListItemContainer,
} from './Sidebar.styled';

const sidebarSelector = createSelector(
  ({ generation }: STATES.App) => generation,
  ({ data, resourcesHaveChanges, resourcesHaveErrors, selectedKey }) => ({
    data,
    resourcesHaveChanges,
    resourcesHaveErrors,
    selectedKey,
  })
);

const GenerationSidebar = (): JSX.Element => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { downloadEntity } = useEntityStoreContext();
  const { data, resourcesHaveChanges, resourcesHaveErrors, selectedKey } = useSelector(sidebarSelector);

  const onSelect = useCallback(
    (resourceKey: string) => {
      dispatch(Generation.actions.selectSchema({ resourceKey }));
    },
    [dispatch]
  );
  const onSelectResource = async (
    obj: Record<string, unknown>,
    resourceKey: string,
    entity?: EntityAddress
  ): Promise<void> => {
    if (!entity) {
      void dispatch(Generation.actions.addLocalData({ obj }));
      return;
    }

    try {
      dispatch(UI.actions.setGlobalLoading(true));

      const entityData = (await downloadEntity(entity)) as EntityData;
      const { data: entityObj, effectiveVersion } = entityData;

      const resource = {
        entityAddress: entity,
        entityVersion: effectiveVersion ?? null,
        obj: entityObj,
        originObj: entityObj,
        resourceKey,
      };
      dispatch(Generation.actions.addDataFromEntity({ resource }));
      dispatch(UI.actions.setGlobalLoading(false));
    } catch (error) {
      dispatch(UI.actions.setGlobalLoading(false));
      messageHolder.error(error);
    }
  };

  const onDeleteResource = (resourceKey: string): void => {
    dispatch(Generation.actions.deleteResource({ resourceKey }));
  };

  return (
    <StyledSideBar width={SIDEBAR_WIDTH} collapsed={false}>
      <StyledSidebarHeader display="flex" justifyContent="center" width="100%">
        <FormattedMessage {...sidebarMessages.jsonDataSamples} />
        <StyledSidebarHeaderChild>
          <JsonResourceModal
            onDone={onSelectResource}
            resourceKey=""
            title={intl.formatMessage(sidebarMessages.jsonDataSamples)}
          >
            {({ onOpen }) => (
              <Button onClick={onOpen} data-testid="sidebar-add-icon" type="dashed" shape="circle" size="small">
                <Icon type="plus" />
              </Button>
            )}
          </JsonResourceModal>
        </StyledSidebarHeaderChild>
      </StyledSidebarHeader>
      <SchemaListItemContainer>
        {data.length > 0 ? (
          data.map((dataEntity, key) => {
            return (
              <SchemaListItem
                hasChanges={resourcesHaveChanges[dataEntity.resourceKey]}
                hasErrors={resourcesHaveErrors[key]}
                selected={selectedKey === dataEntity.resourceKey}
                jObject={dataEntity}
                onSelect={onSelect}
                title={`#${key + 1}`}
                onDeleteResource={onDeleteResource}
                key={dataEntity.resourceKey}
              />
            );
          })
        ) : (
          <Box textAlign="center">{intl.formatMessage(sidebarItemMessages.noSampleData)}</Box>
        )}
      </SchemaListItemContainer>
    </StyledSideBar>
  );
};

export default GenerationSidebar;
