import EntitySlice, { ENTITY_INITIAL_STATE } from './EntitySlice';

export default {
  initialState: ENTITY_INITIAL_STATE,
  name: EntitySlice.name,
  reducer: EntitySlice.reducer,
  actions: {
    ...EntitySlice.actions,
  },
};
