import { EntityAddress, EntityStoreBrowser } from '@coherent/entity-store-ui';
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { messageHolder } from '../../helpers';
import { EntityStoreTabContainer, TAB_CONTENT_HEIGHT } from './common.styled';
import { TabProps } from './LocalTab';

interface EntityStoreTabProps extends TabProps {
  setEntity: (entityAddress: EntityAddress | undefined) => void;
}

const EntityStoreTab = ({ setEntity, setData }: EntityStoreTabProps): JSX.Element => {
  const intl = useIntl();
  const onSelectedEntityAddress = useCallback(
    (entityAddress: EntityAddress | undefined) => {
      setEntity(entityAddress);
      setData({});
    },
    [setEntity, setData]
  );

  return (
    <EntityStoreTabContainer overflow="hidden" width="100%" minHeight={TAB_CONTENT_HEIGHT}>
      <EntityStoreBrowser
        onSelectedEntityAddress={onSelectedEntityAddress}
        onError={messageHolder.error}
        language={intl.locale}
      />
    </EntityStoreTabContainer>
  );
};

export default memo(EntityStoreTab);
