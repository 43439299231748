import React, { PropsWithChildren } from 'react';
import { Logo } from '../common';
import { StyledLayoutHeaderBox } from './Layout.styled';

const LayoutHeader = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <StyledLayoutHeaderBox>
      <Logo />
      {children}
    </StyledLayoutHeaderBox>
  );
};

export default LayoutHeader;
