import React from 'react';
import ErrorContainer from './ErrorFallback.styled';

type FallbackProps = {
  error?: Error;
  resetErrorBoundary?: () => void;
};

const ErrorFallback = ({ resetErrorBoundary, error }: FallbackProps): JSX.Element | null => {
  const onReset: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (resetErrorBoundary) {
      resetErrorBoundary();
    }
  };

  if (!error) {
    return null;
  }

  return (
    <ErrorContainer>
      <div>{error.message}</div>
      {resetErrorBoundary && (
        <div>
          <button type="button" onClick={onReset}>
            Clear
          </button>
        </div>
      )}
    </ErrorContainer>
  );
};

export default ErrorFallback;
