import { JsonEditor } from '@coherent/json-editor';
import { Row } from '@lucid/core';
import styled from 'styled-components';

export const MODAL_CONTENT_HEIGHT = 400;

export const StyledJsonEditor = styled(JsonEditor)`
  width: 100%;
  height: 100%;

  .json-editor-header {
    min-height: ${({ theme }) => theme.space[6]}px;
  }

  .jsoneditor,
  .ace_editor {
    min-height: ${MODAL_CONTENT_HEIGHT}px !important;
  }

  .different_element {
    background-color: #acee61;
  }

  .different_element div.jsoneditor-field,
  .different_element div.jsoneditor-value {
    color: red;
  }
`;

export const StyledRow = styled(Row)`
  overflow: hidden;
`;
