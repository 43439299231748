import styled, { css } from 'styled-components';

const LISTITEM_HEIGHT = 108;

const SelectedContainerCss = css`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.secondary};
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary};

  .ant-btn.ant-btn-dashed {
    background-color: transparent;
    color: ${({ theme }) => theme.color.white};
  }

  :hover {
    background-color: ${({ theme }) => theme.color.secondary};

    .ant-btn.ant-btn-dashed {
      background-color: transparent;
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

export const Container = styled.div<{ selected: boolean }>`
  width: 100%;
  min-height: ${LISTITEM_HEIGHT}px;
  height: ${LISTITEM_HEIGHT}px;
  padding: ${({ theme }) => theme.space[2]}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.primary};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.color.gray6};

    .ant-btn.ant-btn-dashed {
      background-color: transparent;
    }
  }

  ${({ selected }) => selected && SelectedContainerCss}
`;

export const Title = styled.div`
  font-weight: bold;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
`;

export const Content = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${({ theme }) => theme.space[1]}px;
  white-space: nowrap;
`;

export const StyledStatusIconChangesCss = css`
  background-color: ${({ theme }) => theme.color.purple};
`;

export const StyledStatusIconErrorsCss = css`
  background-color: ${({ theme }) => theme.color.error};
`;

export const StyledStatusIcon = styled.div<{ changes?: boolean; errors?: boolean }>`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.space[1]}px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  ${({ changes }) => changes && StyledStatusIconChangesCss};
  ${({ errors }) => errors && StyledStatusIconErrorsCss};
  ${({ errors, changes }) => !changes && !errors && 'display: none;'};
`;
