import { EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { Button, Dropdown, Icon, Menu, Message } from '@lucid/core';
import React, { memo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { DEFAULT_JSON_RESOURCE, RESOURCE_KEY_COMPARE_EDITING, RESOURCE_KEY_COMPARE_ORIGIN } from '../../common';
import { DiffsModal, EditorHeaderControlsContainer, JsonResourceModal } from '../../components';
import { useEntityUploadModal, useSchemas } from '../../hooks';
import { entityUploadModalMessages, jsonEditorMessages, sidebarItemMessages, commonMessages } from '../../locale';
import { Schemas, useAppDispatch } from '../../store';

const schemaHeaderControlSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.selectedKey,
  ({ schemas }: STATES.App) => schemas.resourcesHaveErrors,
  (selectedKey, resourcesHaveErrors) => {
    return {
      selectedKey,
      resourcesHaveErrors,
    };
  }
);

export type SchemaHeaderControlsProps = {
  title: string;
  applyChanges: () => void;
  getCurrentData: () => Record<string, unknown>;
  entityAddress: Nullable<EntityAddress>;
  currentOriginData: Record<string, unknown>;
};

const SchemaHeaderControls = ({
  title,
  applyChanges,
  getCurrentData,
  entityAddress,
  currentOriginData,
}: SchemaHeaderControlsProps): JSX.Element | null => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { onSelectResource } = useSchemas();
  const { selectedKey, resourcesHaveErrors } = useSelector(schemaHeaderControlSelector);
  const onUploaded = useCallback(
    (savedData: EntityData, savedAddress: EntityAddress): void => {
      void Message.success(intl.formatMessage(entityUploadModalMessages.success));

      applyChanges();

      dispatch(
        Schemas.actions.updateAfterSaved({
          resourceKey: selectedKey,
          savedData,
          savedAddress,
        })
      );
    },
    [applyChanges, dispatch, intl, selectedKey]
  );
  const { entityUploadModal, onSave, onSaveAs } = useEntityUploadModal({
    entityAddress,
    getCurrentData,
    onUploaded,
    resourceKey: selectedKey,
  });
  const [diffsModalVisible, setDiffModalVisible] = useState(false);
  const [jsonModalVisible, setJsonModalVisible] = useState(false);

  const getFirstJsonResource = useCallback(() => {
    return {
      ...DEFAULT_JSON_RESOURCE,
      obj: currentOriginData,
      originObj: currentOriginData,
      resourceKey: RESOURCE_KEY_COMPARE_ORIGIN,
    };
  }, [currentOriginData]);

  const getSecondJsonResource = useCallback(() => {
    return {
      ...DEFAULT_JSON_RESOURCE,
      obj: getCurrentData(),
      originObj: getCurrentData(),
      resourceKey: RESOURCE_KEY_COMPARE_EDITING,
    };
  }, [getCurrentData]);

  const closeSchemaEditColumn = (): void => {
    dispatch(
      Schemas.actions.selectSchema({
        resourceKey: '',
      })
    );
  };

  const resetChanges = (): void => {
    dispatch(
      Schemas.actions.resetChanges({
        resourceKey: selectedKey,
      })
    );
  };

  return (
    <EditorHeaderControlsContainer>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="apply" onClick={applyChanges}>
              <Icon type="check-circle" />
              <FormattedMessage {...jsonEditorMessages.menuApply} />
            </Menu.Item>
            <Menu.Item key="select" onClick={() => setJsonModalVisible(true)}>
              <Icon type="file-text" />
              <FormattedMessage {...jsonEditorMessages.menuSelect} />
            </Menu.Item>
            <Menu.Item key="save" onClick={onSave} disabled={resourcesHaveErrors[selectedKey]}>
              <Icon type="save" />
              <FormattedMessage {...jsonEditorMessages.menuSave} />
            </Menu.Item>
            <Menu.Item key="saveAs" onClick={onSaveAs} disabled={resourcesHaveErrors[selectedKey]}>
              <Icon type="save" />
              <FormattedMessage {...jsonEditorMessages.menuSaveAs} />
            </Menu.Item>
            <Menu.Item key="showChanges" onClick={() => setDiffModalVisible(true)} className="ant-dropdown-menu-item">
              <Icon type="diff" />
              <FormattedMessage {...jsonEditorMessages.menuDiffs} />
            </Menu.Item>
            <Menu.Item key="reset" onClick={resetChanges}>
              <Icon type="sync" />
              <FormattedMessage {...jsonEditorMessages.menuReset} />
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="close" onClick={closeSchemaEditColumn}>
              <Icon type="close" />
              <FormattedMessage {...commonMessages.close} />
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger={['click']}
      >
        <Button type="dashed" shape="circle" size="small" data-testid="schema-header-menu-trigger">
          <Icon type="menu" />
        </Button>
      </Dropdown>
      {entityUploadModal}
      <DiffsModal
        title={intl.formatMessage(sidebarItemMessages.title, { key: selectedKey })}
        getFirstJsonResource={getFirstJsonResource}
        getSecondJsonResource={getSecondJsonResource}
        isVisible={diffsModalVisible}
        setVisible={setDiffModalVisible}
      />
      <JsonResourceModal
        onDone={onSelectResource}
        resourceKey={selectedKey}
        title={title}
        isVisible={jsonModalVisible}
        setVisible={setJsonModalVisible}
      />
    </EditorHeaderControlsContainer>
  );
};

export default memo(SchemaHeaderControls);
