import { Select } from '@lucid/core';
import styled from 'styled-components';

export const MODAL_WIDTH = 1000;

export const SelectContainer = styled(Select)`
  .ant-select-selection {
    background-color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.primary};
    width: ${({ theme }) => theme.space[9]}px;
    margin-left: ${({ theme }) => theme.space[2]}px;
  }
`;
