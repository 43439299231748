import { createGlobalStyle, css, DefaultTheme } from 'styled-components';

const BaseModalGlobalCss = css`
  .base-modal {
    .ant-modal {
      max-width: calc(100% - ${({ theme }) => theme.fontSizes[3]}px);
    }

    .ant-modal-close {
      right: -27px;
      top: -27px;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: ${({ theme }) => theme.fontSizes[3]}px;
    }

    .ant-modal-header {
      border-bottom: none;
      padding: ${({ theme }) => theme.space[3]}px;
      padding-bottom: 0;
    }

    .ant-modal-body {
      padding: ${({ theme }) => theme.space[3]}px;
      font-size: ${({ theme }) => theme.fontSizes[2]}px;
    }

    .ant-modal-footer {
      border-top: none;
      padding: ${({ theme }) => theme.space[3]}px;
      padding-top: 0;
    }
  }
`;

export const BaseModalGlobalStyled = createGlobalStyle<{ theme: DefaultTheme }>`
  ${BaseModalGlobalCss} 
`;
