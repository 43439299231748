import { EntityAddress, EntityData } from '@coherent/entity-store-ui';
import { EditorType, JsonError, RefMap, RefMapType } from '@coherent/json-editor';
import { UISchemaElement } from '@jsonforms/core';
import { Button, Dropdown, Icon, Menu, Message, Modal } from '@lucid/core';
import { Switch } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { DEFAULT_JSON_RESOURCE, RESOURCE_KEY_COMPARE_EDITING, RESOURCE_KEY_COMPARE_ORIGIN } from '../../common';
import { Box, DiffsModal, EntityNewModal, JsonResourceModal } from '../../components';
import { buildEntityAddressPathParam } from '../../helpers';
import { useEntityUploadModal, useSchemas } from '../../hooks';
import {
  commonMessages,
  dataColMessages,
  entityUploadModalMessages,
  jsonEditorMessages,
  jsonModalMessages,
} from '../../locale';
import { Entity, useAppDispatch } from '../../store';
import Theme from '../../styles/theme';
import DataHeaderTitle from './DataHeaderTitle';
import { EditorHeaderControlsContainer, StyledJsonEditor } from './Home.styled';

type DataEditColumnProps = {
  allowSelect?: boolean;
  hideSidebarToggle?: boolean;
  onSaved?: (savedData: EntityData, savedAddress: EntityAddress) => void;
  allowSaveAs?: boolean;
};

const dataEditSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.jsonSchema,
  ({ schemas }: STATES.App) => schemas.uiSchema,
  ({ schemas }: STATES.App) => schemas.refsMappingList,
  ({ schemas }: STATES.App) => Object.values(schemas.resourcesHaveChanges).some(Boolean),
  ({ entity }: STATES.App) => entity.data,
  ({ entity }: STATES.App) => entity.dataDisplayType,
  (jsonSchema, uiSchema, refsMappingList, haveUnsavedSchema, data, dataDisplayType) => {
    return {
      data,
      jsonSchema,
      uiSchema,
      refsMappingList,
      dataDisplayType,
      haveUnsavedSchema,
    };
  }
);

const DataEditColumn = ({
  allowSelect = true,
  allowSaveAs = true,
  hideSidebarToggle = false,
  onSaved,
}: DataEditColumnProps): JSX.Element => {
  const history = useHistory();
  const intl = useIntl();
  const [jsonEditorKey, setJsonEditorKey] = useState(0);
  const { loadSchemaSetupsForType } = useSchemas();
  const dispatch = useAppDispatch();
  const { data, uiSchema, jsonSchema, refsMappingList, dataDisplayType, haveUnsavedSchema } = useSelector(
    dataEditSelector
  );
  const dataRef = useRef<Record<string, unknown>>({});

  const getCurrentData = useCallback(() => {
    return dataRef.current;
  }, []);

  const onUploaded = (savedData: EntityData, savedAddress: EntityAddress): void => {
    dispatch(Entity.actions.updateAfterSaved({ savedData, savedAddress }));

    if (onSaved) {
      onSaved(savedData, savedAddress);
      return;
    }

    history.push(
      `/entity/${buildEntityAddressPathParam({
        ...savedAddress,
        versionId: undefined,
      })}`
    );
  };

  const { entityUploadModal, onSave, onSaveAs } = useEntityUploadModal({
    entityAddress: data.entityAddress,
    getCurrentData,
    onUploaded,
    resourceKey: data.resourceKey,
  });
  const [diffsModalVisible, setDiffModalVisible] = useState(false);
  const [jsonModalVisible, setJsonModalVisible] = useState(false);
  const [entityNewModalVisible, setEntityNewModalVisible] = useState(false);

  const clonedInitData = useMemo(() => {
    const clonedData = cloneDeep(data.obj);
    dataRef.current = clonedData;

    return clonedData;
  }, [data.obj]);

  useEffect(() => {
    dataRef.current = clonedInitData;
  }, [clonedInitData]);

  const clonedJsonSchema = useMemo(() => {
    return cloneDeep(jsonSchema.obj);
  }, [jsonSchema.obj]);

  const clonedUISchema = useMemo<unknown>(() => {
    return cloneDeep(uiSchema.obj);
  }, [uiSchema.obj]);

  const clonedRefsMappingList = useMemo(() => {
    return Object.entries(refsMappingList).reduce<Record<string, RefMap>>((prev, [key, value]) => {
      return {
        ...prev,
        [key]: {
          ref: key,
          type: RefMapType.Local,
          remoteUrl: '',
          localObject: cloneDeep(value.obj),
        },
      };
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clonedJsonSchema, refsMappingList]);

  useEffect(() => {
    // This hook is for reloading JSONForm UI
    setJsonEditorKey((current) => current + 1);
  }, [clonedJsonSchema, clonedUISchema, clonedRefsMappingList, clonedInitData]);

  const onChange = useCallback((nextData: Record<string, unknown>) => {
    dataRef.current = nextData;
  }, []);

  const resetChanges = (): void => {
    dispatch(Entity.actions.resetChanges());
  };

  const onSelectResource = (obj: Record<string, unknown>, resourceKey: string, entity?: EntityAddress): void => {
    if (!entity) {
      void dispatch(Entity.actions.setData({ obj }));
      return;
    }

    const onConfirmed = (): void => {
      history.push(`/entity/${buildEntityAddressPathParam(entity)}`);
    };

    if (!haveUnsavedSchema) {
      void onConfirmed();

      return;
    }

    Modal.confirm({
      title: intl.formatMessage(jsonModalMessages.unsavedChangesConfirm),
      onOk() {
        onConfirmed();
      },
      okText: intl.formatMessage(commonMessages.continue),
    });
  };

  const onDoneNewEntitySetup = async (entityAddress: EntityAddress, entityExists: boolean): Promise<void> => {
    void Message.success(intl.formatMessage(entityUploadModalMessages.success));
    if (entityExists) {
      history.push(`/entity/${buildEntityAddressPathParam(entityAddress)}`);

      return;
    }

    if (entityAddress.entityType && entityAddress.dataType) {
      const jsonSetups = await loadSchemaSetupsForType(entityAddress.entityType, entityAddress.dataType);

      if (jsonSetups) {
        jsonSetups.data.entityAddress = entityAddress;
        dispatch(Entity.actions.setFromJSONSetups({ jsonSetups }));

        history.push('/');
      }
    }
  };

  const getSecondJsonResource = useCallback(() => {
    const currentData = getCurrentData();

    return {
      ...DEFAULT_JSON_RESOURCE,
      obj: currentData,
      originObj: currentData,
      resourceKey: RESOURCE_KEY_COMPARE_EDITING,
    };
  }, [getCurrentData]);

  const getFirstJsonResource = useCallback(() => {
    return {
      ...DEFAULT_JSON_RESOURCE,
      obj: data.originObj,
      originObj: data.originObj,
      resourceKey: RESOURCE_KEY_COMPARE_ORIGIN,
    };
  }, [data.originObj]);

  const onNew = (): void => {
    setEntityNewModalVisible(true);
  };

  const onDisplayTypeChange = (checked: boolean): void => {
    const editorType = checked ? EditorType.JSONForm : EditorType.Textual;
    dispatch(Entity.actions.setDataDisplayType(editorType));
  };

  const onErrors = useCallback(
    (errors: ReadonlyArray<JsonError>) => {
      dispatch(Entity.actions.setErrors({ errors: cloneDeep(errors) as JsonError[] }));
    },
    [dispatch]
  );

  return (
    <Box
      height="100%"
      width="100%"
      isFlex
      borderLeft="1px solid"
      borderColor={Theme.color.secondary}
      data-testid="data-json-editor"
      maxWidth="100%"
      overflow="hidden"
      position="relative"
    >
      <StyledJsonEditor
        key={`${jsonEditorKey}`}
        initData={dataRef.current}
        schema={clonedJsonSchema}
        uiSchema={clonedUISchema as UISchemaElement}
        schemaRefs={clonedRefsMappingList}
        displayType={dataDisplayType}
        onErrors={onErrors}
        headerTitle={<DataHeaderTitle hideSidebarToggle={hideSidebarToggle} />}
        onChange={onChange}
        headerControls={
          <EditorHeaderControlsContainer>
            <Switch
              data-testid="data-edit-switch"
              checked={dataDisplayType === EditorType.JSONForm}
              onChange={onDisplayTypeChange}
            />
            <span className="type-switch-label">
              <FormattedMessage {...jsonEditorMessages.menuUI} />
            </span>
            <Dropdown
              overlay={
                <Menu>
                  {allowSelect && (
                    <Menu.Item key="new" onClick={onNew}>
                      <Icon type="file-add" />
                      <FormattedMessage {...jsonEditorMessages.menuNew} />
                    </Menu.Item>
                  )}
                  {allowSelect && (
                    <Menu.Item key="select" onClick={() => setJsonModalVisible(true)}>
                      <Icon type="file-text" />
                      <FormattedMessage {...jsonEditorMessages.menuSelect} />
                    </Menu.Item>
                  )}
                  <Menu.Item key="save" onClick={onSave}>
                    <Icon type="save" />
                    <FormattedMessage {...jsonEditorMessages.menuSave} />
                  </Menu.Item>
                  {allowSaveAs && (
                    <Menu.Item key="saveAs" onClick={onSaveAs}>
                      <Icon type="save" />
                      <FormattedMessage {...jsonEditorMessages.menuSaveAs} />
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="showChanges"
                    onClick={() => setDiffModalVisible(true)}
                    className="ant-dropdown-menu-item"
                  >
                    <Icon type="diff" />
                    <FormattedMessage {...jsonEditorMessages.menuDiffs} />
                  </Menu.Item>
                  <Menu.Item key="reset" onClick={resetChanges}>
                    <Icon type="sync" />
                    <FormattedMessage {...jsonEditorMessages.menuReset} />
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={['click']}
            >
              <Button type="dashed" shape="circle" size="small" data-testid="data-col-menu-toggle-btn">
                <Icon type="menu" />
              </Button>
            </Dropdown>
            {entityUploadModal}
            <DiffsModal
              title={data.resourceKey}
              getFirstJsonResource={getFirstJsonResource}
              getSecondJsonResource={getSecondJsonResource}
              isVisible={diffsModalVisible}
              setVisible={setDiffModalVisible}
            />
            <JsonResourceModal
              onDone={onSelectResource}
              resourceKey={data.resourceKey}
              title={intl.formatMessage(jsonModalMessages.dataSchema)}
              isVisible={jsonModalVisible}
              setVisible={setJsonModalVisible}
            />
            <EntityNewModal
              onDone={onDoneNewEntitySetup}
              isVisible={entityNewModalVisible}
              setVisible={setEntityNewModalVisible}
            />
          </EditorHeaderControlsContainer>
        }
      />

      {!allowSelect && data.entityAddress == null && (
        <Box
          width="100%"
          height="100%"
          position="absolute"
          top={0}
          left={0}
          isFlex
          alignItems="center"
          justifyContent="center"
          zIndex={4}
          backgroundColor="#fff"
          color={Theme.color.primary}
        >
          <FormattedMessage {...dataColMessages.noneSelected} />
        </Box>
      )}
    </Box>
  );
};

export default memo(DataEditColumn);
