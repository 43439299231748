import { createAsyncThunk } from '@reduxjs/toolkit';
import { quicktype, InputData, jsonInputForTargetLanguage } from 'quicktype-core';

const generateSchema = createAsyncThunk('generation/generateSchema', async (_, { getState }) => {
  const {
    generation: { data },
  } = getState() as STATES.App;

  const samples = data.map((sample) => JSON.stringify(sample.obj));
  const jsonInput = jsonInputForTargetLanguage('JSON Schema');
  const generatedSchema = await jsonInput
    .addSource({
      name: 'jsonSchema',
      samples,
    })
    .then(() => {
      const inputData = new InputData();
      inputData.addInput(jsonInput);

      return quicktype({
        inputData,
        lang: 'JSON Schema',
        fixedTopLevels: true,
      });
    })
    .then((result) => {
      const schema = JSON.parse(result.lines.join('\n')) as Record<string, unknown>;

      schema.$schema = 'http://json-schema.org/draft-07/schema#';
      return schema;
    });

  return {
    generatedSchema,
  };
});

export default {
  generateSchema,
};
