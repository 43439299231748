import { ConfigProvider } from '@lucid/core';
import React, { FC, ReactNode } from 'react';
import { IntlShape, RawIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

interface AppProviderProps {
  onBeforeLift?(): void | Promise<void>;
  loadingUI?: ReactNode;
}

const intlSelector = createSelector(
  ({ lang }: STATES.App) => lang,
  ({ intl, configProviderLocale }) => ({ intl, configProviderLocale })
);

const LanguageProvider: FC<AppProviderProps> = ({ children }) => {
  const { intl, configProviderLocale } = useSelector(intlSelector);

  return (
    <RawIntlProvider value={intl as IntlShape}>
      <ConfigProvider locale={configProviderLocale}>{children}</ConfigProvider>
    </RawIntlProvider>
  );
};

export default LanguageProvider;
