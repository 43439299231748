import { EntityAddress } from '@coherent/entity-store-ui';
import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import GenerationAsyncActions from './GenerationAsyncActions';

export const GENERATION_SLICE_NAME = 'generation';

export const GENERATION_INITIAL_STATE: STATES.Generation = {
  data: [],
  resourcesHaveChanges: {},
  selectedKey: '',
  resourcesHaveErrors: {},
  jsonSchema: {
    entityAddress: null,
    entityVersion: null,
    obj: {},
    originObj: {},
    resourceKey: '',
  },
  generatedSchemaHasChanges: false,
  generatedSchemaHasErrors: false,
  lastSavedEntityAddress: null,
};
const addDataFromEntity: CaseReducer<STATES.Generation, PayloadAction<{ resource: ENTITIES.JsonResourceObject }>> = (
  state,
  { payload: { resource } }
) => {
  state.data.push({
    ...resource,
    resourceKey: uuidv4(),
  });
};

const hasChanges: CaseReducer<STATES.Generation, PayloadAction<{ resourceKey: string; value?: boolean }>> = (
  state,
  { payload: { resourceKey, value = true } }
) => {
  if (state.resourcesHaveChanges[resourceKey] === value) {
    return;
  }

  state.resourcesHaveChanges = {
    ...state.resourcesHaveChanges,
    [resourceKey]: value,
  };
};

const saveLastEntityAddress: CaseReducer<
  STATES.Generation,
  PayloadAction<{ savedAddress: Nullable<EntityAddress> }>
> = (state, { payload: { savedAddress } }) => {
  state.lastSavedEntityAddress = savedAddress;
};

const schemaHasChanges: CaseReducer<STATES.Generation, PayloadAction<{ value?: boolean }>> = (
  state,
  { payload: { value = true } }
) => {
  if (state.generatedSchemaHasChanges === value) {
    return;
  }

  state.generatedSchemaHasChanges = value;
};

const hasErrors: CaseReducer<STATES.Generation, PayloadAction<{ resourceKey: string; value?: boolean }>> = (
  state,
  { payload: { resourceKey, value = true } }
) => {
  if (state.resourcesHaveErrors[resourceKey] === value) {
    return;
  }

  state.resourcesHaveErrors = {
    ...state.resourcesHaveErrors,
    [resourceKey]: value,
  };
};

const schemaHasErrors: CaseReducer<STATES.Generation, PayloadAction<{ value?: boolean }>> = (
  state,
  { payload: { value = true } }
) => {
  if (state.generatedSchemaHasErrors === value) {
    return;
  }

  state.generatedSchemaHasErrors = value;
};
const selectSchema: CaseReducer<STATES.Generation, PayloadAction<{ resourceKey: string }>> = (
  state,
  { payload: { resourceKey } }
) => {
  state.selectedKey = resourceKey;
};

const deleteResource: CaseReducer<STATES.Generation, PayloadAction<{ resourceKey: string }>> = (
  state,
  { payload: { resourceKey } }
) => {
  const resourceIndex = state.data.findIndex((resource) => resource.resourceKey === resourceKey);
  if (resourceIndex !== -1) {
    state.data.splice(resourceIndex, 1);
  }
};

/** Use this to set data column (from file, url, raw on resource modal) */
const addLocalData: CaseReducer<STATES.Generation, PayloadAction<{ obj: Record<string, unknown> }>> = (
  state,
  { payload: { obj } }
) => {
  state.data.push({
    entityAddress: null,
    entityVersion: null,
    obj,
    originObj: obj,
    resourceKey: uuidv4(),
  });
};

const applyChanges: CaseReducer<STATES.Generation, PayloadAction<{ resourceKey: string; textContent: string }>> = (
  state,
  { payload: { resourceKey, textContent } }
) => {
  const { resourcesHaveErrors, resourcesHaveChanges, data } = state;
  if (!resourcesHaveErrors[resourceKey] || resourcesHaveChanges[resourceKey]) {
    const obj = JSON.parse(textContent) as Record<string, unknown>;

    const schema = data.find((resource) => resource.resourceKey === resourceKey);
    if (schema) {
      schema.obj = obj;
    }
  }
};

const resetChanges: CaseReducer<STATES.Generation, PayloadAction<{ resourceKey: string }>> = (
  state,
  { payload: { resourceKey } }
) => {
  if (!state.resourcesHaveChanges[resourceKey]) {
    return;
  }

  const resourceIndex = state.data.findIndex((resource) => resource.resourceKey === resourceKey);
  if (resourceIndex !== -1) {
    state.data[resourceIndex].obj = state.data[resourceIndex].originObj;
  }

  const { [resourceKey]: removingHasChanges, ...resourcesHaveChanges } = state.resourcesHaveChanges;

  state.resourcesHaveChanges = resourcesHaveChanges;

  const { [resourceKey]: removingHasErrors, ...resourcesHaveErrors } = state.resourcesHaveErrors;

  state.resourcesHaveErrors = resourcesHaveErrors;
};

const GenerationSlice = createSlice({
  name: GENERATION_SLICE_NAME,
  initialState: GENERATION_INITIAL_STATE,
  reducers: {
    addLocalData,
    addDataFromEntity,
    hasChanges,
    hasErrors,
    selectSchema,
    deleteResource,
    applyChanges,
    resetChanges,
    schemaHasChanges,
    schemaHasErrors,
    saveLastEntityAddress,
  },
  extraReducers: (builder) => {
    builder.addCase(GenerationAsyncActions.generateSchema.fulfilled, (state, { payload }) => {
      const { generatedSchema } = payload;

      return {
        ...state,
        jsonSchema: {
          ...state.jsonSchema,
          obj: generatedSchema,
        },
      };
    });
  },
});

export default GenerationSlice;
