import React from 'react';
import { StyledLoading, OverlayDiv } from './LoadingOverlay.styled';

export type LoadingOverlayProps = {
  isLoading: boolean;
};

const LoadingOverlay = ({ isLoading }: LoadingOverlayProps): JSX.Element => {
  return (
    <OverlayDiv show={isLoading}>
      <StyledLoading variant="azureBlue" data-testid="loading-svg" />
    </OverlayDiv>
  );
};

export default LoadingOverlay;
