import { Menu } from '@lucid/core';
import styled from 'styled-components';

export const Container = styled.div`
  font-weight: bold;
  display: flex;
  align-content: center;
  cursor: pointer;

  .anticon {
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledMenu = styled(Menu)`
  min-width: ${({ theme }) => theme.space[9]}px;
`;
