import { Dropdown, Icon, Menu } from '@lucid/core';
import React from 'react';
import { Container, StyledMenu } from './HeaderDropdownMenu.styled';

type HeaderDropdownMenuProps = {
  menu: React.ReactNode[];
  className?: string;
};

const HeaderDropdownMenu = ({ menu, className }: HeaderDropdownMenuProps): JSX.Element => {
  const menus = (): JSX.Element => {
    return (
      <StyledMenu>
        {menu.filter(Boolean).map((menuItem) => {
          if (menuItem === '----') {
            return <Menu.Divider />;
          }

          return <Menu.Item>{menuItem}</Menu.Item>;
        })}
      </StyledMenu>
    );
  };

  return (
    <Container className={className}>
      <Dropdown overlay={menus}>
        <Icon type="menu" />
      </Dropdown>
    </Container>
  );
};

export default HeaderDropdownMenu;
