import { EntityAddress, EntityData, useEntityStoreContext } from '@coherent/entity-store-ui';
import { EditorType, useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import { Icon, Layout, Modal } from '@lucid/core';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LOGIN_PATH } from '../../common';
import { Box } from '../../components';
import { Keycloak } from '../../helpers';
import { useMassValidationRouterMatch, useSchemas } from '../../hooks';
import { confirmMessages, userControlMessages } from '../../locale';
import { Entity, MassValidation, useAppDispatch } from '../../store';
import DataEditColumn from '../Home/DataEditColumn';
import EntitiesValidationTableColumn from './EntitiesValidationTableColumn';

const selector = createSelector(
  ({ auth }: STATES.App) => auth.isAuthenticated,
  ({ env }: STATES.App) => env,
  ({ massValidation }: STATES.App) => massValidation.invalidOnly,
  (isAuthenticated, { keycloakDomain, keycloakTenant, keycloakClientId }, invalidOnly) => ({
    isAuthenticated,
    keycloakDomain,
    keycloakTenant,
    keycloakClientId,
    invalidOnly,
  })
);

const MassValidationPage = (): JSX.Element => {
  const { isAuthenticated, keycloakDomain, keycloakTenant, keycloakClientId, invalidOnly } = useSelector(selector);
  const intl = useIntl();
  const { dataType, entityType, rootFolder } = useMassValidationRouterMatch();
  const { entityHostUrl, docStore } = useEntityStoreContext();
  const { loadedGlobalRefsEntity } = useEntityStoreJsonEditorContext();
  const { loadSchemaSetupsForType } = useSchemas();
  const schemasInputsRef = useRef({ entityType: '', dataType: '' });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      const keycloak = new Keycloak(
        keycloakDomain,
        keycloakTenant,
        keycloakClientId,
        `${window.location.origin}${LOGIN_PATH}?return_url=${window.location.pathname}`,
        window.location.origin
      );

      Modal.confirm({
        title: intl.formatMessage(confirmMessages.loginRequired),
        icon: <Icon type="warning" />,
        okText: intl.formatMessage(userControlMessages.login),
        onOk() {
          window.location.replace(keycloak.singleSignOnUrl);
        },
      });

      return;
    }

    if (!entityType || !loadedGlobalRefsEntity || !docStore) {
      return;
    }

    void (async () => {
      if (schemasInputsRef.current.dataType !== dataType || schemasInputsRef.current.entityType !== entityType) {
        const setups = await loadSchemaSetupsForType(entityType, dataType);

        dispatch(
          Entity.actions.setDataDisplayType(
            isEmpty(setups?.uischema?.entityData?.data) ? EditorType.Textual : EditorType.JSONForm
          )
        );
      }

      void dispatch(
        MassValidation.actions.changeInputs({
          dataType,
          invalidOnly,
          entityType,
          rootFolder,
          docStore,
          entityHostUrl,
          pageIndex: 1,
        })
      );

      schemasInputsRef.current = {
        entityType,
        dataType,
      };
    })();
  }, [
    entityType,
    rootFolder,
    dataType,
    isAuthenticated,
    intl,
    loadedGlobalRefsEntity,
    keycloakDomain,
    keycloakTenant,
    keycloakClientId,
    loadSchemaSetupsForType,
    dispatch,
    docStore,
    entityHostUrl,
    invalidOnly,
  ]);

  const onSaved = useCallback(
    (savedData: EntityData, savedAddress: EntityAddress) => {
      void dispatch(MassValidation.actions.updateEntity({ savedData, savedAddress }));
    },
    [dispatch]
  );

  return (
    <Layout.Content>
      <Box isFlex justifyContent="space-between" width="100%" height="100%" flexWrap="nowrap">
        <EntitiesValidationTableColumn />
        <DataEditColumn allowSelect={false} allowSaveAs={false} hideSidebarToggle onSaved={onSaved} />
      </Box>
    </Layout.Content>
  );
};

export default MassValidationPage;
