import { useEntityStoreContext } from '@coherent/entity-store-ui';
import { EditorType, JsonError, JsonSchemaDraft07, useEntityStoreJsonEditorContext } from '@coherent/json-editor';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RESOURCE_KEY_JSON_SCHEMA, RESOURCE_KEY_UI_SCHEMA } from '../../common';
import { Box } from '../../components';
import { sidebarItemMessages } from '../../locale';
import { Schemas, useAppDispatch } from '../../store';
import Theme from '../../styles/theme';
import { StyledJsonEditor } from './Home.styled';
import SchemaHeaderControls from './SchemaHeaderControls';
import SchemaHeaderTitle from './SchemaHeaderTitle';

export type SchemaEditColumnProps = {
  resourceKey: string;
};

const schemaEditSelector = createSelector(
  ({ schemas }: STATES.App) => schemas.jsonSchema,
  ({ schemas }: STATES.App) => schemas.refsMappingList,
  ({ schemas }: STATES.App) => schemas.uiSchema,
  (jsonSchema, refsMappingList, uiSchema) => {
    return {
      jsonSchema,
      refsMappingList,
      uiSchema,
    };
  }
);

const SchemaEditColumn = ({ resourceKey }: SchemaEditColumnProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { docStore, entityHostUrl } = useEntityStoreContext();
  const { refMapping } = useEntityStoreJsonEditorContext();
  const [jsonEditorKey, setJsonEditorKey] = useState(0);
  const { jsonSchema, refsMappingList, uiSchema } = useSelector(schemaEditSelector);
  const textualContentsCached = useRef<Record<string, string | undefined>>({});
  const resourceKeyRef = useRef<string>(resourceKey);

  const schemaObjectResource = useMemo((): Nullable<ENTITIES.JsonResourceObject> => {
    switch (resourceKey) {
      case RESOURCE_KEY_JSON_SCHEMA: {
        return jsonSchema;
      }
      case RESOURCE_KEY_UI_SCHEMA: {
        return uiSchema;
      }
      default: {
        return refsMappingList[resourceKey];
      }
    }
  }, [jsonSchema, refsMappingList, uiSchema, resourceKey]);

  useEffect((): void => {
    textualContentsCached.current[RESOURCE_KEY_JSON_SCHEMA] = undefined;
    setJsonEditorKey((current) => current + 1);
  }, [jsonSchema.obj]);

  useEffect((): void => {
    textualContentsCached.current[RESOURCE_KEY_UI_SCHEMA] = undefined;
    setJsonEditorKey((current) => current + 1);
  }, [uiSchema.obj]);

  useEffect((): void => {
    if (resourceKeyRef.current === resourceKey) {
      textualContentsCached.current[resourceKey] = undefined;
      setJsonEditorKey((current) => current + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refsMappingList[resourceKey]?.obj]);

  useEffect(() => {
    // We need this to force jsonEditor reload the schema
    setJsonEditorKey((current) => current + 1);
    resourceKeyRef.current = resourceKey;
  }, [resourceKey]);

  const textualOnTextChange = useCallback(
    (content: string) => {
      textualContentsCached.current[resourceKey] = content;
      dispatch(
        Schemas.actions.hasChanges({
          resourceKey,
        })
      );
    },
    [resourceKey, dispatch]
  );

  const onErrors = useCallback(
    (errors: ReadonlyArray<JsonError>) => {
      dispatch(
        Schemas.actions.hasErrors({
          resourceKey,
          value: errors.length !== 0,
        })
      );
    },
    [resourceKey, dispatch]
  );

  const applyChanges = (): void => {
    void dispatch(
      Schemas.actions.applyChanges({
        resourceKey,
        textContent: textualContentsCached.current[resourceKey] ?? '',
        docStore,
        globalRefMapping: refMapping,
        entityHostUrl,
      })
    );
  };

  const getCurrentData = useCallback(() => {
    const currentText = textualContentsCached.current[resourceKey];
    const obj = schemaObjectResource?.obj ?? {};

    try {
      return currentText ? (JSON.parse(currentText) as Record<string, unknown>) : obj;
    } catch (error) {
      return obj;
    }
  }, [resourceKey, schemaObjectResource?.obj]);

  if (!schemaObjectResource) {
    return null;
  }

  return (
    <Box
      height="100%"
      width="100%"
      isFlex
      borderLeft="1px solid"
      borderColor={Theme.color.secondary}
      data-testid="inputs-json-editor"
      overflow="hidden"
    >
      <StyledJsonEditor
        key={`${jsonEditorKey}`}
        initData={schemaObjectResource.obj}
        displayType={EditorType.Textual}
        headerTitle={<SchemaHeaderTitle />}
        schema={resourceKey === RESOURCE_KEY_UI_SCHEMA ? undefined : JsonSchemaDraft07}
        textualOnTextChange={textualOnTextChange}
        textualInitTextData={textualContentsCached.current[resourceKey]}
        onErrors={onErrors}
        headerControls={
          <SchemaHeaderControls
            title={intl.formatMessage(sidebarItemMessages.title, { key: resourceKey })}
            entityAddress={schemaObjectResource.entityAddress}
            applyChanges={applyChanges}
            getCurrentData={getCurrentData}
            currentOriginData={schemaObjectResource.originObj}
          />
        }
      />
    </Box>
  );
};

export default memo(SchemaEditColumn);
