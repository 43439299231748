import { Layout } from '@lucid/core';
import React, { ReactNode } from 'react';

type LayoutBodyProps = {
  children: ReactNode;
};

const LayoutBody = ({ children }: LayoutBodyProps): JSX.Element => {
  return <Layout>{children}</Layout>;
};

export default LayoutBody;
