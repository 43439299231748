export const SUPPORTED_LANGUAGES = ['en', 'zh'];
export const LOGIN_PATH = '/login';
export const RESOURCE_KEY_JSON_SCHEMA = 'JSON_SCHEMA';
export const RESOURCE_KEY_UI_SCHEMA = 'UI_SCHEMA';
export const RESOURCE_KEY_DATA = 'DATA';
export const RESOURCE_KEY_COMPARE_1ST = 'RESOURCE_KEY_COMPARE_1ST';
export const RESOURCE_KEY_COMPARE_2ND = 'RESOURCE_KEY_COMPARE_2ND';
export const RESOURCE_KEY_COMPARE_ORIGIN = 'RESOURCE_KEY_COMPARE_ORIGIN';
export const RESOURCE_KEY_COMPARE_EDITING = 'RESOURCE_KEY_COMPARE_EDITING';

export const DEFAULT_JSON_RESOURCE: ENTITIES.JsonResourceObject = {
  entityAddress: null,
  entityVersion: null,
  obj: {},
  originObj: {},
  resourceKey: 'Resource_Key',
};
export enum EntityType {
  DOCUMENT = 'Document',
  FOLDER = 'Folder',
}
