import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import EnvAsyncActions from './EnvAsyncActions';

const ENV_SLICE_NAME = 'env';
export const ENV_INITIAL_STATE: STATES.Env = {
  entityStoreHost: '',
  globalRefMappingsEntity: '',
  keycloakClientId: '',
  keycloakDomain: '',
  keycloakTenant: '',
  environmentKey: 'development',
  apiResponse: undefined,
};

const selectEnv: CaseReducer<STATES.Env, PayloadAction<{ selectedEnv: STATES.Env }>> = (
  state,
  { payload: { selectedEnv } }
) => selectedEnv;

const EnvSlice = createSlice({
  name: ENV_SLICE_NAME,
  initialState: ENV_INITIAL_STATE,
  reducers: { selectEnv },
  extraReducers: (builder) => {
    builder.addCase(EnvAsyncActions.fetchEnv.fulfilled, (state, { payload }) => {
      const { apiResponse } = payload;

      if (!apiResponse) {
        return;
      }

      if (!state.entityStoreHost) {
        const {
          entityStoreHost,
          globalRefMappingsEntity,
          keycloakClientId,
          keycloakTenant,
          keycloakDomain,
        } = apiResponse[state.environmentKey];

        state.entityStoreHost = entityStoreHost;
        state.globalRefMappingsEntity = globalRefMappingsEntity;
        state.keycloakClientId = keycloakClientId;
        state.keycloakTenant = keycloakTenant;
        state.keycloakDomain = keycloakDomain;
      }

      state.apiResponse = apiResponse;
    });
  },
});

export default EnvSlice;
