import { Loading } from '@lucid/animation';
import styled from 'styled-components';

export const OverlayDiv = styled.div<{ show: boolean }>`
  && {
    top: 64px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
    z-index: 1001;
  }
`;

export const StyledLoading = styled(Loading)`
  height: 100px;
`;
