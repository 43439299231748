export interface KeycloakResponseToken {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  token_type: 'bearer';
  session_state: string;
  scope: string;
}

export enum KeycloakErrorType {
  /** Error on generating token */
  INVALID_CODE = 'INVALID_CODE',
  /** Error on refreshing token */
  INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
}

export class KeycloakError extends Error {
  constructor(public type: KeycloakErrorType, public response: Response) {
    super(`KeycloakError - Status: ${response.status}`);
  }
}

export class Keycloak {
  constructor(
    private readonly domain: string,
    private readonly tenant: string,
    private readonly clientId: string,
    private readonly redirectUrl: string,
    private readonly redirectAfterLoggedOutUrl: string
  ) {}

  get singleSignOnUrl(): string {
    return (
      `${this.domain}/auth/realms/${this.tenant}/protocol/openid-connect/auth?` +
      `client_id=${this.clientId}&` +
      'response_type=code&' +
      `redirect_uri=${encodeURIComponent(this.redirectUrl)}`
    );
  }

  get singleSignOutUrl(): string {
    return (
      `${this.domain}/auth/realms/${this.tenant}/protocol/openid-connect/logout?` +
      `redirect_uri=${encodeURIComponent(this.redirectAfterLoggedOutUrl)}`
    );
  }

  async refreshToken(refreshToken: string): Promise<KeycloakResponseToken> {
    const response = await fetch(`${this.domain}/auth/realms/${this.tenant}/protocol/openid-connect/token`, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: this.clientId,
        refresh_token: refreshToken,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });

    if (response.status !== 200) {
      throw new KeycloakError(KeycloakErrorType.INVALID_REFRESH_TOKEN, response);
    }

    return response.json() as Promise<KeycloakResponseToken>;
  }

  async generateToken(code: string): Promise<KeycloakResponseToken> {
    const response = await fetch(`${this.domain}/auth/realms/${this.tenant}/protocol/openid-connect/token`, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: this.clientId,
        code,
        redirect_uri: this.redirectUrl,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });

    if (response.status !== 200) {
      throw new KeycloakError(KeycloakErrorType.INVALID_CODE, response);
    }

    return response.json() as Promise<KeycloakResponseToken>;
  }
}
