import { Icon } from '@lucid/core';
import { IconProps, IconThemeType } from '@lucid/core/es/Icon';
import React, { FC } from 'react';
import styled from 'styled-components';
import Theme from '../../styles/theme';
import Box from './Box';

interface CloseIconProps {
  size?: number;
  className?: string;
  onClick?: () => void;
}

const IconWithTheme: FC<CloseIconProps & Exclude<IconProps, 'theme'> & { iconTheme: IconThemeType }> = ({
  iconTheme,
  ...rest
}) => <Icon theme={iconTheme} {...rest} />;

const StyledIcon = styled(IconWithTheme)`
  background: ${({ theme }) => theme.color.white};
  border-radius: 50%;
`;

const CloseIcon = ({ size = 4, className, onClick }: CloseIconProps): JSX.Element => (
  <Box className={className} color={Theme.color.secondary} fontSize={size} lineHeight={0} p={3}>
    <StyledIcon type="close-circle" iconTheme="filled" onClick={onClick} />
  </Box>
);

export default CloseIcon;
