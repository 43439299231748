import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Box } from '../../components';
import { userControlMessages } from '../../locale';
import { UserControlWrapper } from './UserControl.styled';

const userControlSelector = createSelector(
  ({ auth }: STATES.App) => auth,
  ({ isAuthenticated, displayName, realm }) => ({
    isAuthenticated,
    displayName,
    realm,
  })
);

const UserControl = (): JSX.Element | null => {
  const { isAuthenticated, displayName, realm } = useSelector(userControlSelector);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <UserControlWrapper>
      <FormattedMessage
        {...userControlMessages.welcome}
        values={{
          displayName: (
            <Box display="inline-block" ml={1} fontWeight="bold">
              {displayName}
            </Box>
          ),
          realm: (
            <Box display="inline-block" fontWeight="bold">
              {`(${realm})`}
            </Box>
          ),
        }}
      />
    </UserControlWrapper>
  );
};

export default UserControl;
