import { JsonEditor } from '@coherent/json-editor';
import { Button } from '@lucid/core';
import styled from 'styled-components';

export const StyledJsonEditor = styled(JsonEditor)`
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;

  .json-editor-header {
    min-height: ${({ theme }) => theme.space[6]}px;
  }

  .jsoneditor,
  .ace_editor {
    min-height: auto !important;
  }

  .ant-btn.ant-btn-dashed {
    background-color: ${({ theme }) => theme.color.primary};
  }
`;

export const EditorHeaderControlsContainer = styled.div`
  display: flex;
  align-items: center;

  .ant-btn.ant-btn-dashed {
    background-color: ${({ theme }) => theme.color.primary};
    padding: 0;
    padding-top: 1px;
  }

  .ant-switch {
    &.ant-switch-checked {
      background-color: ${({ theme }) => theme.color.secondary};
    }
  }
`;

export const TextEllipsis = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const OpenSidebarIcon = styled(Button)`
  margin-right: ${({ theme }) => theme.space[2]}px;
`;
