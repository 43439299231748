import SchemasAsyncActions from './SchemasAsyncActions';
import SchemasSlice, { SCHEMAS_INITIAL_STATE } from './SchemasSlice';

export default {
  initialState: SCHEMAS_INITIAL_STATE,
  name: SchemasSlice.name,
  reducer: SchemasSlice.reducer,
  actions: {
    ...SchemasSlice.actions,
    ...SchemasAsyncActions,
  },
};
