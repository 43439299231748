import { PrivacyLevel } from '@coherent/entity-store';
import { EntityAddress, EntityAddressType } from '@coherent/entity-store-ui';
import { Input, Radio } from '@lucid/core';
import { Form } from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';

type EntityAddressInputProps = {
  label: string;
  initValue?: EntityAddress;
  onChange: (value: EntityAddress) => void;
  upload?: boolean;
};

const EntityAddressInput = memo(
  ({ label, initValue, onChange, upload }: EntityAddressInputProps): JSX.Element => {
    const isFirstRender = useRef(true);
    const [path, setPath] = useState(initValue?.path ?? '');
    const [dataType, setDataType] = useState(initValue?.dataType ?? EntityAddressType.Blob);
    const [entityType, setEntityType] = useState(initValue?.entityType ?? '');
    const [versionId, setVersionId] = useState(initValue?.versionId ?? '');
    const [privacyLevel, setPrivacyLevel] = useState(initValue?.privacyLevel ?? PrivacyLevel.Tenant);

    useEffect(() => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      onChange({
        path,
        dataType,
        entityType,
        privacyLevel,
        versionId,
      });
    }, [onChange, path, dataType, entityType, privacyLevel, versionId]);

    return (
      <div>
        <div>{label}</div>
        <div>
          <Form.Item label="Path">
            <Input value={path} onChange={(e) => setPath(e.target.value)} />
          </Form.Item>
        </div>
        <div>
          <Form.Item label="Version">
            <Input value={versionId} onChange={(e) => setVersionId(e.target.value)} />
          </Form.Item>
        </div>
        <div>
          <Form.Item label="Type">
            <Radio.Group
              options={[
                { label: 'Blob', value: EntityAddressType.Blob },
                { label: 'Json Data', value: EntityAddressType.JsonData },
              ]}
              onChange={(e) => setDataType(e.target.value)}
              value={dataType}
            />
          </Form.Item>
        </div>
        {upload && (
          <>
            <div>
              <Form.Item label="Entity Type">
                <Input value={entityType} onChange={(e) => setEntityType(e.target.value)} />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="Privacy Level">
                <Radio.Group
                  options={[
                    { label: 'Global', value: PrivacyLevel.Global },
                    { label: 'Private', value: PrivacyLevel.Private },
                    { label: 'Tenant', value: PrivacyLevel.Tenant },
                  ]}
                  onChange={(e) => setPrivacyLevel(e.target.value)}
                  value={privacyLevel}
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default EntityAddressInput;
