import styled from 'styled-components';

const ErrorContainer = styled.div`
  margin-top: 5px;
  border: 1px solid red;
  padding: 5px;
  width: 500px;
  color: red;

  button {
    background: none;
    border: none;
    font-weight: bold;
    text-decoration: underline;
    padding: 0;
  }
`;

export default ErrorContainer;
